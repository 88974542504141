import { compareBids } from '~/utils';
import { ethersEthers } from '~/ether-utils';

import { getPrice } from '~/ether-utils';
import { AuctionTypes } from './auctions';
import { getCollectionsById, getUserNFTs } from './graph/graph';
import { Collection, NFT, SaleWithNftHash } from './graph/types';

export const getMaticBalance = async (signer: any): Promise<number> => {
  const balance = await signer.getBalance();
  return Number(ethersEthers.utils.formatEther(balance.toString()));
};

/** Suma el precio de todos los NFTs de timed edition */
export const sumTimedEditionNFTsValue = (nfts: SaleWithNftHash[]) => {
  if (nfts?.length <= 0) return null;
  return nfts?.reduce((total, nft) => {
    try {
      const price = getPrice(nft.price);
      return total + price;
    } catch (err) {
      console.error(err);
      return null;
    }
  }, 0);
};

/** Suma las últimas pujas de los últimos lots de cada NFT */
export const sumFirstPriceNFTsValue = (nfts: NFT[]) => {
  if (nfts?.length <= 0) return null;
  return nfts?.reduce((total, nft) => {
    try {
      // Obtiene el último lot
      const lastLot = nft.lots[nft.lots.length - 1];
      // Obtiene sus bids ordenadas ASC por amount
      const bids = lastLot?.bids?.sort(compareBids) || [];

      const lastBidAmount = getPrice(bids[bids.length - 1].amount);
      return total + lastBidAmount;
    } catch (err) {
      console.error(err);
      return null;
    }
  }, 0);
};

export const findUserLots = async (address: string, conversionRate?: number) => {
  const usersNFTs = await getUserNFTs(address);
  const usersNFTLength = usersNFTs?.data?.data?.nfts.length;

  let boughtNFTCollections = null;
  const collectionIds = getUniqCollectionsForNFTs(usersNFTs?.data?.data?.nfts);
  const collectionsForN = await Promise.all(collectionIds.map((c) => getCollectionsById(c)));
  boughtNFTCollections = collectionsForN.map((c) => c.data.data.collections[0]);
  const salesData = saleViaLotLookup(boughtNFTCollections, address);
  const firstPriceNfts = usersNFTs?.data?.data?.nfts.filter(
    (nft: NFT) => nft?.activeLot?.auction?.id === AuctionTypes.firstPrice,
  );

  if (usersNFTLength) {
    const value = sumTimedEditionNFTsValue(salesData);
    const firstPriceValue = sumFirstPriceNFTsValue(firstPriceNfts);
    const sum = value + firstPriceValue;

    return {
      galleryValue: sum,
      userLots: Number(usersNFTLength) || 0,
      salesData: salesData || null,
      usersNFTs: usersNFTs?.data?.data?.nfts,
    };
  } else {
    return {
      galleryValue: 0,
      userLots: 0,
      salesData,
      usersNFTs: usersNFTs?.data?.data?.nfts,
    };
  }
};

const saleViaLotLookup = (collections: Collection[], buyerAddress: string) => {
  let salesForBuyer: SaleWithNftHash[] = [];
  if (collections) {
    collections.forEach((c) => {
      c.nfts.forEach((n) => {
        if (n.lots && n.lots.length > 0) {
          const lastLot = n.lots[n.lots.length - 1];
          const { sales } = lastLot;
          const filtered = sales.filter((s) => s.buyer === buyerAddress.toLowerCase());
          const salesToBuyer = filtered.map((x) => ({
            ...x,
            nftHash: n.contentHash,
            originalId: n.id,
          }));
          salesForBuyer = [...salesForBuyer, ...salesToBuyer];
        }
      });
    });
  }
  return salesForBuyer;
};

const getUniqCollectionsForNFTs = (nfts: NFT[]) =>
  Array.from(new Set(nfts?.map((n) => n.identifier)));
