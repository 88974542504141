import Link from 'next/link';
import classes from './FooterLink.module.scss';

interface Props {
  path: string;
  label: string;
  external?: boolean;
}

export function FooterLink({ path, label, external = false }: Props) {
  return external ? (
    <li className={classes.FooterLink}>
      <a className={classes.label} target="_blank" rel="noopener noreferrer" href={path}>
        {label}
      </a>
    </li>
  ) : (
    <li className={classes.FooterLink}>
      <Link href={path}>
        <a className={classes.label}>{label}</a>
      </Link>
    </li>
  );
}

export function MarketplaceFooterLink({ path, label }: Props) {
  return (
    <li className={classes.MFooterLink}>
      <a className={classes.label} target="_blank" rel="noopener noreferrer" href={path}>
        {label}
      </a>
    </li>
  );
}
