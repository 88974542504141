import moment from 'moment';
import { useContext } from 'react';
import { getTimestampFromUtc } from '~/utils';
import { Banner } from '../services/mito/interfaces';
import { CmsContext, CmsContextValue } from './context';

/** Provee los usuarios de la plataforma */
export function useCms() {
  const { cms: _cms, ...cmsContext } = useContext(CmsContext);

  const cms: CmsContextValue['cms'] = {
    ..._cms,
    banners: {
      ..._cms?.banners,
      home: {
        hero: getBannerWithDisplay(_cms?.banners?.home?.hero),
        upcoming: getBannerWithDisplay(_cms?.banners?.home?.upcoming),
      },
    },
  };

  return { cms, ...cmsContext };
}

function getBannerWithDisplay(banners: Banner[]): Banner[] {
  return (banners || []).map(({ display, ...banner }) => {
    const now = new Date().getTime() / 1000;

    const startTime = getTimestampFromUtc(display?.start);
    const endTime = getTimestampFromUtc(display?.end);

    const started = now > startTime;
    const ended = now > endTime;

    const show = !display || (started && !ended);

    return {
      ...banner,
      display: {
        started,
        ended,
        show,
        ...display,
      },
    };
  });
}
