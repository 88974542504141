import MitoService from '~/store/services/mito';
import { findUserLots } from '~/store/services/balances';
import { Cms } from '~/store/services/mito/interfaces';
import { testProfiles } from '~/store/cmsContext/profiles';

import { getNftStatics, shortenAddress, truncate } from '~/utils';
import { STATIC_HOST, IPFS_NODE, SESSION_TOKEN } from '~/constants';
import Cookies from 'js-cookie';

export type RewardType = 'code' | 'download' | 'link';

export type RewardClaimType = 'optional' | 'mandatory';
export interface RewardCardType {
    allNftsRequired: boolean;
    collection: {
        fallbackImgSrc: string;
        imgSrc: any;
        title: string;
    };
    contractIds: string;
    creator: {
        address: string;
        imgSrc?: string;
        name: string;
    };
    desc: string;
    fallbackImgSrc: string;
    id: string;
    imgSrc: string;
    instructions: string;
    isClaimed: boolean;
    isInUserWallet: boolean;
    isUnlimited: boolean;
    nfts: Array<{
        copy: string;
        id: string;
        originalId: string;
        title: string;
        fallbackImgSrc: string;
        imgSrc: string;
    }>;
    rewardClaimType: RewardClaimType;
    rewardCreatedAt: string;
    rewardUpdatedAt: string;
    rewardClaimedBy: any;
    rewardId: number;
    title: string;
    type: RewardType;
};

const fetchCms = async () => {
    try {
        const { data } = await MitoService.getCms();
        const cms = JSON.parse(data.cms) as Cms;
        return cms;
    } catch (err) {
        console.error(err);
    }
};

export const getUserRewardCards = async (address: string, ownedNfts?: any, token?: string) => {


    const userNFTs = ownedNfts ? ownedNfts : (await findUserLots(address))?.usersNFTs;

    const cms = await fetchCms();
    const profiles = cms?.profiles;


    const currentToken = await Cookies.get(SESSION_TOKEN);
    
    const res = await MitoService.getUserAvailableRewards({ token: currentToken });

    let aux = {
        'rewards': {}
    }
    const unlimitedClaimedByRewardId = {}

    const creatorNameByAddress = {};
    const creatorImgSrcByAddress = {};
    const creatorAddressByOriginalId = {};

    userNFTs?.forEach((nft: any) => {
        const creatorAddress = nft.batch?.creator;
        const originalId = nft.batch?.originalTokenID?.id;
        if (originalId) {
            creatorAddressByOriginalId[originalId] = creatorAddress;
        }
    });


    //#region Utils

    const splitContentHash = (text: string) => {
        let title: string, desc: string;

        const parts = text?.split("/");

        if (parts) {
            title = parts[0];
            desc = parts[1];
        }
        return { title, desc };
    }

    const checkUsedNfts = (userClaims: Array<any>) => {

        let usedNfts = {};
        userClaims?.forEach(claim => {
            const claimUsedNfts = claim.nfts_used_to_claim;

            claimUsedNfts?.forEach((usedNft: any) => {
                const id = usedNft.nft_id.nft_contract_id;
                usedNfts[id] = claim.updatedAt;
            })
        })

        return usedNfts;
    }

    const findCreatorName = (address: string, cmsProfiles: any, testProfiles: any) => {
        const profiles: any = { ...cmsProfiles, ...testProfiles };
        const key = Object.keys(profiles).find((profileAddress: any) => address === profileAddress);
        return key ? profiles[key]['name'] : shortenAddress(address);
    };

    const findCreatorImgSrc = (address: string, cmsProfiles: any, testProfiles: any) => {
        const profiles: any = { ...cmsProfiles, ...testProfiles };
        const key = Object.keys(profiles).find((profileAddress: any) => address === profileAddress);
        return key ? profiles[key]['avatar'] : '/placeholder.png';
    };

    //#endregion Utils

    let availableCardsByReward = {};
    let claimedCardsByReward = {};

    let availableCards = []; // cards eligible to be used
    let claimedCards = []; // cards already claimed
    let unclaimedAvailableCards = []; // available cards that are not yet claimed, to be notified

    if (res) {
        let rewardsData = res.data && Array.isArray(res.data) ? res.data : [];

        rewardsData?.forEach(async (reward: any) => {

            const rewardId = reward.reward_info?.id;

            aux['rewards'][rewardId.toString()] = {
                'mandatory_unlimited': [],
                'optional_unlimited': [],
                'optional_limited': [],
            }

            const rewardCreatedAt = reward.reward_info?.createdAt;
            const rewardUpdatedAt = reward.reward_info?.updatedAt;

            const rewardType = reward.reward_info?.type as RewardType;
            const rewardTitle = reward.reward_info?.title;
            const rewardDescription = reward.reward_info?.description;
            const rewardIsUnlimited = reward.reward_info?.max_claims === 0 ? true : false;
            const rewardInstructions = reward.reward_info?.instructions;
            const includeAll = reward.reward_info?.inlcude_all; // careful, property comes with typo

            const originalNfts = reward.reward_info?.original_nfts_in_reward;
            const fulfillers = reward.user_nfts_that_fullfill_conditions;

            const userClaims = reward.user_claims;

            const usedNfts = checkUsedNfts(userClaims);

            const rewardClaimType = reward.reward_info?.claim_type;

            let rewardCard: RewardCardType = {
                allNftsRequired: includeAll,
                collection: {
                    fallbackImgSrc: '/placeholder.png',
                    imgSrc: '/placeholder.png',
                    title: '',
                },
                contractIds: null,
                creator: {
                    address: null,
                    imgSrc: '/placeholder.png',
                    name: null,
                },
                desc: rewardDescription,
                id: null,
                fallbackImgSrc: '/placeholder.png',
                imgSrc: '/placeholder.png',
                instructions: rewardInstructions,
                nfts: [],
                rewardClaimedBy: {},
                rewardClaimType: rewardClaimType as RewardClaimType,
                rewardCreatedAt: rewardCreatedAt,
                rewardUpdatedAt: rewardUpdatedAt,
                rewardId: rewardId,
                isClaimed: false,
                isInUserWallet: false,
                isUnlimited: rewardIsUnlimited,
                title: rewardTitle,
                type: rewardType
            };


            if (rewardClaimType === 'optional') {

                originalNfts?.forEach((originalNft: any) => {

                    const id = originalNft.original_nft_id.original_nft_id;

                    const collectionTitle = originalNft.original_nft_id?.collection?.title || '';
                    rewardCard['collection']['title'] = collectionTitle;

                    let rewardCreatorAddress = originalNft.original_nft_id?.creator;


                    if (id in fulfillers) {
                        const candidates = fulfillers[id];

                        if (candidates.length > 0) {

                            candidates?.forEach(async (nft: any) => {

                                // console.log('candidate nft:', nft);

                                if (!rewardCreatorAddress || rewardCreatorAddress === '????') {
                                    rewardCreatorAddress = nft.batch?.creator;
                                }




                                const { title } = splitContentHash(nft.contentHash);
                                const copyNumber = nft.copy_number?.toString() || 'n';
                                const maxCopyNumber = nft.max_copy_number?.toString() || 't';
                                const copy = copyNumber + '/' + maxCopyNumber;
                                const theoricalNftImgSrc = `${STATIC_HOST}/${id}_thumb.jpg`;

                                const matchedNft = userNFTs ? userNFTs.find((userNft: any) => userNft.id === nft.id) : null;
                                let isInUserWallet = false;
                                let staticSrc = matchedNft ? getNftStatics(matchedNft).thumb : null;

                                const fallbackImgSrc = `${IPFS_NODE}/${matchedNft?.location}`;

                                if (matchedNft) {
                                    isInUserWallet = true;
                                }
                                else {
                                    isInUserWallet = false;
                                }

                                const src = staticSrc ? staticSrc : theoricalNftImgSrc;

                                rewardCard['isInUserWallet'] = isInUserWallet;
                                rewardCard['fallbackImgSrc'] = fallbackImgSrc;
                                rewardCard['imgSrc'] = src;
                                rewardCard['creator']['address'] = rewardCreatorAddress;

                                const creatorName = findCreatorName(rewardCreatorAddress, profiles, []);
                                const creatorImgSrc = findCreatorImgSrc(rewardCreatorAddress, profiles, []);

                                rewardCard['creator']['name'] = creatorName;
                                rewardCard['creator']['imgSrc'] = creatorImgSrc;

                                if (rewardCreatorAddress) {
                                    creatorNameByAddress[rewardCreatorAddress] = creatorName;
                                    creatorImgSrcByAddress[rewardCreatorAddress] = creatorImgSrc;
                                    creatorAddressByOriginalId[id] = rewardCreatorAddress;
                                }

                                if (rewardIsUnlimited) {
                                    aux['rewards'][rewardId.toString()]['optional_unlimited'].push(nft.id);

                                    rewardCard['nfts'].push({
                                        copy: copy,
                                        id: nft.id,
                                        originalId: id,
                                        title: title,
                                        fallbackImgSrc: fallbackImgSrc,
                                        imgSrc: src,
                                    });

                                } else {

                                    const rewardAux = aux['rewards'][rewardId.toString()];

                                    if (nft.id in rewardAux['optional_limited']) {

                                    } else {
                                        let nftsList = [];

                                        aux['rewards'][rewardId.toString()]['optional_limited'].push(nft.id);

                                        nftsList.push({
                                            copy: copy,
                                            id: nft.id,
                                            originalId: id,
                                            title: title,
                                            fallbackImgSrc: fallbackImgSrc,
                                            imgSrc: src,
                                        });

                                        rewardCard = { ...rewardCard, nfts: [...nftsList] };
                                    }

                                    if (!(rewardId in availableCardsByReward)) {
                                        availableCardsByReward[rewardId] = [];
                                    }
                                    rewardCard['collection']['imgSrc'] = rewardCard.nfts[0]?.imgSrc;
                                    rewardCard['collection']['fallbackImgSrc'] = rewardCard.nfts[0]?.fallbackImgSrc;

                                    availableCardsByReward[rewardId].push(rewardCard);


                                }
                            });

                            if (rewardIsUnlimited) {

                                if (!(rewardId in availableCardsByReward)) {
                                    rewardCard['collection']['imgSrc'] = rewardCard.nfts[0]?.imgSrc;
                                    rewardCard['collection']['fallbackImgSrc'] = rewardCard.nfts[0]?.fallbackImgSrc;

                                    availableCardsByReward[rewardId] = [];
                                    availableCardsByReward[rewardId].push(rewardCard);
                                }



                            }

                        }
                    }
                });

                userClaims?.forEach(claim => {
                    const claimUsedNfts = claim.nfts_used_to_claim;

                    claimUsedNfts?.forEach((usedNft: any) => {
                        const usedId = usedNft.nft_id.original_nft_id;
                        const contractId = usedNft.nft_id.nft_contract_id;
                        let isInUserWallet = false;


                        // check if originalId in originals

                        originalNfts?.forEach(async (originalNft: any) => {

                            const originalId = originalNft.original_nft_id.original_nft_id;
                            const collectionTitle = originalNft.original_nft_id?.collection?.title || '';
                            let rewardCreatorAddress = originalNft.original_nft_id?.creator;

                            if (!rewardCreatorAddress || rewardCreatorAddress === '????') {
                                rewardCreatorAddress = creatorAddressByOriginalId[originalId];
                            }

                            const rewardCreatorName = findCreatorName(rewardCreatorAddress, profiles, []);
                            const rewardCreatorImgSrc = findCreatorImgSrc(rewardCreatorAddress, profiles, []);


                            if (usedId === originalId) {
                                // Match. This has been claimed.                             

                                // only add 1 card max for optional limited
                                // case optional, unlimited --> only show 1 card max, no matter how many nfts fulfill the conditions

                                const matchedNft = userNFTs ? userNFTs.find((userNft: any) => userNft.id === contractId) : null;

                                const theoricalNftImgSrc = `${STATIC_HOST}/${originalId}_thumb.jpg`;
                                const fallbackImgSrc = `${IPFS_NODE}/${matchedNft?.location}`;

                                let staticSrc = matchedNft ? getNftStatics(matchedNft).thumb : null;

                                // console.log('matchedNft:', matchedNft);
                                if (matchedNft) {
                                    isInUserWallet = true;
                                }
                                else {
                                    isInUserWallet = false;
                                    // if user no longer has the nft, get details of the missing nft
                                    try {
                                    } catch (error) {
                                        // console.error(error);
                                    }
                                }

                                const src = staticSrc ? staticSrc : theoricalNftImgSrc;

                                // if (!(rewardId in claimedCardsByReward)) {
                                if (true) {

                                    const claimedRewardCard: RewardCardType = {
                                        allNftsRequired: includeAll,
                                        collection: {
                                            imgSrc: src,
                                            fallbackImgSrc: fallbackImgSrc,
                                            title: collectionTitle,
                                        },
                                        contractIds: null,
                                        creator: {
                                            address: rewardCreatorAddress,
                                            imgSrc: rewardCreatorImgSrc,
                                            name: rewardCreatorName,
                                        },
                                        desc: rewardDescription,
                                        id: null,
                                        fallbackImgSrc: fallbackImgSrc,
                                        imgSrc: src,
                                        nfts: [
                                            {
                                                copy: `${usedNft.nft_id?.copy_number?.toString() || 'n'}/${usedNft.nft_id?.max_copy_number?.toString() || 't'}`,
                                                id: contractId,
                                                originalId: originalId,
                                                title: usedNft.title,
                                                fallbackImgSrc: fallbackImgSrc,
                                                imgSrc: src,
                                            }
                                        ],
                                        rewardClaimedBy: {},
                                        rewardClaimType: rewardClaimType as RewardClaimType,
                                        rewardCreatedAt: rewardCreatedAt,
                                        rewardUpdatedAt: rewardUpdatedAt,
                                        rewardId: rewardId,
                                        instructions: rewardInstructions,
                                        isClaimed: true, // claimed
                                        isInUserWallet: isInUserWallet,
                                        isUnlimited: rewardIsUnlimited,
                                        title: rewardTitle,
                                        type: rewardType
                                    };

                                    claimedRewardCard['collection']['imgSrc'] = claimedRewardCard.nfts[0]?.imgSrc;
                                    claimedRewardCard['collection']['fallbackImgSrc'] = claimedRewardCard.nfts[0]?.fallbackImgSrc;

                                    if (!rewardIsUnlimited) { // only limited claims

                                        // we don't show claimed cards that are not in wallet (no cards with disabled state)
                                        if (claimedRewardCard.isInUserWallet === true) {
                                            let list = claimedCardsByReward[rewardId.toString()] || [];
                                            list = [...list, claimedRewardCard];
                                            claimedCardsByReward[rewardId.toString()] = list;
                                        }
                                    } else {

                                        if (!(rewardId in unlimitedClaimedByRewardId)) {
                                            unlimitedClaimedByRewardId[rewardId] = {}
                                        }
                                        if (!(contractId in unlimitedClaimedByRewardId[rewardId])) {
                                            unlimitedClaimedByRewardId[rewardId][contractId] = true;
                                        }
                                    }
                                }
                            }


                        })
                    })
                })


            } else if (rewardClaimType === 'mandatory') {

                let stackLengths = {};
                let stacks = {};

                if (rewardIsUnlimited) {

                    originalNfts?.forEach((originalNft: any) => {

                        const id = originalNft.original_nft_id.original_nft_id;

                        const collectionTitle = originalNft.original_nft_id?.collection?.title || '';
                        rewardCard['collection']['title'] = collectionTitle;


                        let rewardCreatorAddress = originalNft.original_nft_id?.creator;

                        const candidates = reward.user_nfts_that_fullfill_conditions[id];
                        stacks[id] = [];

                        if (candidates) {
                            stackLengths[id] = candidates.length;
                            candidates?.forEach((nft: any) => {

                                if (!rewardCreatorAddress || rewardCreatorAddress === '????') {
                                    rewardCreatorAddress = nft.batch?.creator;
                                }

                                stacks[id].push(nft);
                            })

                            rewardCard['creator']['address'] = rewardCreatorAddress;
                            const creatorName = findCreatorName(rewardCreatorAddress, profiles, []);
                            const creatorImgSrc = findCreatorImgSrc(rewardCreatorAddress, profiles, []);

                            rewardCard['creator']['name'] = creatorName;
                            rewardCard['creator']['imgSrc'] = creatorImgSrc;

                            if (rewardCreatorAddress) {
                                creatorNameByAddress[rewardCreatorAddress] = creatorName;
                                creatorImgSrcByAddress[rewardCreatorAddress] = creatorImgSrc;
                                creatorAddressByOriginalId[id] = rewardCreatorAddress;
                            }
                        } else {
                            stackLengths[id] = 0;
                        }

                    });

                    const lengths = Object.values(stackLengths);
                    const shortestStack = lengths.reduce((a: number, b: number) => Math.min(a, b));

                    for (let i = 0; i < shortestStack; i++) {

                        for (const [key, value] of Object.entries<any>(stacks)) {
                            const nft = stacks[key][i];

                            const { title } = splitContentHash(nft.contentHash);
                            const copyNumber = nft.copy_number?.toString() || 'n';
                            const maxCopyNumber = nft.max_copy_number?.toString() || 't';
                            const copy = copyNumber + '/' + maxCopyNumber;

                            const nftImgSrc = `${STATIC_HOST}/${nft.original_token_id}_thumb.jpg`;

                            const matchedNft = userNFTs.find((userNft: any) => userNft.id === nft.id);
                            let isInUserWallet = false;
                            let staticSrc = matchedNft ? getNftStatics(matchedNft).thumb : null;
                            const fallbackImgSrc = `${IPFS_NODE}/${matchedNft?.location}`;

                            if (matchedNft) {
                                isInUserWallet = true;
                            }
                            else {
                                isInUserWallet = false;
                            }

                            const src = staticSrc ? staticSrc : nftImgSrc;
                            rewardCard['imgSrc'] = src;
                            rewardCard['fallbackImgSrc'] = fallbackImgSrc;
                            rewardCard['isInUserWallet'] = isInUserWallet;


                            rewardCard['nfts'].push({
                                copy: copy,
                                id: nft.id,
                                originalId: nft.original_token_id,
                                title: title,
                                fallbackImgSrc: fallbackImgSrc,
                                imgSrc: src,
                            });
                        }

                        if (!(rewardId in availableCardsByReward)) {
                            // case unlimited --> only show 1 card max, no matter how many nfts fulfill the conditions

                            rewardCard['collection']['imgSrc'] = rewardCard.nfts[0]?.imgSrc;
                            rewardCard['collection']['fallbackImgSrc'] = rewardCard.nfts[0]?.fallbackImgSrc;

                            availableCardsByReward[rewardId] = [];
                            availableCardsByReward[rewardId].push(rewardCard);
                        }
                    }
                }

            }
        });
    }


    const getMissingData = async (card: any) => {

        if (card.isInUserWallet === false) {
            // if user no longer has the nft, get details of the missing nft
            try {
                const res = await MitoService.getNftDetail({ id: card.nfts[0].nft.id });
                const data = res?.data?.location;
                const staticSrc = data ? getNftStatics(data).thumb : null;
                card.nfts[0].imgSrc = staticSrc;
                return card;
            } catch (error) {
                // console.error(error);
            }
        };


    };


    /** set ids */
    for (const [key, value] of Object.entries<any>(availableCardsByReward)) {
        const cards = value.map((obj: any, i: number) => {
            const contractIds = obj.nfts.map((u: any) => u.id).join('-');
            let isClaimed = false;
            if (obj.rewardId in unlimitedClaimedByRewardId) {

                for (const [key, value] of Object.entries<any>(unlimitedClaimedByRewardId[obj.rewardId])) {
                    if (contractIds.includes(key)) {
                        isClaimed = true;
                    }
                }

            }

            return {
                ...obj,
                cardId: (i + 1).toString(),
                contractIds: contractIds,
                id: 'r' + obj?.rewardId.toString() + '-a' + (i + 1).toString() + '-' + contractIds,
                isClaimed: isClaimed ? true : obj.isClaimed
            };
        });
        availableCardsByReward[key] = cards;
    }

    for (const [key, value] of Object.entries<any>(claimedCardsByReward)) {
        const cards = value.map((card: any, i: number) => {
            const contractIds = card.nfts.map((u: any) => u.id).join('-');
            return {
                ...card,
                cardId: (i + 1).toString(),
                id: 'r' + card?.rewardId.toString() + '-c' + (i + 1).toString() + '-' + contractIds
            };
        });
        claimedCardsByReward[key] = cards;
    }

    for (const [key, value] of Object.entries(availableCardsByReward)) {
        availableCards = availableCards.concat(value);
    }

    for (const [key, value] of Object.entries(claimedCardsByReward)) {
        claimedCards = claimedCards.concat(value);
    }

    availableCards?.forEach((card: any) => {
        if (card.isClaimed === false) { unclaimedAvailableCards.push(card) };
    })


    // console.log('availableCards:', JSON.stringify(availableCards, null, 2));
    // console.log('claimedCards:', JSON.stringify(claimedCards, null, 2));
    // console.log('unclaimedAvailableCards:', unclaimedAvailableCards);

    return {
        availableCards: availableCards,
        claimedCards: claimedCards,
        unclaimedAvailableCards: unclaimedAvailableCards,
    }
}


export const getNftRewardCards = (rewardsData: Array<any>) => {

    let availableCardsByReward = {};
    let availableCards = [];

    rewardsData?.forEach(async (reward: any) => {

        const rewardId = reward.reward_id?.id;
        const rewardCreatedAt = reward.reward_id?.createdAt;
        const rewardUpdatedAt = reward.reward_id?.updatedAt;

        const rewardType = reward.reward_id?.type as RewardType;
        const rewardTitle = reward.reward_id?.title;
        const rewardDescription = reward.reward_id?.description;
        const rewardIsUnlimited = reward.reward_id?.max_claims === 0 ? true : false;
        const rewardInstructions = reward.reward_id?.instructions;
        const includeAll = reward.reward_id?.inlcude_all; // careful, property comes with typo

        const originalNfts = reward.reward_id?.original_nfts_in_reward;

        const rewardClaimType = reward.reward_id?.claim_type;

        let rewardCard: RewardCardType = {
            allNftsRequired: includeAll,
            collection: {
                fallbackImgSrc: '/placeholder.png',
                imgSrc: '/placeholder.png',
                title: '',
            },
            contractIds: '',
            creator: {
                address: '',
                imgSrc: '/placeholder.png',
                name: '',
            },
            desc: rewardDescription,
            id: '',
            fallbackImgSrc: '/placeholder.png',
            imgSrc: '/placeholder.png',
            instructions: rewardInstructions,
            nfts: originalNfts,
            rewardClaimedBy: {},
            rewardClaimType: rewardClaimType as RewardClaimType,
            rewardCreatedAt: rewardCreatedAt,
            rewardUpdatedAt: rewardUpdatedAt,
            rewardId: rewardId,
            isClaimed: false,
            isInUserWallet: true, // true or it will be disables?
            isUnlimited: rewardIsUnlimited,
            title: rewardTitle,
            type: rewardType
        };

        availableCardsByReward[rewardId] = [];
        availableCardsByReward[rewardId].push(rewardCard);

        availableCards.push(rewardCard);
    });

    // console.log('[ getNftRewardCards ] availableCards:', availableCards);
    return availableCards;

};


export const getCollectionRewardCards = (rewardsData: Array<any>) => {

    let availableCardsByReward = {};
    let availableCards = [];

    rewardsData?.forEach(async (reward: any) => {

        const rewardId = reward.id;
        const rewardCreatedAt = reward.createdAt;
        const rewardUpdatedAt = reward.updatedAt;

        const rewardType = reward.type as RewardType;
        const rewardTitle = reward.title;
        const rewardDescription = reward.description;
        const rewardIsUnlimited = reward.max_claims === 0 ? true : false;
        const rewardInstructions = reward.instructions;
        const includeAll = reward.inlcude_all; // careful, property comes with typo from BE

        const originalNfts = reward.original_nfts_in_reward;

        const rewardClaimType = reward.claim_type;

        let rewardCard: RewardCardType = {
            allNftsRequired: includeAll,
            collection: {
                fallbackImgSrc: '/placeholder.png',
                imgSrc: '/placeholder.png',
                title: '',
            },
            contractIds: '',
            creator: {
                address: '',
                imgSrc: '/placeholder.png',
                name: '',
            },
            desc: rewardDescription,
            id: '',
            fallbackImgSrc: '/placeholder.png',
            imgSrc: '/placeholder.png',
            instructions: rewardInstructions,
            nfts: originalNfts,
            rewardClaimedBy: {},
            rewardClaimType: rewardClaimType as RewardClaimType,
            rewardCreatedAt: rewardCreatedAt,
            rewardUpdatedAt: rewardUpdatedAt,
            rewardId: rewardId,
            isClaimed: false,
            isInUserWallet: true, // true or it will be disables?
            isUnlimited: rewardIsUnlimited,
            title: rewardTitle,
            type: rewardType
        };

        availableCardsByReward[rewardId] = [];
        availableCardsByReward[rewardId].push(rewardCard);

        availableCards.push(rewardCard);
    });

    return availableCards;

};