import { Dispatch, SetStateAction } from 'react';
import { createContext } from 'react';
import { CURRENCIES } from '~/constants';

export interface FiatConversionContextValue {
  currency: {
    code?: keyof typeof CURRENCIES;
    symbol?: string;
  };
  conversionRate: number;
  updateConversionRate: () => void;
  setCurrency: Dispatch<SetStateAction<FiatConversionContextValue['currency']['code']>>;
  formatFiat: (price: number | string) => string;
}
export const FiatConversionContext = createContext({} as FiatConversionContextValue);
