import { Contract, ContractInterface } from 'ethers';
import * as ABIs from '~/interfaces';
import { getAddress } from 'ethers/lib/utils';

export const checksumAddress = (address: string) => getAddress(address);

export interface Contracts {
  nftContract: Contract;
  newNftContract: Contract;
  bridgeNFT: Contract;
  royaltyContract: Contract;
  auctionHubContract: Contract;
  firstPriceContract: Contract;
  limitedTimedContract: Contract;
  limitedTimedBuyLimitContract: Contract;
  directSaleContract: Contract;
  firstPriceContractV2: Contract;
  limitedTimedContractV2: Contract;
  limitedTimedBuyLimitContractV2: Contract;
  directSaleContractV2: Contract;
  timedEditionContractMoonPay: Contract;
}

// ? To "Boot" up a set of contracts, call this function and pass in a provider/web3 instance

export function getEthersContracts(provider: any) {
  // example contract creations
  const nftContract = new Contract(
    checksumAddress(process.env.NEXT_PUBLIC_NFT as string),
    ABIs.NFTABI.abi as ContractInterface,
    provider,
  );

  const newNftContract = new Contract(
    checksumAddress(process.env.NEXT_PUBLIC_NEW_NFT as string),
    ABIs.NewNFTABI.abi as ContractInterface,
    provider,
  );

  const bridgeNFT = new Contract(
    checksumAddress(process.env.NEXT_PUBLIC_BRIDGE_ADDRESS as string),
    ABIs.BridgeNFT.abi as ContractInterface,
    provider,
  );

  const auctionHubContract = new Contract(
    checksumAddress(process.env.NEXT_PUBLIC_AUCTION_HUB_v2 as string),
    ABIs.AuctionHubABI.abi as ContractInterface,
    provider,
  );

  const directSaleContract = new Contract(
    checksumAddress(process.env.NEXT_PUBLIC_DIRECT_SALE as string),
    ABIs.DirectSale.abi as ContractInterface,
    provider,
  );

  const royaltyContract = new Contract(
    checksumAddress(process.env.NEXT_PUBLIC_ROYALTY as string),
    ABIs.Royalties.abi as ContractInterface,
    provider,
  );
  const firstPriceContract = new Contract(
    checksumAddress(process.env.NEXT_PUBLIC_FIRST_PRICE as string),
    ABIs.FirstPriceABI.abi as ContractInterface,
    provider,
  );
  const firstPriceContractV2 = new Contract(
    checksumAddress(process.env.NEXT_PUBLIC_FIRST_PRICE_v2 as string),
    ABIs.SubastaV2.abi as ContractInterface,
    provider,
  );
  const limitedTimedContract = new Contract(
    checksumAddress(process.env.NEXT_PUBLIC_LIMITED_EDITION as string),
    ABIs.LimitedTimedEdition.abi as ContractInterface,
    provider,
  );
  const limitedTimedContractV2 = new Contract(
    checksumAddress(process.env.NEXT_PUBLIC_LIMITED_EDITION_v2 as string),
    ABIs.VentaPorCopias.abi as ContractInterface,
    provider,
  );
  const limitedTimedBuyLimitContract = new Contract(
    checksumAddress(process.env.NEXT_PUBLIC_LIMITED_EDITION_BUY_LIMIT as string),
    ABIs.LimitedTimedEdition.abi as ContractInterface,
    provider,
  );

  const limitedTimedBuyLimitContractV2 = new Contract(
    checksumAddress(process.env.NEXT_PUBLIC_LIMITED_EDITION_BUY_LIMIT_v2 as string),
    ABIs.LimitedTimedEdition.abi as ContractInterface,
    provider,
  );

  const directSaleContractV2 = new Contract(
    checksumAddress(process.env.NEXT_PUBLIC_DIRECT_SALE_v2 as string),
    ABIs.DirectSale.abi as ContractInterface,
    provider,
  );

  const timedEditionContractMoonPay = new Contract(
    checksumAddress(process.env.NEXT_PUBLIC_LIMITED_EDITION_MOONPAY as string),
    ABIs.VentaPorCopiasMoonpayTx.abi as ContractInterface,
    provider,
  );

  const contracts = {
    nftContract,
    bridgeNFT,
    newNftContract,
    auctionHubContract,
    royaltyContract,
    firstPriceContract,
    firstPriceContractV2,
    limitedTimedContract,
    limitedTimedContractV2,
    directSaleContract,
    limitedTimedBuyLimitContractV2,
    directSaleContractV2,
    limitedTimedBuyLimitContract,
    timedEditionContractMoonPay,
  };
  return contracts;
}
