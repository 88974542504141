import { ToastProps, toast } from '~/components/_ui-kit/Toast';
import { useLang } from '~/store/languageContext/hooks';
import * as Toastify from 'react-toastify';

// TODO: locale
const toasters = {
  // Grey default toast for wallet change warn
  requestChainIdChange: {
    options: {
      delay: 3000,
    },
  },
  // Red toast when user rejects MetaMask
  chainIdRejected: {
    type: 'error',
    options: {
      autoClose: 3000,
    },
  },
  // Green toast when user is successfully connected to MetaMask
  chainIdAccepted: {
    type: 'success',
    options: {
      autoClose: 3000,
    },
  },
  // Red toast when user is connected to another network
  chainIdError: {
    type: 'error',
    options: {
      autoClose: 3000,
    },
  },

  txUncaughError: {
    type: 'error',
  },
  txRejectedByUser: {
    type: 'error',
    options: {
      autoClose: 5000,
    },
  },
  bidHasBeenClaimed: {
    type: 'error',
    options: {
      autoClose: 3000,
    },
  },
  nftHasBeenClaimed: {
    type: 'error',
    options: {
      autoClose: 3000,
    },
  },
  withdrawSuccess: {
    type: 'success',
    options: {
      autoClose: 3000,
    },
  },
  walletConnectRejected: {
    type: 'error',
    options: {
      autoClose: 3000,
    },
  },
  sendNFTSuccess: {
    type: 'success',
    options: {
      autoClose: 3000,
    },
  },
  cancelLotSuccess: {
    type: 'success',
  },
  removedRewardSuccess: {
    type: 'success',
  },
  loginRequired: {
    type: 'error',
  },
  auctionBidSuccess: {
    type: 'success',
    options: {
      autoClose: 5000,
    },
  },
  signaturePending: {},
  updateProfile: {
    type: 'success',
    options: {
      autoClose: 3000,
    },
  },
  tokenAccessDenied: {
    type: 'error',
  },
  walletAccessDenied: {
    type: 'error',
  },
};

export default function useToast() {
  const { t, lang } = useLang();
  const throwToast = (toastType: keyof typeof toasters, config?: Partial<ToastProps>) => {
    const toastConfig = toasters[toastType] as ToastProps;

    if (t(`Toast:${toastType}:desc`).replaceAll(' ', '') === '') {
      toast({
        message: t(`Toast:${toastType}:msg`),
        ...toastConfig,
        ...config,
      });
    } else {
      toast({
        message: t(`Toast:${toastType}:msg`),
        desc: t(`Toast:${toastType}:desc`),
        ...toastConfig,
        ...config,
      });
    }
  };

  const dismissToast = (id?: any) => {
    Toastify.toast.dismiss(id || undefined);
  };

  return { throwToast, dismissToast };
}
