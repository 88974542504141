import { css } from 'styled-components';

const backgroundGradient = css`
  ${({ theme }) => css`
    background: linear-gradient(to right, ${theme.colors.highlight}, #ffffff);
  `}
`;

const textGradient = css`
  background: linear-gradient(to right, #ffffff, #ffffff);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
`;

const textGradientColor = css`
  ${({ theme }) => css`
    ${commonStyles.backgroundGradient}
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
  `}
`;

const hoverBg = css`
  position: relative;

  :before {
    position: absolute;
    left: 0;
    top: 0;
    background-color: rgba(255, 255, 255, 0);
    border-radius: 12px;
    content: '';
    display: block;
    width: 100%;
    height: 100%;
  }

  :hover {
    :before {
      background-color: rgba(255, 255, 255, 0.05);
    }
  }
`;

const actionButton = css`
  ${({ theme }) => css`
    color: ${theme.colors.textColor} !important;
    background: linear-gradient(90deg, ${theme.colors.highlight}, white 100%);
    font-size: 14px;
    line-height: 1.2;
    border-radius: 999px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1px;
    width: fit-content;
    span {
      padding: 12px 16px;
      border-radius: 999px;
      width: 100%;
      text-align: center;
      background: ${theme.colors.background1};
      position: relative;
    }
    :hover {
      span {
        background: ${theme.colors.background4};
      }
    }
    :active {
      span {
        background: ${theme.colors.backgroundStrong};
      }
    }
    :focus:not(:active) {
      span {
        background: ${theme.colors.backgroundStrong};
        text-decoration: underline;
        box-shadow: 0px 0px 0px 2px ${theme.colors.backgroundContrast};
      }
    }
  `}
`;

const navigationButton = css`
  ${({ theme }) => css`
    color: ${theme.colors.textColor} !important;
    background: linear-gradient(90deg, ${theme.colors.highlight}, white 100%);
    font-size: 12px;
    line-height: 1.2;
    border-radius: 999px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1px;
    font-weight: 700;
    width: fit-content;
    & > span {
      padding: 8px 16px;
      border-radius: 999px;
      width: 100%;
      background: ${theme.colors.background1};
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        margin-left: 6px;
      }
      span {
        z-index: 2;
        position: relative;
      }
      ::before {
        content: '';
        display: block;
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
        border-radius: 999px;
        background: linear-gradient(90deg, ${theme.colors.highlight}, white 100%);
      }
    }
    :hover {
      & > span {
        ::before {
          opacity: 0.2;
        }
      }
    }
    :active {
      & > span {
        ::before {
          opacity: 0.5;
        }
      }
    }
    :focus-visible {
      & > span {
        background: ${theme.colors.backgroundStrong};
        text-decoration: underline;
        box-shadow: 0px 0px 0px 2px ${theme.colors.backgroundContrast};
      }
    }
  `}
`;

const link = css`
  ${({ theme }) => css`
    text-decoration: underline;
    color: ${theme.colors.highlight};
    &:hover,
    &:active {
      text-decoration: underline;
      color: ${theme.colors.textColor};
    }
  `}
`;

const grid = css`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: repeat(12, [col-start] 1fr);
    grid-gap: 16px;
    & > * {
      width: 100%;
      grid-column: span 12;
    }
    @media (min-width: 600px) {
      grid-gap: 24px;
      & > * {
        grid-column: span 6;
      }
    }
    @media (min-width: 960px) {
      grid-gap: 32px;
      & > * {
        grid-column: span 4;
      }
    }
    @media (min-width: 1280px) {
      & > * {
        grid-column: span 3;
      }
    }
  `}
`;

const gridResale = css`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: repeat(12, [col-start] 1fr);
    grid-gap: 16px 16px;
    & > * {
      width: 100%;
      grid-column: span 12;
    }

    @media (min-width: 600px) {
      grid-gap: 16px 16px;
      & > * {
        grid-column: span 6;
      }
    }
    @media (min-width: 768px) {
      grid-gap: 24px 24px;
      & > * {
        grid-column: span 4;
      }
    }
    @media (min-width: 1024px) {
      grid-template-columns: repeat(12, [col-start] 1fr);
      grid-gap: 32px 32px;

      & > * {
        grid-column: span 3;
      }
    }
    @media (min-width: 1440px) {
      grid-gap: 32px 32px;
      grid-template-columns: repeat(5, [col-start] 1fr);

      & > * {
        grid-column: span 1;
      }
    }
  `}
`;

const commonStyles = {
  textGradient,
  hoverBg,
  backgroundGradient,
  textGradientColor,
  actionButton,
  navigationButton,
  link,
  grid,
  gridResale,
};

export default commonStyles;
