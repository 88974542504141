import { useEffect } from 'react';

export default function useConsoleWarning() {
  useEffect(() => {
    if (process.env.NEXT_PUBLIC_ENVIRONMENT === 'LOCAL') return;
    console.info(
      `%cWARNING!`,
      'color: #990000; background: #FFFF99; font-size: 48px; font-weight: bold',
    );
    console.info(
      `%cThis is a browser feature intended for developers. If someone told you to copy-paste something here to enable a Mito feature or "hack" someone's account, it is a scam and will give them access to your Wallet. See https://en.wikipedia.org/wiki/Self-XSS for more information`,
      'color: #333; font-size: 16px;',
    );
  }, []);
}
