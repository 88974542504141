import Head from 'next/head';
import dynamic from 'next/dynamic';
import React, { useState, ReactNode } from 'react';
import { useRouter } from 'next/router';
import { useWallets } from '~/store/walletContext/WalletContext';
import classes from './Layout.module.scss';
import { Navbar } from '~/components';
import Footer from '../Footer/Footer';
import { TermsModal } from '../Modals/index';
import MobileMenu from '~/components/Menus/MobileMenu/MobileMenu';

const WalletModal = dynamic(import('~/components/WalletModal/WalletModal'));
const Alert = dynamic(import('~/components/_ui-kit/Alert'));
const DevBar = dynamic(import('../_utils/DevBar'));

import { useAppStatus } from '~/store/appContext/hooks';
import { externalLinks } from '../Footer/linkItems';
import { CustomMessage } from '~/store/services/mito/index';

import { useLang } from '~/store/languageContext/hooks';

// import styled, { css } from 'styled-components';
import { iLotNFT } from '~/store/services/mito/mito.models';

interface iLayout {
  children?: React.ReactChild;
  nft?: iLotNFT;
  backgroundRef?: string;
  userData?: any;
  isArtist?: boolean;
  magicBg?: boolean;
}

export default function Layout({ children, backgroundRef, magicBg = false }: iLayout) {
  const { showWalletModal, showTermsModal, setShowWalletModal } = useWallets();
  const [showMenu, setShowMenu] = useState(false);

  const router = useRouter();
  const path = router.pathname;

  const { t } = useLang();

  const resolveBackground = (ref: string) => {
    switch (ref) {
      case 'code':
        return classes.rewardsCodeBg;
      case 'download':
        return classes.rewardsDownloadBg;
      case 'link':
        return classes.rewardsLinkBg;
      default:
        return classes.defaultBg;
    }
  };

  return (
    <>
      <Head>
        {/* Home */}
        {path === '/' && <title key="title">{t('Meta:home')}</title>}
        {/* Marketplace */}
        {path === '/marketplace' && <title key="title">{t('Meta:marketplace')}</title>}
        {/* Collections */}
        {path === '/collections' && <title key="title">{t('Meta:collections')}</title>}

        {/* Common for all routes */}
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta property="og:image" content="https://mito.io/mito-og-image.png" />
        <meta name="twitter:image" content="https://mito.io/mito-og-image.png" />
        <meta name="description" content="MITO - NFT Digital Art gallery" />
        <meta name="keywords" content="Art, NFT, Blockchain" />
        <meta name="author" content="MITO" />

        <meta property="og:description" content="MITO - NFT Digital Art gallery" />
        <meta property="og:url" content="https://mito.io/" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://mito.io/" />
        <meta property="twitter:description" content="MITO - NFT Digital Art gallery" />

        <link key="favicon" rel="icon" href="/logo.svg" color="#FA9579" />
        <script async>
          {/* Tik tok pixel */}
          {`!function (w, d, t) {
            w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++
              )ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};n=document.createElement("script");n.type="text/javascript",n.async=!0,n.src=i+"?sdkid="+e+"&lib="+t;e=document.getElementsByTagName("script")[0];e.parentNode.insertBefore(n,e)};
                    ttq.load('${process.env.NEXT_PUBLIC_TIKTOK_PIXEL_ID}');
                    ttq.page();
                  }(window, document, 'ttq');
                  `}
          {/* Mailer lite newsletter form */}
          {`
          (function(w,d,e,u,f,l,n){w[f]=w[f]||function(){(w[f].q=w[f].q||[])
            .push(arguments);},l=d.createElement(e),l.async=1,l.src=u,
            n=d.getElementsByTagName(e)[0],n.parentNode.insertBefore(l,n);})
            (window,document,'script','https://assets.mailerlite.com/js/universal.js','ml');
            ml('account', '186471');
            `}
        </script>
        <script async>
          {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
              'https://www.googletagmanager.com/gtm.js?id=%27+i+dl;f.parentNode.insertBefore(j,f);
              })(window,document,'script','dataLayer','GTM-T7CT9SQ');
            `}
        </script>
      </Head>
      <WarningMaintenanceAlert />
      <DevBar />
      <div className={`${classes.Layout} ${resolveBackground(backgroundRef)}`} id="btnOpenWidget">
        {showWalletModal && <WalletModal setIsOpen={setShowWalletModal} />}
        <MobileMenu isVisible={showMenu} setShowMenu={setShowMenu} />
        {showTermsModal && <TermsModal />}
        <Navbar setShowMenu={setShowMenu} />
        <main className={classes.content}>{children}</main>
        <Footer />
      </div>
    </>
  );
}

function WarningMaintenanceAlert() {
  const { warningMode, customMode, message } = useAppStatus();
  const { t, lang } = useLang();

  const twitterHref =
    externalLinks?.find((l) => l.path.includes('twitter'))?.path || 'https://twitter.com';

  const twitterLink = (
    <a target="_blank" href={twitterHref}>
      Twitter
    </a>
  );

  const maintenanceMsg = {
    title: t('Layout:maintenance_title'),
    desc: t('Layout:maintenance_desc'),
  };

  const getCustomMsg = (message: CustomMessage, lang: string): string => {
    if (message && lang === 'es') {
      return message.es ? message.es : null;
    } else if (message && lang === 'en') {
      return message.en ? message.en : null;
    } else {
      return null;
    }
  };

  const replaceWithJSX = (source: string, find: string, replace: ReactNode) => {
    const items = source.split(find);
    return items.length > 0 ? [items[0], replace, items[1]] : items;
  };

  const maintenanceDesc = replaceWithJSX(maintenanceMsg.desc, '{twitter_link}', twitterLink);

  const customMsg = getCustomMsg(message, lang);

  if (warningMode)
    return (
      <div className={classes.AlertContainer}>
        <Alert message={maintenanceMsg.title} desc={maintenanceDesc} />
      </div>
    );
  else if (customMode && customMsg)
    return (
      <div className={classes.AlertContainer}>
        <Alert message={customMsg} />
      </div>
    );
  return null;
}

// const AlertContainer = styled.div`
//   ${({ theme }) => css`
//     cursor: pointer;
//     display: flex;
//     align-items: center;
//     background: ${theme.colors.background3};
//     border-radius: 0;
//     width: 100%;
//     text-align: center;
//     justify-content: center;
//     align-items: center;
//     position: relative;

//     > div:nth-child(2) {
//       position: absolute;
//       right: 12px;
//     }

//     ${theme.device.mobile} {
//       text-align: left;
//       justify-content: flex-start;
//       align-items: center;
//     }

//     > div {
//       min-height: 48px;
//       display: flex;
//       align-items: center;
//       justify-content: center;

//       ${theme.device.mobile} {
//         text-align: left;
//         justify-content: flex-start;
//         align-items: center;
//         height: 78px;
//       }

//       > span {
//         font-family: Inter;
//         font-size: 14px;
//         font-style: normal;
//         font-weight: 700;
//         line-height: 18px;
//         letter-spacing: 0px;
//         color: white;
//         margin: 0;

//         > span {
//           color: #fa9579;
//           margin-left: 3px;
//         }
//       }
//     }

//     ${theme.device.mobile} {
//       text-align: start;
//       justify-content: flex-start;
//     }
//   `}
// `;
