import { ReactNode } from 'react';
import { RiCheckboxCircleFill, RiErrorWarningFill } from 'react-icons/ri';
import styled, { css } from 'styled-components';

export interface AlertProps {
  message: string;
  desc?: string | ReactNode;
  icon?: ReactNode;
  iconSize?: 'default' | 'large';
  type?: 'success' | 'error' | 'warning' | null;
  secureCloseArea?: boolean;
}

const Alert = ({ message, desc, icon, iconSize, ...root }: AlertProps) => {
  return (
    <Root desc={desc} {...root}>
      <IconWrapper iconSize={iconSize || 'default'}>
        {icon || (root.type === 'success' && <RiCheckboxCircleFill />) || <RiErrorWarningFill />}
      </IconWrapper>
      <Container>
        <Title>{message}</Title>
        {desc && <Desc>{desc}</Desc>}
      </Container>
    </Root>
  );
};

export default Alert;

/** Styled components */

type RootProps = Pick<AlertProps, 'secureCloseArea' | 'type' | 'desc'>;
const Root = styled.div<RootProps>`
  ${({ theme, type, secureCloseArea, desc }) =>
    css`
      padding: 12px;
      border-radius: 16px;
      display: flex;
      padding-right: ${secureCloseArea && '48px'};
      background: ${theme.colors.background3};
      color: ${theme.colors.textColor};
      position: relative;
      overflow: hidden;
      align-items: ${!desc && 'center'};

      ${type === 'error' &&
      css`
        background: ${theme.colors.state.dangerBg};
        color: ${theme.colors.state.dangerFg};
      `}
      ${type === 'success' &&
      css`
        background: ${theme.colors.state.validBg};
        color: ${theme.colors.state.validFg};
      `}
      ${type === 'warning' &&
      css`
        background: ${theme.colors.state.warningBg};
        color: ${theme.colors.state.warningFg};
      `}
    `}
`;

const Container = styled.div`
  ${({ theme }) => css`
    flex: 1 1 auto;
    z-index: 1;
    /* padding-top: 1px; */
  `}
`;

const Title = styled.div`
  ${({ theme }) => css`
    font-size: 14px;
    line-height: 125%;
    font-weight: 700;
    text-align: start;
  `}
`;

const Desc = styled.div`
  ${({ theme }) => css`
    font-size: 12px;
    margin-top: 4px;
    font-weight: 500;
    line-height: 125%;
    text-align: start;
    color: ${theme.colors.textColorLight};

    a {
      text-decoration: underline;
    }
  `}
`;

interface IconWrapperProps {
  iconSize: AlertProps['iconSize'];
}
const IconWrapper = styled.div<IconWrapperProps>`
  ${({ theme, iconSize }) => css`
    display: contents;
    svg {
      flex: 0 0 auto;
      font-size: 20px;
      z-index: 1;
      margin-right: 8px;
    }
    ${iconSize === 'large' &&
    css`
      svg {
        font-size: 32px;
        margin-right: 16px;
      }
    `}
  `}
`;
