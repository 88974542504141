import types from './actionTypes';
import { bid } from './services/nft';
import { formatEther, getUserRoyalties } from './services/royalties';
import { getUserRewardCards } from '~/store/services/rewards';

export const applyMiddleware = (dispatch: any) => (action: any) => {
  switch (action.type) {  
    case types.GetRoyalties.GET_ROYALTIES_REQUEST:
      return getUserRoyalties(action.payload)
        .then((res) => {
          dispatch({
            type: types.GetRoyalties.GET_ROYALTIES_SUCCESS,
            payload: formatEther(res),
          });
        })
        .catch((err) => {
          dispatch({
            type: types.GetRoyalties.GET_ROYALTIES_FAIL,
            payload: err.response,
          });
        });
    case types.Bid.BID_REQUEST:
      return bid(action.payload)
        .then((res) => {
          dispatch({
            type: types.Bid.BID_SUCCESS,
            payload: res,
          });
        })
        .catch((err) => {
          dispatch({
            type: types.Bid.BID_FAIL,
            payload: err,
          });
        });
    case types.setUserRewards.SET_USER_REWARDS_REQUEST:
      return getUserRewardCards(action.payload)
        .then((res) => {
          dispatch({
            type: types.setUserRewards.SET_USER_REWARDS_SUCCESS,
            payload: res,
          });
        })
        .catch((err) => {
          dispatch({
            type: types.setUserRewards.SET_USER_REWARDS_FAIL,
            payload: err,
          });
        });
    default:
      dispatch(action);
  }
};
