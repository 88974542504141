import {
  NotificationsContextValue as Value,
  notificationsInitialState as InitialState,
  notificationsInitialState,
} from '.';

export type NotificationsActionTypes = 'fetching' | 'success' | 'error' | 'reset';

export const notificationsReducer = (
  state: Value['state'] = InitialState,
  action: {
    type: NotificationsActionTypes;
    payload?: Partial<Value['state']>;
  },
) => {
  switch (action.type) {
    case 'fetching':
      return {
        ...state,
        ...(action.payload || {}),
        loading: !state.data,
        updating: true,
      };
    case 'success':
      return {
        ...state,
        loading: false,
        updating: false,
        error: null,
        data: action.payload?.data || {},
      };
    case 'error':
      return {
        ...state,
        loading: false,
        updating: false,
        data: null,
        error: action.payload?.error,
      };
    case 'reset':
      return notificationsInitialState;
    default:
      return { ...state, error: 'Uncaugh type' };
  }
};
