interface Link<T> {
  path: string;
  label: T;
  external: boolean;
}

export const externalLinks: Link<string>[] = [
  {
    path: 'https://www.instagram.com/mitogallery/',
    label: 'instagram',
    external: true,
  },
  {
    path: 'https://www.tiktok.com/@rungielabs?lang=en',
    label: 'tiktok',
    external: true,
  },
  {
    path: 'https://twitter.com/mitogallery',
    label: 'twitter',
    external: true,
  },
  {
    path: 'https://discord.com/invite/5AfYBHseej',
    label: 'discord',
    external: true,
  },
  {
    path: 'https://medium.com/@mitogallery',
    label: 'medium',
    external: true,
  },
];

export const faqsAbout = [
  {
    label: 'FAQ:about:q1',
    redirects: {
      es: 'https://rungie.notion.site/Qu-es-MITO-eb0668ee0a47472490780751f4d71a2e',
      en: 'https://rungie.notion.site/What-is-MITO-a691a45580d747e5811d27e71e3533b1',
    },
  },
  {
    label: 'FAQ:about:q2',
    redirects: {
      es: 'https://rungie.notion.site/C-mo-funciona-MITO-119b5ea9979c4ce88f081b74b445b78e',
      en: 'https://rungie.notion.site/How-does-MITO-work-569ca07c66564795ac9ec0e8d9bb3b4c',
    },
  },
  {
    label: 'FAQ:about:q3',
    redirects: {
      es: 'https://rungie.notion.site/Cu-nto-consume-la-creaci-n-de-un-NFT-en-MITO-879b3db7231e4a89bb16b54842592ca8',
      en: 'https://rungie.notion.site/How-much-does-the-creation-of-an-NFT-in-MITO-consume-c930b822da6c4b3ba1c3242b9c4b9dcf',
    },
  },
  {
    label: 'FAQ:about:q4',
    redirects: {
      es: 'https://rungie.notion.site/Contacta-con-MITO-af65982e18ce4cada0a2ac9db0032943',
      en: 'https://rungie.notion.site/Contact-MITO-b6c516aab05b495bb9fa2b035206079b',
    },
  },
];

export const faqsMarket = [
  {
    label: 'FAQ:market:q1',
    redirects: {
      es: 'https://rungie.notion.site/C-mo-puedo-comprar-una-obra-2db01bb182de4b888e7f374c49a31376',
      en: 'https://rungie.notion.site/How-can-I-buy-an-artwork-313c0d217adc4a8d95118315b985e00c',
    },
  },
  {
    label: 'FAQ:market:q2',
    redirects: {
      es: 'https://rungie.notion.site/Qu-es-una-wallet-C-mo-puedo-crear-una-bfda3b01b0cf44dca8bdb2fc7379e0e9',
      en: 'https://rungie.notion.site/What-is-a-wallet-How-can-I-create-one-23cb2186b4384413b7473ad7e7d8ce99',
    },
  },
  {
    label: 'FAQ:market:q3',
    redirects: {
      es: 'https://rungie.notion.site/C-mo-funcionan-las-subastas-02355fd29f71402da6e150f6948feb60',
      en: 'https://rungie.notion.site/How-do-the-auctions-work-cef0edc1d5db44468e8de452a996d9d4',
    },
  },
  {
    label: 'FAQ:market:q4',
    redirects: {
      es: 'https://rungie.notion.site/C-mo-puedo-revender-en-el-marketplace-f311ad1074154364abb8c7510e8f1dbe',
      en: 'https://rungie.notion.site/Can-I-resell-on-a-marketplace-cdbc8d2b4ca840a58bbd761f2101ebaf',
    },
  },
];

export const internalLinks: Link<string>[] = [
  {
    path: 'link:conditions',
    label: 'Footer:conditions',
    external: true,
  },
  {
    path: 'link:privacy',
    label: 'Footer:privacy',
    external: true,
  },
  {
    path: 'link:faq',
    label: 'Footer:help',
    external: true,
  },
];
