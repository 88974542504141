import _orderBy from 'lodash/orderBy';
import { useCallback } from 'react';
import { useLang } from '~/store/languageContext/hooks';
import { Lot } from '~/store/services/graph/types';
import { useUsers } from '~/store/userContext/hooks';
import { ResponseOf } from '~/typescript';
import { replaceMany, getCorrectAuctionType } from '~/utils';
import { formatPrice, localePrice } from '~/ether-utils';
import NotificationsQueries from './queries';
import { NotificationsTable } from './types';

type Lots = ResponseOf<typeof NotificationsQueries.lots>;

/** Exporta utilidades para el módulo de notifications */
export function useNotificationUtils() {
  const { tBySplit, lang, t } = useLang();
  const { getUser } = useUsers();

  /** Mapea la data de los Lots a Notificaciones */
  const getNotificationsByLot = useCallback(
    (lots: Lots, userAddress: string, royalties: number, unclaimedAvailableCards: Array<any>) => {
      let notifications: NotificationsTable = {};
      let creatorSales: {
        id: string;
        amount: string;
        tokenName: string;
        timestamp: string;
        buyer: string;
      }[] = [];

      // Itera sobre los lots para recuperar las bids y los sales
      lots.forEach((lot) => {
        const lotData = getLotData(lot, userAddress);
        const {
          isBidder,
          isEndedAuction,
          isHighestBidder,
          highestBid,
          userBid,
          canClaimRefund,
          canClaimToken,
          isCreator,
          isSeller,
          endedByTime,
          userSell,
          isEndedSell,
        } = lotData;
        const tokenName = tBySplit(lot.token.contentHash)?.split('/')[0];

        // Si el usuario ha vendido el NFT se añade la notificación
        if (isSeller && isEndedSell) {
          const id = `sell_success-${userSell.id}`;
          notifications = {
            ...notifications,
            [id]: {
              id,
              date: timestampToISO(userSell.timestamp),
              title: t('Notifications:success_buy:title'),
              desc: replaceMany(t('Notifications:success_buy:desc'), {
                '{nft}': <strong>{tokenName}</strong>,
                '{amount}': <strong>{formatPrice(userSell.price, 3, 0, lang)} MATIC</strong>,
              }),
              icon: 'Happy',
            },
          };
        }

        // Tu puja ha sido superada
        if (isBidder && !isEndedAuction && !isHighestBidder) {
          const id = `bid_surpassed-${userBid.id}`;
          notifications = {
            ...notifications,
            [id]: {
              id,
              date: timestampToISO(highestBid.timestamp),
              title: t('Notifications:bid_surpassed:title'),
              desc: replaceMany(t('Notifications:bid_surpassed:desc'), {
                '{nft}': <strong>{tokenName}</strong>,
              }),
              icon: 'Sad',
              action: {
                text: t('Notifications:bid_surpassed:link'),
                src: lot.id,
                type: 'goToNFTDetail',
              },
            },
          };
        }

        // Tienes una obra pendiente de reclamar
        if (canClaimToken) {
          const id = `claim_winner-${userBid.id}`;
          notifications = {
            ...notifications,
            [id]: {
              id,
              date: timestampToISO(highestBid.timestamp),
              title: t('Notifications:claim_winner:title'),
              desc: replaceMany(t('Notifications:claim_winner:desc'), {
                '{nft}': <strong>{tokenName}</strong>,
                '{amount}': <strong>{formatPrice(userBid.amount, 3, 0, lang)} MATIC</strong>,
              }),
              icon: 'Happy',
              action: {
                text: t('Notifications:claim_winner:link'),
                src: lot.id,
                type: 'goToNFTDetail',
              },
            },
          };
        }

        // Tienes una un reembolso pendiente de reclamar
        if (canClaimRefund) {
          const id = `claim_loser-${userBid.id}`;
          notifications = {
            ...notifications,
            [id]: {
              id,
              date: timestampToISO(highestBid.timestamp),
              title: t('Notifications:claim_loser:title'),
              desc: replaceMany(t('Notifications:claim_loser:desc'), {
                '{nft}': <strong>{tokenName}</strong>,
                '{amount}': <strong>{formatPrice(userBid.amount, 3, 0, lang)} MATIC</strong>,
              }),
              icon: 'Sad',
              action: {
                text: t('Notifications:claim_loser:link'),
                src: lot.id,
                type: 'goToNFTDetail',
              },
            },
          };
        }

        // Añade las ventas del lot si el user es el artista
        if (isCreator) {
          lot.sales.forEach((sale) => {
            creatorSales.push({
              id: sale.id,
              timestamp: sale.timestamp,
              tokenName,
              amount: sale.price || highestBid.amount,
              buyer: sale.buyer,
            });
          });
        }
      });

      // Notificación por recompensas disponibles no reclamadas
      unclaimedAvailableCards?.forEach((card) => {
        const id = card.id;
        if (!card.isClaimed) {
          notifications = {
            ...notifications,
            [id]: {
              id,
              date: card.rewardUpdatedAt,
              title: t('Notifications:rewards:title'), 
              desc: `${t('Notifications:rewards:msg')}${card.creator?.name}`,
              icon: 'Gift',
              action: {
                text: t('Notifications:rewards:action'),
                src: userAddress,
                type: 'goToMyRewards',
              },
            },
          };
        }

      });

      // Notificación con los royalties generados
      if (royalties > 0 && creatorSales.length > 0) {
        const lastSale = _orderBy(creatorSales, ['timestamp'], ['desc'])[0];
        const id = `royalties_to_claim-${lastSale.id}`;
        const moreSalesCount = creatorSales.length - 1 > 0;
        notifications = {
          ...notifications,
          [id]: {
            id,
            date: timestampToISO(lastSale.timestamp),
            title: t('Notifications:royalties_to_claim:title'),
            desc: (
              <>
                {replaceMany(t('Notifications:royalties_to_claim:desc'), {
                  '{nft}': <strong>{lastSale.tokenName}</strong>,
                  '{royalties}': <strong>{localePrice(royalties, 3, 0, lang)} MATIC</strong>,
                  '{amount}': <strong>{formatPrice(lastSale.amount, 3, 0, lang)} MATIC</strong>,
                  '{buyer}': <strong>{getUser(lastSale.buyer)?.name}</strong>,
                })}
                {moreSalesCount && (
                  <strong>
                    {replaceMany(t('Notifications:royalties_to_claim:more'), {
                      '{salesCount}': creatorSales.length - 1,
                    })}
                  </strong>
                )}
              </>
            ),
            icon: 'Happy',
            action: {
              text: t('Notifications:royalties_to_claim:link'),
              src: lastSale.id,
              type: 'claim',
            },
          },
        };
      }

      return notifications;
    },
    [lang],
  );




  /** Get notifications for available rewards */
  // const getNotificationsByReward = useCallback(
  //   (userAddress: string, unclaimedAvailableCards: Array<any>) => {
  //     let notifications: NotificationsTable = {};

  //     unclaimedAvailableCards.forEach((card) => {
  //       const id = card.id;
  //       notifications = {
  //         ...notifications,
  //         [id]: {
  //           id,
  //           date: card.rewardUpdatedAt,
  //           title: "Nueva recompensa",
  //           // desc: `Tienes disponible una recompensa por ser coleccionista de ${card.collection.title}`,
  //           desc: `Tienes disponible una recompensa por ser coleccionista de ${card.creator?.name}`,
  //           icon: 'Gift',
  //           action: {
  //             text: 'Ver recompensa',
  //             src: userAddress,
  //             type: 'goToMyRewards',
  //           },
  //         },
  //       };

  //     });

  //     return notifications;
  //   },
  //   [lang],
  // );



  return { getNotificationsByLot };
}

/** Transforma el timestamp de The Graph en ISO Date string */
const timestampToISO = (timestamp: string) =>
  timestamp ? new Date(Number(timestamp) * 1000).toISOString() : null;

/** Recupera información de un lot */
function getLotData(lot: Lot, userAddress: string) {
  const isBuyer = lot.sales.some((s) => s.buyer === userAddress);
  const isBidder = lot.bids.some((s) => s.bidder === userAddress);
  const isSeller = lot.sales.some((s) => s.seller === userAddress);
  const isCreator = lot.token?.creator === userAddress;

  const isFirstPrice = getCorrectAuctionType(lot.auction?.id, 'firstPrice');
  const isTimedEdition = getCorrectAuctionType(lot.auction?.id, 'timedEdition');

  const endTime = Number(lot.endTime) * 1000;
  const endedByTime = endTime < Date.now();
  const endedByEditions = lot.sales?.length >= Number(lot.limit);
  const isEndedAuction = isFirstPrice && endedByTime;
  const isEndedTimedEdition = isTimedEdition && (endedByTime || endedByEditions);
  const isEndedLot = isEndedAuction || isEndedTimedEdition;

  const sortedBids = _orderBy(lot.bids, ['timestamp'], ['desc']);
  const highestBid = sortedBids[0];

  const userBid = lot.bids?.find((b) => b.bidder === userAddress);
  const isHighestBidder = highestBid?.bidder === userAddress;
  const userSell = lot.sales?.find((b) => b.seller === userAddress);
  const isEndedSell = Number(userSell?.timestamp) < Date.now();

  const canClaim = userBid && isEndedAuction && !userBid.claimed;
  const canClaimToken = canClaim && isHighestBidder;
  const canClaimRefund = canClaim && !isHighestBidder;

  return {
    isBuyer,
    isBidder,
    isFirstPrice,
    isTimedEdition,
    endTime,
    endedByTime,
    endedByEditions,
    isEndedAuction,
    isEndedTimedEdition,
    isEndedLot,
    sortedBids,
    highestBid,
    userBid,
    isHighestBidder,
    isCreator,
    canClaim,
    canClaimToken,
    canClaimRefund,
    isSeller,
    userSell,
    isEndedSell,
  };
}
