import { ReactNode, useEffect, useMemo, useState } from 'react';
import MoonpayService from '../services/moonpay';
import { useStore } from '../store';
import { FiatConversionContext, FiatConversionContextValue } from './context';
import * as Sentry from '@sentry/react';
import { logClient } from '~/utils';
import { CURRENCIES } from '~/constants';

export type Currency = FiatConversionContextValue['currency'];
export type CurrencyCode = FiatConversionContextValue['currency']['code'];

const LOCALSTORAGE_KEY = 'currency';

const UPDATE_RATE = 60; // Seconds

export function FiatConversionProvider(props: { children: ReactNode }) {
  const { state, actions } = useStore();
  const [currencyCode, setCurrencyCode] = useState<CurrencyCode>();
  const currency = (CURRENCIES[currencyCode] as Currency) || {};
  const { conversionRate } = state;

  const setCurrency = (value?: CurrencyCode) => {
    if (!value) return;
    setCurrencyCode(value);
    localStorage.setItem(LOCALSTORAGE_KEY, value);
  };

  /** Actualiza el conversion rate a Fiat con la currency fiat especificada */
  const updateConversionRate = async () => {
    try {
      const res = await MoonpayService.getConversionRate({ currency: 'matic_polygon' });
      const { data: conversionRates } = res;
      const rate = conversionRates[currencyCode];
      actions.setConversionRate(rate);
      logClient(`%c ** Conversion rate: ${rate}`, 'background: #eee; color: #555');
    } catch (error) {
      logClient('No se ha podido obtener el conversion rate', error);
      Sentry.captureException(error);
    }
  };

  /** Formate un precio Fiat a la currency seleccionada */
  const formatFiat = (price?: number | string): string => {
    try {
      const symbol = CURRENCIES[currencyCode].symbol;
      const template = CURRENCIES[currencyCode].template;
      const result = template.replace('{symbol}', symbol).replace('{price}', price.toString());
      return `(${result})`;
    } catch {
      return '';
    }
  };

  // Inicializa la currency con la configuración del usuario
  useEffect(() => {
    const storedCurrency = localStorage.getItem(LOCALSTORAGE_KEY) as CurrencyCode;
    const browserLocale = navigator?.languages?.map((l) => l.slice(3, 5))?.find((l) => l);
    const detectedCurrency = Object.values(CURRENCIES).find((curr) =>
      curr.locales.some((locale) => locale === browserLocale),
    )?.code as CurrencyCode;

    const newCurrency = storedCurrency || detectedCurrency || 'USD';

    setCurrencyCode(newCurrency);
  }, []);

  // Actualiza el conversion rate cada 60 secs
  useEffect(() => {
    if (!currencyCode) return;
    updateConversionRate();
    const priceInterval = setInterval(updateConversionRate, UPDATE_RATE * 1000);
    return () => clearInterval(priceInterval);
  }, [currencyCode]);

  const value = useMemo(
    () => ({ currency, conversionRate, updateConversionRate, setCurrency, formatFiat }),
    [currency, conversionRate],
  );

  return (
    <FiatConversionContext.Provider value={value}>{props.children}</FiatConversionContext.Provider>
  );
}
