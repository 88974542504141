import dynamic from 'next/dynamic';

const TermsModal = dynamic(() => import('./TermsModal/TermsModal'))
const BidHistoryModalN = dynamic(() => import('./BidHistoryModal/BidHistoryModal'))
const SettingsModal = dynamic(() => import('./SettingsModal/SettingsModal'))
const CollectorsListModalN = dynamic(() => import('./CollectorsListModal/CollectorsListModal'))
const AuthorizeSaleModal = dynamic(() => import('./AuthorizeSaleModal/AuthorizeSaleModal'))
const DirectSaleModal = dynamic(() => import('./DirectSaleModal/DirectSaleModal'))
const SendNFTModal = dynamic(() => import('./SendNFTModal/SendNFTModal'))
const StartDirectSaleProcessModal = dynamic(() => import('./StartDirectSaleProcessModal/StartDirectSaleProcessModal'))
const RewardsModal = dynamic(() => import('./RewardsModal/RewardsModal'))

export {
  TermsModal,
  BidHistoryModalN,
  SettingsModal,
  CollectorsListModalN,
  DirectSaleModal,
  SendNFTModal,
  AuthorizeSaleModal,
  StartDirectSaleProcessModal,
  RewardsModal,
};
