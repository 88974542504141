import styled, { css } from 'styled-components';
import Layout from '~/components/Layout/Layout';
import { useLang } from '~/store/languageContext/hooks';
import commonStyles from '~/styles/common';
import Button from '~/components/_ui-kit/Button';
import Link from 'next/link';

export interface ErrorViewProps {
  type?: string;
}

const ErrorView = ({ type }: ErrorViewProps) => {
  const { t } = useLang();
  const ErrorTypes = {
    notfound: {
      code: '404',
      title: t('ErrorPage:notfound_title'),
      subtitle: t('ErrorPage:notfound_desc'),
    },
    uncaught: {
      code: '500',
      title: t('ErrorPage:uncaught_title'),
      subtitle: t('ErrorPage:uncaught_desc'),
    },
    maintenance: {
      code: 'OOPS',
      title: t('ErrorPage:maintenance_title'),
      subtitle: t('ErrorPage:maintenance_desc'),
    },
  };

  const error = ErrorTypes[type || 'uncaught'];

  return (
    <Layout>
      <Root>
        <Code>{error.code}</Code>
        <Title>{error.title}</Title>
        <Subtitle>{error.subtitle}</Subtitle>
        {error.code === '404' && (
          <GoBackButton size="l">
            <Link href="/">{t('ErrorPage:back_to_home')}</Link>
          </GoBackButton>
        )}
      </Root>
    </Layout>
  );
};

export default ErrorView;

/** Styled components */

const Root = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.textColor};
    margin-top: 25%;
    max-width: 1312px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    ${theme.device.tabletS} {
      align-items: center;
      margin: 0 auto;
      margin-top: 15%;
      text-align: center;
    }
  `}
`;

const Code = styled.div`
  ${({ theme }) => css`
    ${commonStyles.textGradient}
    ${commonStyles.textGradientColor}
    font-weight: 900;
    font-size: 100px;
    line-height: 100px;
    margin-bottom: 24px;
  `}
`;

const Title = styled.div`
  ${({ theme }) => css`
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    ${theme.device.tabletS} {
      font-size: 32px;
      line-height: 39px;
    }
  `}
`;

const Subtitle = styled.div`
  ${({ theme }) => css`
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    margin-top: 16px;
  `}
`;

const GoBackButton = styled(Button)`
  margin-top: 24px;
`;
