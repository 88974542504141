import { useMedia } from 'react-use';
import theme from '~/styles/theme';

type Devices = keyof typeof theme.device;

export default function useMediaDevice(device: Devices) {
  const breakpoint = theme.device[device]?.replace('@media ', '');
  const matches = useMedia(breakpoint);
  return matches;
}
