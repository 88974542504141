import { ReactNode, useEffect, useMemo, useState } from 'react';
import MitoService from '../services/mito';
import { Cms } from '../services/mito/interfaces';
import { CmsContext, CmsContextValue } from './context';

export function CmsProvider(props: { children: ReactNode }) {
  const [cms, setCms] = useState<CmsContextValue['cms']>(null);

  const fetchCms = async () => {
    try {
      const { data } = await MitoService.getCms();
      const newCms = JSON.parse(data.cms) as Cms;
      setCms(newCms);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    fetchCms();
  }, []);

  const value = useMemo(() => ({ cms, fetchCms }), [cms]);
  return <CmsContext.Provider value={value}>{props.children}</CmsContext.Provider>;
}
