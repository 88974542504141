import { getSuggestedGas } from '~/ether-utils';

export interface GetBidDataPayload {
  firstPriceContract: any;
  lotID: string;
  address: string;
}

export interface ClaimPayload {
  firstPriceContract: any;
  bidId: string; // was BigNumber
  address: string;
}

export enum AuctionTypes {
  firstPrice = '0x1',
  timedEdition = '0x2',
  timedEditionBuyLimit = '0x3',
  firstPriceV2 = '0x4',
  timedEditionV2 = '0x5',
  timedEditionBuyLimitV2 = '0x6',
  directSaleV2 = '0x7',
  timedEditionMoonPay = '0x8',
}

export enum SaleType {
  AUCTION = '0',
  DIRECT_SALE = '1',
}

export enum AuctionTypesString {
  firstPrice = 'firstPrice',
  timedEdition = 'timedEdition',
  timedEditionBuyLimit = 'timedEditionBuyLimit',
}

export interface GetMinBid {
  firstPriceContract: any;
}

export const getMinBidAmount = async (payload: GetMinBid) => {
  const { firstPriceContract } = payload;
  return firstPriceContract.bidIncrementFactor();
};

export const getLotHighestBid = async (payload: GetBidDataPayload) => {
  const { firstPriceContract, lotID, address } = payload;
  return firstPriceContract.getLotHighestBid(lotID);
};

export const getExistingBidID = async (payload: GetBidDataPayload) => {
  const { firstPriceContract, lotID, address } = payload;
  return firstPriceContract.getExistingBidID(address, lotID);
};

export const claim = async (payload: ClaimPayload, provider: any) => {
  const { firstPriceContract, bidId, address } = payload;
  const gasPrice = await getSuggestedGas(provider);
  return firstPriceContract
    .claim(bidId, {
      from: address,
      // gasPrice,
    })
    .then((tx: any) => tx.wait())
    .catch((err: any) => err);
};
