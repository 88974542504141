import { useEffect, memo, useContext } from 'react';
import classes from './Overlay.module.scss';
import ClickAwayListener from 'react-click-away-listener';
import { ThemeContext } from '~/store/themeContext/themeContext';

export interface Props {
  handleClickAway?: any;
  children: React.ReactElement<any>;
  className?: string;
}

function Overlay({ children, handleClickAway = () => {}, className = '' }: Props) {
  const { setStaticBody } = useContext(ThemeContext);

  useEffect(() => {
    setStaticBody(true);
    return () => setStaticBody(false);
  }, []);

  const click = (event: any) => {
    event.stopPropagation();
    handleClickAway();
  };

  return (
    <div className={`${classes.Overlay} ${className}`}>
      <ClickAwayListener onClickAway={click}>
        {children}
      </ClickAwayListener>
    </div>
  );
}

export default memo(Overlay);
