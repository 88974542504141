import axios from 'axios';
import { MOONPAY_API_KEY } from '~/constants';
import { getUrl } from '~/utils';

export const api = axios.create({
  baseURL: 'https://api.moonpay.com/',
});

type MoonpayCurrency = 'matic_polygon';

const baseParams = {
  apiKey: MOONPAY_API_KEY,
};

export interface GetConversionRate {
  path: { currency: MoonpayCurrency };
  res: { EUR?: number; USD?: number; [key: string]: number };
}
function getConversionRate(path: GetConversionRate['path']) {
  const url = getUrl('/v3/currencies/:currency/ask_price', path);
  return api.get<GetConversionRate['res']>(url, { params: baseParams });
}

export default {
  getConversionRate,
};
