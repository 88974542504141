import { Web3Provider } from '@ethersproject/providers';
import { BigNumber, ethers, Contract } from 'ethers';

import {
  DEBUG_KEY,
  FALLBACK_GAS,
  SUGGESTED_GAS_PERCENT,
} from './constants';
import * as Sentry from '@sentry/react';

export const getBigInt = (value: any): string => {
  try {
    return BigInt(value).toString();
  } catch (err) {
    return value;
  }
};

export const BigNumberEthers = BigNumber;
export const ethersEthers = ethers;
export const ContractEthers = Contract;

export const getPrice = (wei: string | BigNumber | BigInt): number | null => {
  try {
    const value = getBigInt(wei);
    const priceBigInt = typeof value === 'string' ? BigNumber.from(value) : value;
    const priceDecimal = ethers.utils.formatUnits(priceBigInt);
    const price = Number(priceDecimal);
    return price;
  } catch (err) {
    return null;
  }
};

export const localePrice = (
  price: string | number,
  decimals: number,
  minDecilmals?: number,
  locale?: string,
): string => {
  if (!price) return '0';
  return Number(price).toLocaleString(locale || 'es-ES', {
    maximumFractionDigits: typeof decimals === 'number' ? decimals : 2,
    minimumFractionDigits: typeof minDecilmals === 'number' ? minDecilmals : 2,
  });
};

export const formatPrice = (
  wei: string | BigNumber,
  decimals: number,
  minDecilmals?: number,
  locale?: string,
): string => {
  const price = getPrice(wei);
  const priceFormatted = localePrice(price?.toString(), decimals, minDecilmals, locale);
  return priceFormatted;
};

export const toBigNumber = (price: string): BigNumber => {
  return BigNumber.from(price);
};

export const formatEther = (wei: ethers.BigNumberish): number => {
  return wei ? Number(ethers.utils.formatEther(wei)) : 0;
};


export const logClient = (...args) => {
  if (typeof localStorage === 'undefined') return;
  if (localStorage.getItem(DEBUG_KEY)) {
    return console.info(...args);
  }
};

/** Devuelve el Gas Price sugerido aplicando un porcentaje de incremento sobre el de por defecto */
export const getSuggestedGas = async (provider?: any) => {
  if (!provider) return null;
  try {
    const web3 = new Web3Provider(provider);
    const defaultGas = await web3.getGasPrice();
    const additionalGas = defaultGas.div(100 / SUGGESTED_GAS_PERCENT);
    const suggestedGas = defaultGas.add(additionalGas);
    logClient({ defaultGas, suggestedGas });
    return suggestedGas;
  } catch (err) {
    console.error('El gas price no se ha podido obtener', err);
    Sentry.captureException(err);
    return BigNumber.from(FALLBACK_GAS);
  }
};
