import React, { useState } from 'react';
import Button from '~/components/_ui-kit/Button';
import Input from '~/components/_ui-kit/Input';
import { useLang } from '~/store/languageContext/hooks';
import classes from './NewsletterForm.module.scss';

const NewsletterForm = () => {
  const { t } = useLang();
  const [email, setEmail] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [successMsg, setSuccessMsg] = useState(false);
  const postUrl = 'https://assets.mailerlite.com/jsonp/186471/forms/79010614434858445/subscribe';

  async function handleSubmit(e) {
    e.preventDefault();

    if (
      !email
        .toLowerCase()
        .trim()
        .match(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/)
    ) {
      setErrorMsg(t('Footer:news:error:notEmail'));
      return;
    }

    setIsEmailValid(true);

    const form = e.target;
    const action = form.getAttribute('action');
    const method = form.getAttribute('method');
    const data = new FormData(form);

    const response = await fetch(action, {
      method: method,
      body: data || email,
    });

    if (response.ok) {
      setSuccessMsg(true);
    } else {
      setErrorMsg(t('Footer:news:error:network'));
    }
  }

  return (
    <>
      {!isEmailValid ? (
        <form onSubmit={handleSubmit} action={postUrl} data-code="" method="post" target="_blank">
          <h5>{t('Footer:news:title')}</h5>
          <p>{t('Footer:news:desc')}</p>
          <div>
            <Input
              fullWidth
              size="full"
              inputType="text"
              errorMessage={errorMsg}
              sendValue={setEmail}
              placeholder={t('Footer:news:input')}
              value={email}
              name="fields[email]"
            />
            <Button
              className={classes.button}
              variant="secondary"
              size="m"
              style={{ marginTop: `${errorMsg ? '8px' : 'unset'}` }}
              disabled={email === null}
            >
              {t('Footer:news:subscribe')}
            </Button>
          </div>
        </form>
      ) : (
        <div className={classes.successMsg}>
          <p>{t('Footer:news:subscribe:success')}</p>
          <a href="https://discord.com/invite/5AfYBHseej" target="_blank">
            <Button variant="secondary" size="m">
              {t('Footer:news:subscribe:discord')}
            </Button>
          </a>
        </div>
      )}
    </>
  );
};

export default NewsletterForm;
