export const baseUrl = process.env.NEXT_PUBLIC_BASE_HOST;
export const shareSafeBaseUrl = process.env.NEXT_PUBLIC_BASE_HOST;
export const graphEndpoint = process.env.NEXT_PUBLIC_GRAPH_HOST;
export const venusEndpoint = process.env.NEXT_PUBLIC_BACK_HOST;
export const DEBUG_KEY = 'debug';
export const IPFS_NODE = 'https://mito.infura-ipfs.io';
export const STATIC_HOST = process.env.NEXT_PUBLIC_STATIC_HOST;
export const SUGGESTED_GAS_PERCENT = 50;
export const FALLBACK_GAS = 10;
export const MOONPAY_API_KEY = 'pk_test_17sQsplQAOWh6lcHp8DyORhHwFLicVv';
export const ALLOWED_OWNER_COPIES = 2;
export const SESSION_TOKEN = 'sessionToken';
export const CURRENT_ACCOUNT = 'currentAccount';
export const CURRENCIES = {
  EUR: {
    code: 'EUR',
    symbol: '€',
    template: '{price} {symbol}',
    locales: ['ES', 'FR', 'DE'],
  },
  USD: {
    code: 'USD',
    symbol: '$',
    template: '{symbol}{price}',
    locales: ['US'],
  },
};
export const MAX_AGE_CACHE = 43200;

export const REWARDS_TYPE = {
  LINK: 'link',
  DOWNLOAD: 'download',
  CODE: 'code',
};

export const REWARDS_TYPE_TBD = {
  TOKEN: 'token',
};

export const REWARD_SELECT_TYPE = {
  UNLIMITED: 'unlimited', // max_claims -> 0
  UNIQUE: 'unique', // max_claims -> 1
};
