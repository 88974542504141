import { getAllLots } from '~/store/services/graph/graph';

const NotificationsQueries = {
  async lots() {
    const res = await getAllLots();
    return res.data.data.lots;
  },
};

export default NotificationsQueries;
