import { ethersEthers } from '~/ether-utils';

export interface RoyaltiesPayload {
  withdrawAmount: number;
  userAddress: string;
  royaltiesContract: any;
}

export const formatEther = (wei): number => {
  return Number(ethersEthers.utils.formatEther(wei));
};

export const getUserRoyalties = async (payload: RoyaltiesPayload) => {
  const { userAddress, royaltiesContract } = payload;

  return royaltiesContract.getBalance(userAddress);
};

export const claimUserRoyalties = async (payload: RoyaltiesPayload) => {
  const { userAddress, withdrawAmount, royaltiesContract } = payload;
  const amount = ethersEthers.utils.parseEther(withdrawAmount.toString());
  //? Follow below pattern for Ethers Write calls
  return royaltiesContract
    .withdraw(amount)
    .then((tx: any) => tx.wait())
    .catch((err: any) => err);
};
