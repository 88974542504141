import { useEffect } from 'react';
import { DEBUG_KEY } from '~/constants';
import MitoService from '~/store/services/mito';

export default function useHealthCheck() {
  const getEnvConfig = () => {
    const env = {
      NEXT_PUBLIC_ENVIRONMENT: process.env.NEXT_PUBLIC_ENVIRONMENT,
      NEXT_PUBLIC_BASE_HOST: process.env.NEXT_PUBLIC_BASE_HOST,
      NEXT_PUBLIC_BACK_HOST: process.env.NEXT_PUBLIC_BACK_HOST,
      NEXT_PUBLIC_IPFS_HOST: process.env.NEXT_PUBLIC_IPFS_HOST,
      NEXT_PUBLIC_GRAPH_HOST: process.env.NEXT_PUBLIC_GRAPH_HOST,
      NEXT_PUBLIC_CHAIN_ID: process.env.NEXT_PUBLIC_CHAIN_ID,
      NEXT_PUBLIC_NFT: process.env.NEXT_PUBLIC_NFT,
      NEXT_PUBLIC_ROYALTY: process.env.NEXT_PUBLIC_ROYALTY,
      NEXT_PUBLIC_AUCTION_HUB: process.env.NEXT_PUBLIC_AUCTION_HUB,
      NEXT_PUBLIC_FIRST_PRICE: process.env.NEXT_PUBLIC_FIRST_PRICE,
      NEXT_PUBLIC_LIMITED_EDITION: process.env.NEXT_PUBLIC_LIMITED_EDITION,
      NEXT_PUBLIC_INFURA_PROJECT_ID: process.env.NEXT_PUBLIC_INFURA_PROJECT_ID,
      NEXT_PUBLIC_MOONPAY_KEY: process.env.NEXT_PUBLIC_MOONPAY_KEY,
      NEXT_PUBLIC_IMAGE_SOURCE_REVISION: process.env.NEXT_PUBLIC_IMAGE_SOURCE_REVISION,
      NEXT_PUBLIC_IMAGE_SOURCE_BRANCH: process.env.NEXT_PUBLIC_IMAGE_SOURCE_BRANCH,
      NEXT_PUBLIC_IMAGE_VERSION: process.env.NEXT_PUBLIC_IMAGE_VERSION,
    };
    console.log({ env });
    return env;
  };

  const apiHealthCheck = async () => {
    try {
      const { data } = await MitoService.getHealthCheck();
      // console.log(process.env.NEXT_PUBLIC_BACK_HOST, data);
    } catch (err) {
      console.error('NO HAY CONEXIÓN CON LA API DE MITO');
      console.error(err);
    }
  };

  useEffect(() => {
    if (typeof localStorage === undefined) return;
    const isDebug = localStorage.getItem(DEBUG_KEY);
    if (isDebug) {
      getEnvConfig();
      apiHealthCheck();
    }
  }, []);
}
