import { useWeb3React } from '@web3-react/core';
import { ReactNode, useEffect, useMemo, useState } from 'react';
import ErrorView from '~/pages/_error';
import { logClient } from '~/utils';
import MitoService from '../services/mito';
import { AppContext, AppContextValue } from './context';

export function AppProvider(props: { children: ReactNode }) {
  const { account } = useWeb3React();
  const [app, setApp] = useState<AppContextValue['app']>(null);
  const status = app?.status;

  const fetchApp = async () => {
    try {
      const { data } = await MitoService.getAppStatus();
      setApp(data);
      logClient(`%c ** App status: ${data.status}`, 'background: #eee; color: #555');
    } catch (err) {
      console.error(err);
    }
  };

  // Inicializa con la primera llamada y realiza un check cada 60s
  useEffect(() => {
    fetchApp();
    const instance = setInterval(fetchApp, 60 * 1000);
    return () => clearInterval(instance);
  }, []);

  // Recarga la página para limpiar el estado si el usuario tiene un wallet connectado
  useEffect(() => {
    if (account) {
      if (status === 'WARN') window.location.reload();
      if (status === 'KO') window.location.href = window.location.host;
    }
  }, [account, status]);

  const value = useMemo(() => ({ app, fetchApp }), [app]);
  return (
    <AppContext.Provider value={value}>
      {app?.status === 'KO' ? <ErrorView type="maintenance" /> : props.children}
    </AppContext.Provider>
  );
}
