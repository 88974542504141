import { RiEarthLine, RiPriceTag3Line } from 'react-icons/ri';
import styled, { css } from 'styled-components';
import { CURRENCIES } from '~/constants';
import { useFiatConversion } from '~/store/fiatConversionContext/hooks';
import { CurrencyCode } from '~/store/fiatConversionContext/provider';
import { Languages } from '~/store/languageContext/context';
import { useLang } from '~/store/languageContext/hooks';

export interface LocaleBarProps {}

const buttons = [
  {
    code: Languages.es,
    label: 'Español',
  },
  {
    code: Languages.en,
    label: 'English',
  },
];

const currencies = Object.keys(CURRENCIES) as CurrencyCode[];

const LocaleBar = ({}: LocaleBarProps) => {
  const { lang, setLang } = useLang();
  const { currency, setCurrency } = useFiatConversion();
  return (
    <Root>
      <Switches>
        <Switch>
          <RiEarthLine size="20px" />
          {buttons.map(({ code, label }) => (
            <Option key={code} active={lang === code} onClick={() => setLang(code)}>
              {label}
            </Option>
          ))}
        </Switch>
        <Switch>
          <RiPriceTag3Line size="20px" />
          {currencies.map((code) => (
            <Option key={code} active={currency?.code === code} onClick={() => setCurrency(code)}>
              {code}
            </Option>
          ))}
        </Switch>
      </Switches>
    </Root>
  );
};

export default LocaleBar;

/** Styled components */

const Root = styled.div`
  ${({ theme }) => css`
    margin-top: 48px;
    width: 100%;
    font-size: 12px;
    line-height: 120%;
    min-height: 24px;
    font-weight: 500;
    z-index: 1;

    & > * {
      margin-bottom: 24px;
    }

    a,
    button {
      :hover {
        color: ${theme.colors.textColor};
      }
    }

    a {
      font-weight: bold;
    }

    ${theme.device.tabletS} {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0;
      & > * {
        margin-bottom: 0;
      }
    }
  `}
`;

const Switches = styled.div`
  ${({ theme }) => css`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    ${theme.device.tabletS} {
      justify-content: flex-end;
      gap: 32px;
      margin: 0;
    }
  `}
`;

const Switch = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.textColorSecondary};
    display: flex;
    width: auto;
    align-items: center;
    & > *:not(:last-child) {
      margin-right: 8px;
    }
  `}
`;

interface LanguageProps {
  active?: boolean;
}

const Option = styled.button<LanguageProps>`
  ${({ active }) => css`
    font-weight: ${active && 'bold'};
  `}
`;
