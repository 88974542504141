import { ReactNode, useEffect, useState } from 'react';
import { UsersContext, UsersContextValue } from './context';
import { getMinters } from '~/store/services/graph/graph';

export function UsersProvider(props: { children: ReactNode }) {
  const [users, setUsers] = useState<UsersContextValue['users']>({});

  const fetchUsers = async () => {
    try {
      const res = await getMinters();
      const { minters } = res.data.data;
      const allUsers = [...minters];

      const usersTable = allUsers.reduce<UsersContextValue['users']>((table, user) => {
        return {
          ...table,
          [user.address]: {
            address: user.address,
            username: user.userIdentifier,
            name: user.userIdentifier,
            isMinter: user.isMinter,
            isArtist: user.isActiveMinter === false,
          },
        };
      }, {});

      setUsers(usersTable);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  return <UsersContext.Provider value={{ users }}>{props.children}</UsersContext.Provider>;
}
