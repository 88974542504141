import { useContext } from 'react';
import { User } from '~/store/services/graph/types';
import { shortenAddress } from '~/utils';
import { useCms } from '../cmsContext/hooks';
import { UsersContext } from './context';

/** Provee los usuarios de la plataforma */
export function useUsers() {
  const { users } = useContext(UsersContext);
  const { cms } = useCms();
  const profiles = cms?.profiles;

  const getUser = (address?: string): User => {

    const shortAddress = address ? shortenAddress(address) : '';
    const user = (users && address && users[address?.toLowerCase()]) || {};
    const cmsProfile = (profiles && address && profiles[address?.toLowerCase()]) || {};

    const result = {
      name: shortAddress,
      username: shortAddress,
      verified: !!user?.isMinter || !!cmsProfile.name,
      ...user,
      ...cmsProfile,
    };

    return result;
  };

  return {
    users,
    getUser,
  };
}
