import { useContext } from 'react';
import { AppContext } from './context';

/** Provee el estado actual de la plataforma */
export function useAppStatus() {
  const { app } = useContext(AppContext);
  const status = app?.status;
  const message = app?.message;

  const warningMode = status === 'WARN';
  const maintenanceMode = status === 'KO';
  const customMode = status === 'CUSTOM';
  
  return { status, message, warningMode, maintenanceMode, customMode };
}
