import React, { forwardRef, HTMLAttributes } from 'react';
import { FiX } from 'react-icons/fi';
import styled, { css } from 'styled-components';

export interface CloseButtonProps extends HTMLAttributes<HTMLDivElement> {
  size?: 'large' | 'small';
}

const CloseButton = forwardRef<HTMLDivElement, CloseButtonProps>((props, ref) => {
  return (
    <Root ref={ref} {...props}>
      <FiX size="18" />
    </Root>
  );
});

export default CloseButton;

/** Styled components **/

interface RootProps {
  size?: CloseButtonProps['size'];
}
const Root = styled.div<RootProps>`
  ${({ theme, size }) => css`
    color: ${theme.colors.textColor};
    position: relative;
    cursor: pointer;
    z-index: 99999;
    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    :before {
      content: '';
      display: block;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      background: rgba(128, 128, 128);
      opacity: 0.15;
    }
    :hover {
      :before {
        opacity: 0.2;
      }
    }
    :active {
      :before {
        opacity: 0.3;
      }
    }
    ${size === 'large' &&
    css`
      svg {
        transform: translate(-50%, -50%) scale(1.35);
      }
      :before {
        width: 32px;
        height: 32px;
      }
    `}
  `}
`;
