// React and next
import Link from 'next/link';
import { useEffect, memo, useContext } from 'react';
// Components
import Logo from '~/components/Logo';
import { CloseGradient, IconRewardsGift } from '~/components/Icons';
// Hooks
import { useWallets } from '~/store/walletContext/WalletContext';
import { ThemeContext } from '~/store/themeContext/themeContext';
import { useAppStatus } from '~/store/appContext/hooks';
import { useLang } from '~/store/languageContext/hooks';
import { useStore } from '~/store/store';
// Styles
import classes from './MobileMenu.module.scss';
import Button from '~/components/_ui-kit/Button';
import { useRouter } from 'next/router';

interface Props {
  setShowMenu: React.Dispatch<React.SetStateAction<boolean>>;
  isVisible: boolean;
}

function MobileMenu({ setShowMenu, isVisible }: Props) {
  const { setShowWalletModal, showWalletModal, disconnectWallet } = useWallets();
  const { setStaticBody } = useContext(ThemeContext);
  const { t } = useLang();
  const { state } = useStore();
  const { warningMode, maintenanceMode } = useAppStatus();

  const closeMenu = () => setShowMenu(false);

  useEffect(() => {
    if (isVisible) setStaticBody(true);
    return () => setStaticBody(false);
  }, [isVisible]);

  const router = useRouter();

  const Login = () => {
    if (warningMode || maintenanceMode) return null;

    return (
      <>
        <li>
          <span className={classes.divider}></span>
        </li>
        {state.isWalletConnected && (
          <>
            <li>
              <Link href={`/rewards/address/${state.userAddress}`}>
                <a onClick={closeMenu}>
                  <IconRewardsGift className={classes.iconReward} />
                  {t('Header:rewards')}
                </a>
              </Link>
            </li>
            <li>
              <Button
                variant="secondary"
                size="l"
                className={classes.button}
                onClick={disconnectWallet}
              >
                <p>{t('Menu:logout')}</p>
              </Button>
            </li>
          </>
        )}
        {!state.isWalletConnected && (
          <li>
            <Button
              size="l"
              className={classes.button}
              onClick={() => {
                closeMenu();
                setShowWalletModal(!showWalletModal);
              }}
            >
              {t('Header:sign_in')}
            </Button>
          </li>
        )}
      </>
    );
  };

  return (
    <menu className={`${classes.MobileMenu} ${isVisible ? classes.isVisible : classes.isHidden}`}>
      <div className={classes.topContainer}>
        <Logo tagBackground="black" />
        <button className={classes.closeButton} onClick={closeMenu}>
          <CloseGradient className={classes.icon} />
        </button>
      </div>
      <nav className={classes.navMenu}>
        <ul className={classes.navLinks}>
          {navItems.map((link) => (
            <li key={`link-${link.label}`}>
              <Link href={link.href}>
                <a
                  target={link.blank ? '_blank' : ''}
                  className={`${classes.label} ${
                    router.pathname === link.href ? classes.selected : ''
                  }`}
                >
                  {t(link.translation)}
                </a>
              </Link>
            </li>
          ))}
          <Login />
        </ul>
      </nav>
    </menu>
  );
}

export default memo(MobileMenu);

const navItems = [
  {
    label: 'Home',
    href: '/',
    translation: 'Header:home',
    blank: false,
  },
  {
    label: 'Marketplace',
    href: '/marketplace',
    translation: 'Header:marketplace',
    blank: false,
  },
  {
    label: 'Collections',
    href: '/collections',
    translation: 'Header:collections',
    blank: false,
  },
  // {
  //   label: 'Curators',
  //   href: '/curators',
  //   translation: 'Header:curators',
  //   blank: false,
  // },
  // {
  //   label: 'Editorial',
  //   href: '/editorial',
  //   translation: 'Header:editorial',
  //   blank: false,
  // },
  {
    label: 'About Mito',
    href: '/about',
    translation: 'Header:about_mito',
    blank: true,
  },
];
