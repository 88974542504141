import { createContext, useContext } from 'react';
import { NotificationsTable } from './types';

export type NotificationsContextValue = {
  state: {
    data: NotificationsTable;
    loading: boolean;
    updating: boolean;
    error: any;
  };
  actions: {};
};

export const notificationsInitialState: NotificationsContextValue['state'] = {
  data: {},
  error: null,
  loading: null,
  updating: null,
};

export const NotificationsContext = createContext<NotificationsContextValue>({
  state: notificationsInitialState,
  actions: {},
});

export function useNotifications() {
  const notifications = useContext(NotificationsContext);
  return notifications;
}
