import Link from 'next/link';
import { MitoLogoFooter } from '~/components/Icons';
import { useLang } from '~/store/languageContext/hooks';
import { FooterLink } from './FooterLink/FooterLink';
import { externalLinks, faqsAbout, faqsMarket, internalLinks } from './linkItems';
import {
  IconInstagram,
  IconTikTok,
  IconDiscord,
  IconTwitter,
  IconMedium,
} from '~/components/Icons';
import classes from './Footer.module.scss';
import LocaleBar from '../_cdk/LocaleBar';
import useMediaDevice from '~/hooks/useMediaDevice';
import NewsletterForm from '../_cdk/NewsletterForm/NewsletterForm';

const retrieveFooterIcon = (icon: string) => {
  switch (icon) {
    case 'instagram':
      return <IconInstagram />;
    case 'tiktok':
      return <IconTikTok />;
    case 'discord':
      return <IconDiscord />;
    case 'twitter':
      return <IconTwitter />;
    case 'medium':
      return <IconMedium />;
    default:
      return <IconInstagram />;
  }
};

export default function Footer() {
  const { t, lang } = useLang();
  const isTabletS = useMediaDevice('tabletS');

  return (
    <footer className={classes.footer}>
      <nav className={`${classes.footerColumn} ${classes.socials}`}>
        <div>
          <Link href="/">
            <a>
              <MitoLogoFooter />
            </a>
          </Link>

          <ul>
            {externalLinks.map((link) => (
              <li key={`link-${link.label}`}>
                <a href={link.path} target="_blank">
                  {retrieveFooterIcon(link.label)}
                </a>
              </li>
            ))}
          </ul>
        </div>
        {isTabletS ? <LocaleBar /> : null}
      </nav>

      <main>
        <div className={`${classes.footerColumn} ${classes.newsletter}`}>
          <NewsletterForm />
        </div>

        <nav className={`${classes.footerColumn} ${classes.faqs}`}>
          <div>
            <div>
              <h5>{t('Footer:faqs:title:about')}</h5>
              <ul>
                {faqsAbout.map((link) => (
                  <FooterLink
                    key={`item-${link.label}`}
                    path={link.redirects[lang]}
                    label={t(link.label)}
                    external
                  />
                ))}
              </ul>
            </div>
            <div>
              <h5>{t('Footer:faqs:title:market')}</h5>
              <ul>
                {faqsMarket.map((link) => (
                  <FooterLink
                    key={`item-${link.label}`}
                    path={link.redirects[lang]}
                    label={t(link.label)}
                    external
                  />
                ))}
              </ul>
            </div>
          </div>
        </nav>
      </main>

      {!isTabletS ? <LocaleBar /> : null}

      <nav className={classes.listContainer}>
        <ul>
          {internalLinks.map((link) => (
            <FooterLink
              key={`item-${link.label}`}
              path={t(link.path)}
              label={t(link.label)}
              external={link.external}
            />
          ))}
        </ul>
        <span className={classes.projectBy}>
          {t('Footer:a_project_by')}
          <a href={t('link:copyright')} target="_blank">
            &nbsp; Rungie
          </a>
        </span>
      </nav>
    </footer>
  );
}
