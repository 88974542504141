import { useContext } from 'react';
import { LanguageContext } from './context';
import { DEFAULT_LANG } from './provider';

const splitChar = {
  locale: '$$$',
};

/** Provee el estado actual de la plataforma */
export function useLang() {
  const { lang, literals, setLang } = useContext(LanguageContext);

  const t = (key?: string) => {
    const textsByLang = literals ? literals[key] : null;
    const localedText = textsByLang && textsByLang[lang || DEFAULT_LANG];
    return localedText || key;
  };

  const tBySplit = (text: string) => {
    const [original, enTxt] = text?.split(splitChar.locale) || [];
    const result = (lang === 'es' && original) || (lang === 'en' && enTxt) || original;
    return result;
  };

  return { lang, t, setLang, literals, tBySplit };
}
