import { useEffect, useState, ReactNode, useContext } from 'react';
import moment from 'moment';

import {
  CURRENCIES,
  DEBUG_KEY,
  FALLBACK_GAS,
  SESSION_TOKEN,
  CURRENT_ACCOUNT,
  STATIC_HOST,
  SUGGESTED_GAS_PERCENT,
} from './constants';
import { Bid, Lot, LotStatus, NFT, Collection } from './store/services/graph/types';
import _orderBy from 'lodash/orderBy';
import * as Sentry from '@sentry/react';
import { AuctionTypes } from '~/store/services/auctions';
import { result } from 'lodash';
import Cookies from 'js-cookie';
import useLotInfo from './hooks/useLotInfo.legacy';

import { time } from 'console';

export const getBigInt = (value: any): string => {
  try {
    return BigInt(value).toString();
  } catch (err) {
    return value;
  }
};

export const localePrice = (
  price: string | number,
  decimals: number,
  minDecilmals?: number,
  locale?: string,
): string => {
  if (!price) return '0';
  return Number(price).toLocaleString(locale || 'es-ES', {
    maximumFractionDigits: typeof decimals === 'number' ? decimals : 2,
    minimumFractionDigits: typeof minDecilmals === 'number' ? minDecilmals : 2,
  });
};

export const daysHoursMinutesString = (timestamp: number) => {

  var reminder = Math.ceil(timestamp - Date.now()) / 1000; // in seconds
  const years = Math.floor(reminder / 31536000);
  // reminder -= years * 31536000;

  const months = Math.floor(reminder / 2592000);
  // reminder -= months * 2592000;

  const days = Math.floor(reminder / 86400);
  reminder -= days * 86400

  const hours = Math.floor(reminder / 3600);
  reminder -= hours * 3600;

  const minutes = Math.floor(reminder / 60);
  reminder -= minutes * 60;

  if(years > 0) return null
  if(days > 0) return `${days}d ${hours}h`;
  if(hours > 0 && days < 1) return `${hours}h ${minutes}m`;
  return `${minutes}m`;

}


export function secondsSinceEpoch(d: number) {
  return Math.floor(d / 1000);
}

export function addMinutes(timeStamp: number, minutes: number): number {
  return timeStamp + minutes * 60000;
}

export function addHours(timeStamp: number, hours: number): number {
  return timeStamp + hours * 3600000;
}

export function addDays(timeStamp: number, days: number): number {
  return timeStamp + days * 86400000;
}

export const padMinuteWithZero = (minuteString: string) => {
  return minuteString.length === 1 ? `0${minuteString}` : minuteString;
};

export const hoursAndMinutesToMilliseconds = (timeString: string): number => {
  const hours = timeString.split(':')[0];
  const minutes = timeString.split(':')[1];
  return parseInt(hours) * 3600000 + parseInt(minutes) * 60000;
};

export const getTimeStamp = (hoursAndMinutes: string, date: Date): number => {
  return (date.getTime() + hoursAndMinutesToMilliseconds(hoursAndMinutes)) / 1000;
};

export const getTimeStringFromTimestamp = (timestamp: number): string => {
  return new Date(timestamp * 1000).toISOString().split('T')[1].slice(0, 5);
};

export const getDateStringFromTimestamp = (timestamp: number): string => {
  return new Date(timestamp * 1000).toISOString().split('T')[0];
};

export const getDayMontYearFromTimestamp = (timestamp: number): string => {
  const date = new Date(timestamp * 1000);
  const month = date.toLocaleString('default', { month: 'short' });
  return date.getDate() + ' ' + month + ' ' + date.getFullYear();
};

export const toMidnight = (date: Date): Date => {
  return new Date(date.setUTCHours(0, 0, 0, 0));
};

export const shortenAddress = (address: string, sliceBy?: [number, number]): string => {
  const starts = (sliceBy && sliceBy[0]) || 4;
  const ends = (sliceBy && sliceBy[0]) || 3;
  if (address) {
    return `${address?.toString().slice(0, starts)}...${address?.toString().slice(address.length - ends)}`;
  } else {
    return '????';
  }
};

export const formatTime = (seconds: number): string => {
  if (isNaN(seconds)) {
    return '00:00';
  }

  const date = new Date(seconds * 1000);
  const hh = date.getUTCHours();
  const mm = date.getUTCMinutes();
  const ss = date.getUTCSeconds().toString().padStart(2, '0');

  if (hh) {
    return `${hh}:${mm.toString().padStart(2, '0')}:${ss}`;
  }

  return `${mm}:${ss}`;
};

export const compareBids = (bidA, bidB) => {
  if (Number(bidA?.amount) < Number(bidB?.amount)) {
    return -1;
  }
  if (Number(bidA?.amount) > Number(bidB?.amount)) {
    return 1;
  }
  return 0;
};

export const timestampToTimeString = (timestamp: number): string => {
  const date = new Date(timestamp);

  return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
};

export const findLotsInCollection = (lots: Lot[], collectionName: string): Lot[] => {
  return getActiveLots(lots).filter((lot: Lot) => lot?.token?.identifier === collectionName);
};

export const nftsUniqByContentHash = (nfts: NFT[]) => {
  const orderHash = _orderBy(nfts, ['contentHash'], ['asc']);

  return orderHash.reduce((ac, cv) => {
    const hashes = ac.map((x) => x.contentHash);
    const hashFound = hashes?.includes(cv.contentHash);
    return hashFound ? ac : [...ac, cv];
  }, []);
};

/*
  Claim Status Utils

  These utils help store and retrieve claim state

  Claim state is stored in local storage as an array
  each item of the array represent a bid and claim on a lot
  [{
    lotId: '0x2d',
    bidId: '29',
    claimStatus: 'success'/'fail'
  }]
*/

interface ClaimStatus {
  bidId: string;
  claimStatus: string;
  lotId: string;
}

const CLAIM_STORAGE_KEY = 'CLAIM_STORAGE_KEY';

export const retrieveClaimStatus = (lotId: string) => {
  const claimStatusesAsString = localStorage.getItem(CLAIM_STORAGE_KEY);
  if (claimStatusesAsString) {
    const claimStatuses = JSON.parse(claimStatusesAsString) as ClaimStatus[];
    const match = claimStatuses.find((c) => c.lotId === lotId);
    return match;
  } else {
    return null;
  }
};

export const useClaimExist = (lotId: string) => {
  const [claimExist, setClaimExist] = useState(false);

  useEffect(() => {
    const match = retrieveClaimStatus(lotId);
    setClaimExist(!!match);
  }, [lotId]);

  return claimExist;
};

export const storeClaimStatus = (claimStatus: ClaimStatus) => {
  // console.log('About to store claim status', claimStatus);
  const claimStatusesAsString = localStorage.getItem(CLAIM_STORAGE_KEY);
  if (claimStatusesAsString) {
    const claimStatuses = JSON.parse(claimStatusesAsString) as ClaimStatus[];
    const match = claimStatuses.find((c) => c.lotId === claimStatus.lotId);
    if (match) {
      // console.log('matching claimstatus found, not overriding previous record');
    } else {
      const newClaimStatuses = [claimStatus, ...claimStatuses];
      const asString = JSON.stringify(newClaimStatuses);
      localStorage.setItem(CLAIM_STORAGE_KEY, asString);
    }
    return match;
  } else {
    const claimStatuses = [claimStatus];
    const asString = JSON.stringify(claimStatuses);
    localStorage.setItem(CLAIM_STORAGE_KEY, asString);
  }
};

export const getDropSrc = (IPFSLocation?: string | null) => {
  const srcs = IPFSLocation?.split('$') || [];

  return {
    previewSrc: srcs[0] || IPFSLocation,
    videoSrc: srcs[1] || IPFSLocation,
    videoHDSrc: srcs[2] || IPFSLocation,
  };
};

export const toHex = (number?: number): string | null => {
  return number?.toString(16) || null;
};

export const toDecimal = (hex?: number | string): number | null => {
  return hex ? Number(hex) : null;
};

export const logClient = (...args) => {
  if (typeof localStorage === 'undefined') return;
  if (localStorage.getItem(DEBUG_KEY)) {
    return console.info(...args);
  }
};

/** @param countdown UTC time, example: "06 September 2021 22:00 UTC" */
export const getTimestampFromUtc = (countdown: string) => {
  try {
    const timestamp = new Date(countdown)?.getTime();
    return timestamp / 1000;
  } catch {
    return null;
  }
};

/** Obtiene el nº de copia de un NFT */
export const getNFTEdition = (nft: NFT) => {
  if (!nft) return null;

  // Obtiene el nº de la copia a partir de los NFTs vendidos
  const editionsIds = nft?.batch?.nfts?.map((nft) => toDecimal(nft.id));
  const sortedEditionsIds = _orderBy(editionsIds);

  const number = editionsIds ? sortedEditionsIds.indexOf(Number(nft.id)) + 1 : 1;

  const maxSupply = nft?.batch?.maxSupply || 1;

  return { number, maxSupply };
};

/** Reemplaza una string por un nodo de React */
export const replaceTxT = (origin: string, [match, replace]: [string, ReactNode]): ReactNode => {
  const divider = '%%%';
  const withDividers = origin.replace(match, `${divider}${match}${divider}`);
  const splitted = withDividers.split(divider);
  const replaced = splitted.map((text, i) =>
    text === match ? <span key={i}>{replace}</span> : <span key={i}>{text}</span>,
  );
  return replaced;
};

/** Reemplaza varias string por nodos de React */
export const replaceMany = (
  origin: string,
  replacements: { [match: string]: ReactNode },
): ReactNode => {
  const divider = '%%%';
  let withDividers = origin;
  const replacementsList = Object.entries(replacements);
  replacementsList.forEach(([match, replace]) => {
    withDividers = withDividers.replace(match, `${divider}${match}${divider}`);
  });
  const splitted = withDividers.split(divider);
  const replaced = splitted.map((text, i) => <span key={i}>{replacements[text] || text}</span>);
  return replaced;
};

/** Obtiene la ruta en base a los parámetros pasados */
export const getUrl = (path: string, params?: { [key: string]: any }) => {
  const urlWithParams = Object.entries(params || []).reduce((url, [param, value]) => {
    return (url || path).replace(`:${param}`, value);
  }, '');
  return urlWithParams || path;
};

/** Obtiene los lots activos */
export function getActiveLots(lots: Lot[]): Lot[] {
  const activeLots = lots?.filter((lot) => lot?.lotStatus !== LotStatus.AUCTION_CANCELED);
  return activeLots;
}

/** Obtiene las collections activas */
export function getActiveCollections(collections: Collection[]): Collection[] {
  // TODO: Aplicar colecciones de forma dinamica
  let deletedCollections = ['The gaze', 'Niñas'];
  const activeCollections = collections?.filter(
    (collection) => !deletedCollections.includes(collection.id),
  );
  return activeCollections;
}

export function getActiveRewards(collections: Collection[]): Collection[] {
  // TODO
  const activeRewards = [];
  return activeRewards;
}

/** Obtiene las imágenes estáticas de un nft */
export function getNftStatics(nft?: NFT) {
  const [, , videoOriginal] = nft?.location?.split('$');
  const isVideo = !!videoOriginal;
  const batchId = nft?.batch?.originalTokenID?.id;
  const nftId = batchId || nft?.id;

  const correctExtension = isVideo ? 'mp4' : 'jpg';
  const staticUrl = (type) => `${STATIC_HOST}/${nftId}_${type}.${correctExtension}`;

  return {
    thumb: nftId ? staticUrl('thumb') : null,
    mid: nftId ? staticUrl('mid') : null,
    mini: nftId ? staticUrl('mini') : null,
  };
}

/** Obtiene las imágenes estáticas de un lot */
export function getLotStatics(lot?: Lot) {
  const [, , videoOriginal] = lot?.token?.location?.split('$');
  const isVideo = !!videoOriginal;

  const correctExtension = isVideo ? 'mp4' : 'jpg';
  const staticUrl = (type) => `${STATIC_HOST}/${lot.token.id}_${type}.${correctExtension}`;

  return {
    thumb: lot.id ? staticUrl('thumb') : null,
    mid: lot.id ? staticUrl('mid') : null,
    mini: lot.id ? staticUrl('mini') : null,
  };
}

// export function isVideoExtension(url: string) {
//   return url?.split('.').pop() === 'mp4';
// }

export function isVideoExtension(url: string) {
  return url?.includes('mp4');
}

export function getLastLot(lots) {
  const filteredLots = _orderBy(lots, (a) => Number(a.startTime), 'desc');
  return filteredLots[0];
}

/** Selecciona el auction type correcto */
export const getCorrectAuctionType = (id: string, type: string) => {
  switch (type) {
    case 'firstPrice':
      return AuctionTypes.firstPrice === id || AuctionTypes.firstPriceV2 === id;
    case 'timedEdition':
      return (
        AuctionTypes.timedEdition === id ||
        AuctionTypes.timedEditionV2 === id ||
        AuctionTypes.timedEditionMoonPay === id
      );
    case 'timedEdition':
      return (
        AuctionTypes.timedEdition === id ||
        AuctionTypes.timedEditionV2 === id ||
        AuctionTypes.directSaleV2
      );
    case 'timedEditionBuyLimit':
      return AuctionTypes.timedEditionBuyLimit === id || AuctionTypes.timedEditionBuyLimitV2 === id;
    default:
      return false;
  }
};

export const returnCorrectContract = (id, contracts) => {
  switch (id) {
    case AuctionTypes.firstPrice:
      return contracts.firstPriceContract;
    case AuctionTypes.firstPriceV2:
      return contracts.firstPriceContractV2;
    case AuctionTypes.timedEdition:
      return contracts.limitedTimedContract;
    case AuctionTypes.timedEditionV2:
      return contracts.limitedTimedContractV2;
    case AuctionTypes.timedEditionMoonPay:
      return contracts.limitedTimedContractV2;
    case AuctionTypes.timedEditionBuyLimitV2:
      return contracts.limitedTimedBuyLimitContractV2;
    case AuctionTypes.timedEditionBuyLimit:
      return contracts.limitedTimedBuyLimitContract;
    case AuctionTypes.directSaleV2:
      return contracts.directSaleContractV2;
    case AuctionTypes.timedEditionMoonPay:
      return contracts.timedEditionContractMoonPay;
    default:
      return contracts.firstPriceContract;
  }
};

export const truncate = (str: string, n: number, useWordBoundary = true): string => {
  const ellipsis = '...' // &hellip;
  if (!str) { return '' };
  if (str?.length <= n) { return str; };
  const subString = str.substr(0, n - 1);
  return (useWordBoundary ? subString.substr(0, subString.lastIndexOf(" ")) : subString) + ellipsis;
};

export function filterUniqueByProperty(collection: Array<any>, propName: string) {
  return collection ? collection.filter((v, i, a) => a.findIndex(v2 => [propName].every(k => v2[k] === v[k])) === i) : [];
}

export const checkLangObj = (textObject: any, lang: string): string => {
  // spanish by default (in case the creator didn't translate to english and the viewer is browsing with an english locale)
  let result = textObject.es ? textObject.es : null;

  if (lang === 'en') {
    if (textObject.en) {
      result = textObject.en;
    }
  } else if (lang === 'es') {
    if (textObject.es) {
      result = textObject.es;
    }
  }
  return result;
}

export const wordBreakOpportunityByChar = (text: string, breakChar: string) => {
  let parts = text.split(breakChar);
  const result = parts.join(breakChar + '\u200B');
  return result;
}

export const isValidURL = (url: string) => {
  try {
    return Boolean(new URL(url));
  }
  catch (e) {
    return false;
  }
}

export const bytesToSize = (bytes: number) => {
  let sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes == 0) return '0 Byte';
  let i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)).toFixed(2));
  return Math.round(bytes / Math.pow(1024, i)) + ' ' + sizes[i];
}

export const getCurrentToken = async (account) => {
  try {
    const currentAccount = await Cookies.get(CURRENT_ACCOUNT);

    if (currentAccount !== account) {
      await Cookies.remove(SESSION_TOKEN);
      await Cookies.set(CURRENT_ACCOUNT, account);
    }

    const hasToken = await Cookies.get(SESSION_TOKEN);
    return hasToken;
  } catch (error) {
    return undefined;
  }
};

export const replaceWithNode = (source: string, find: string, replace: ReactNode) => {
  const items = source.split(find);
  return items.length > 0 ? [items[0], replace, items[1]] : items;
}

export const addUrlFormat = (url = '') =>
  url.replace(/^(?:(.*:)?\/\/)?(.*)/i, (match, schemma, nonSchemmaUrl) =>
    schemma ? match : `http://${nonSchemmaUrl}`,
  );

export const generateRandom = (maxLimit = 100) => {
  let rand = Math.random() * maxLimit;
  return Math.floor(rand); // 99
}

export const getRandomBetween = (min = 0, max = 100) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

/**
 * @param lots
 * @returns lots that are currently on sale
 */
export const filterOnSale = (lots: Lot[]) => {
  let filteredLots = lots.filter(l => Object.values(LotStatus).includes(l.lotStatus as EXCLUDE_SALES))
  let onSale = []
  filteredLots.forEach(lot => {
    const currentLot = useLotInfo({ lot })
    if(currentLot.copies.remaining > 0) onSale.push(lot)
  })
  return onSale
}

// Type for a lot that is not currently for sale
type EXCLUDE_SALES = Exclude<LotStatus ,LotStatus.AUCTION_CANCELED | LotStatus.AUCTION_RESOLVED | LotStatus.AUCTION_RESOLVED_AND_CLAIMED>
