import { Web3Provider } from '@ethersproject/providers';
import * as Toastify from 'react-toastify';
import useToast from '~/hooks/useToast';
import { toHex } from '~/utils';
import {
  chainIDToBlockExplorer,
  chainIDToCurrency,
  chainIDToNetwork,
  chainIDToRCPToUrls,
} from '~/wallets/connectors';
import React from 'react';

export const requestNetworkChange = async (
  web3: Web3Provider,
  throwToast: ReturnType<typeof useToast>['throwToast'],
  dismissToast?: ReturnType<typeof useToast>['dismissToast'],
) => {
  const chainId = Number(process.env.NEXT_PUBLIC_CHAIN_ID);
  const shouldRequestChange = web3.network.chainId !== chainId;

  if (shouldRequestChange) {
    const infoToast = throwToast('requestChainIdChange');
    try {
      // Se acepta la red
      const requestSwitch = await requestSwitchNetwork(chainId, web3);
      dismissToast(infoToast);
      throwToast('chainIdAccepted');
      return requestSwitch;
    } catch (err) {
      if (err.code === 4902) {
        // Se acepta la red
        try {
          const requestAdd = await requestAddNetwork(chainId, web3);
          dismissToast(infoToast);
          throwToast('chainIdAccepted');
          return requestAdd;
        } catch (err) {
          // Se Rechaza la red
          if (err.code === 4001 || err.code === 4902) {
            dismissToast(infoToast);
            throwToast('chainIdRejected');
          }
          throw err;
        }
      }
      // Se rechaza la red
      if (err.code === 4001 || err.code === 4902) {
        dismissToast(infoToast);
        throwToast('chainIdRejected');
      }
      throw err;
    }
  }
};

interface SwitchEthereumChainParameter {
  chainId: string; // A 0x-prefixed hexadecimal string
}
const requestSwitchNetwork = async (chainIdHex: number, web3: Web3Provider) => {
  const params: SwitchEthereumChainParameter = {
    chainId: `0x${toHex(chainIdHex)}`,
  };
  return await web3.provider.request({
    method: 'wallet_switchEthereumChain',
    params: [params],
  });
};

interface AddEthereumChainParameter {
  chainId: string; // A 0x-prefixed hexadecimal string
  chainName: string;
  nativeCurrency: {
    name: string;
    symbol: string; // 2-6 characters long
    decimals: number;
  };
  rpcUrls: string[];
  blockExplorerUrls?: string[];
  iconUrls?: string[]; // Currently ignored.
}
const requestAddNetwork = async (chainId: number, web3: Web3Provider): Promise<string> => {
  const params: AddEthereumChainParameter = {
    chainId: `0x${toHex(chainId)}`,
    chainName: chainIDToNetwork[chainId],
    rpcUrls: [chainIDToRCPToUrls[chainId]],
    blockExplorerUrls: [chainIDToBlockExplorer[chainId]],
    nativeCurrency: chainIDToCurrency[chainId],
  };
  return await web3.provider.request({
    method: 'wallet_addEthereumChain',
    params: [params],
  });
};
