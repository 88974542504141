import React, { createContext, useContext, useEffect, useState } from 'react';
import { useDebounce } from '~/hooks';

interface Breakpoints {
  small: number;
  medium: number;
  tablet: number;
  smallDesktop: number,
  desktop: number;
}

interface ContextProps {
  width: number | undefined;
  height: number | undefined;
  breakpoints: Breakpoints;
}

const ViewportContext = createContext({} as ContextProps);

interface Props {
  children: React.ReactNode;
}

export function ViewportProvider(props: Props) {
  const [width, setWidth] = useState(undefined);
  const [height, setHeight] = useState(undefined);

  const breakpoints: Breakpoints = {
    small: 600,
    medium: 768,
    tablet: 1024,
    smallDesktop: 1280,
    desktop: 1440,
  };

  const handleWindowResize = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };

  const debounceHandleWindowResize = useDebounce(handleWindowResize, 10);

  // useEffect only runs on client
  useEffect(() => {
    // for first client render
    handleWindowResize();
    // for future resizes
    window.addEventListener('resize', debounceHandleWindowResize);
    return () =>
      window.removeEventListener('resize', debounceHandleWindowResize);
  }, []);

  return (
    <ViewportContext.Provider value={{ width, height, breakpoints }}>
      {props.children}
    </ViewportContext.Provider>
  );
}

export function useViewport() {
  return useContext(ViewportContext);
}
