import { url } from 'inspector';
import { forwardRef, ReactNode, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import styles from './styles';
import useButtonPressedState from './useButtonPressedState';
import loader from './assets/loader.gif';
import Image from 'next/image';

interface ButtonCustomProps {
  variant?: 'primary' | 'secondary' | 'terciary' | 'ghost';
  size?: 's' | 'm' | 'l';
  iconLeft?: ReactNode;
  iconRight?: ReactNode;
  loading?: boolean;
}

export type ButtonProps = ButtonCustomProps &
  Omit<JSX.IntrinsicElements['button'], 'ref'>;

const Button = forwardRef<HTMLDivElement, ButtonProps>(
  ({ children, iconRight, iconLeft, loading, ...attributes }, ref) => {
    const _ref = useRef(ref);
    const { isPressed, press, release } = useButtonPressedState(_ref);
    const [isLoading, setIsLoading] = useState(loading);

    useEffect(() => {
      setIsLoading(loading);
    }, [loading])

    return (
      <Root
        ref={_ref as any}
        data-mito
        onPointerDown={press}
        onPointerUp={release}
        onKeyDown={(e) => e.key === 'Enter' && press()}
        onKeyUp={(e) => e.key === 'Enter' && release()}
        className={`${!children ? '--only-icon' : ''} ${isPressed ? '--pressed' : ''
          }`}
        {...attributes}
      >
        <div className="bg" />
        <div className="container" />
        <div className="overlay" />
        {!isLoading && (
          <div className="children">
            {iconLeft}
            {children && <div className="text">{children}</div>}
            {iconRight}
          </div>)}
        {isLoading && (
          <div className="children">
            <Spinner>
              <Image src={loader} width='36px' height='36px' />
            </Spinner>
          </div>
        )}

      </Root>
    );
  },
);

Button.defaultProps = {
  variant: 'primary',
  size: 'l',
  disabled: false,
  loading: false,
};

export default Button;

/** Styled components */

type RootProps = Pick<ButtonCustomProps, 'size' | 'variant'>;
const Root = styled.button<RootProps>`
  ${({ size, variant }) => css`
    &[data-mito] {
      ${styles.common}
      ${size === 'l' && styles.large}
  
      // Variants
      ${variant === 'primary' && styles.primary}
      ${variant === 'secondary' && styles.secondary}
      ${variant === 'terciary' && styles.terciary}
      ${variant === 'ghost' && styles.ghost}
  
      // Sizes
      ${size === 's' && styles.small}
      ${size === 'm' && styles.medium}
      ${size === 'l' && styles.large}
    }
  `}
`;

const Spinner = styled.div({
  minWidth: '72px',
})