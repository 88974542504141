import { css } from 'styled-components';

//Gradient
const gradient = css`
  ${({ theme }) => css`
    background: linear-gradient(90deg, #fa9579 0%, #ffffff 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  `}
`;

//Heading
const heading1 = css`
  ${({ theme }) => css`
    font: 800 48px/100% Inter, sans-serif !important;
    letter-spacing: -0.02em !important;
  `}
`;
const heading2 = css`
  ${({ theme }) => css`
    font: 800 32px/100% Inter, sans-serif !important;
    letter-spacing: -0.02em !important;
  `}
`;
const heading3 = css`
  ${({ theme }) =>
    css`
      font: 700 24px/125% Inter, sans-serif !important;
    `}
`;
const heading4 = css`
  ${({ theme }) =>
    css`
      font: 700 20px/125% Inter, sans-serif !important;
    `}
`;
const heading5 = css`
  ${({ theme }) =>
    css`
      font: 700 16px/125% Inter, sans-serif !important;
      letter-spacing: -0.01em !important;
    `}
`;

const displayS = css`
  ${({ theme }) =>
    css`
      font: 900 32px/125% Inter, sans-serif !important;
      letter-spacing: -0.01em !important;
    `}
`;

const displayM = css`
  ${({ theme }) =>
    css`
      font: 900 40px/125% Inter, sans-serif !important;
      letter-spacing: -0.02em !important;
    `}
`;

const displayL = css`
  ${({ theme }) =>
    css`
      font: 900 48px/125% Inter, sans-serif !important;
      letter-spacing: -0.02em !important;
    `}
`;

const headingMedium = css`
  font: 800 24px/100% Inter, sans-serif !important;
  letter-spacing: -0.02em !important;
`;

const headingSmall = css`
  font: 800 20px/100% Inter, sans-serif !important;
  letter-spacing: -0.02em !important;
`;
//Body
const body_L = css`
  ${({ theme }) =>
    css`
      font: 500 16px/150% Inter, sans-serif !important;
    `}
`;
const body_M = css`
  ${({ theme }) =>
    css`
      font: 500 14px/150% Inter, sans-serif !important;
    `}
`;
const body_S = css`
  ${({ theme }) =>
    css`
      font: 500 12px/150% Inter, sans-serif !important;
    `}
`;

//Overline
const overline_12 = css`
  ${({ theme }) =>
    css`
      font: 500 12px/16px Inter, sans-serif !important;
    `}
`;
const overline_11 = css`
  ${({ theme }) =>
    css`
      font: 500 11px/12px Inter, sans-serif !important;
    `}
`;
const overline_uppercase = css`
  ${({ theme }) => css`
    font: 600 11px/12px Inter, sans-serif !important;
    letter-spacing: 0.04em !important;
    text-transform: uppercase !important;
  `}
`;

//UI Text
const uiText_XL_Bold = css`
  ${({ theme }) =>
    css`
      font: 700 20px/125% Inter, sans-serif !important;
    `}
`;
const uiText_XL_Medium = css`
  ${({ theme }) =>
    css`
      font: 500 20px/125% Inter, sans-serif !important;
    `}
`;
const uiText_L_Bold = css`
  ${({ theme }) =>
    css`
      font: 700 18px/125% Inter, sans-serif !important;
    `}
`;
const uiText_L_Medium = css`
  ${({ theme }) =>
    css`
      font: 500 18px/125% Inter, sans-serif !important;
    `}
`;
const uiText_M_Bold = css`
  ${({ theme }) =>
    css`
      font: 700 14px/125% Inter, sans-serif !important;
    `}
`;
const uiText_M_Medium = css`
  ${({ theme }) =>
    css`
      font: 500 14px/125% Inter, sans-serif !important;
    `}
`;
const uiText_S_Bold = css`
  ${({ theme }) =>
    css`
      font: 700 12px/125% Inter, sans-serif !important;
    `}
`;
const uiText_S_Medium = css`
  ${({ theme }) =>
    css`
      font: 500 12px/125% Inter, sans-serif !important;
    `}
`;

//Input
const label = css`
  ${({ theme }) =>
    css`
      font: 700 14px/24px Inter, sans-serif !important;
    `}
`;
const input = css`
  ${({ theme }) =>
    css`
      font: 500 14px/24px Inter, sans-serif !important;
    `}
`;
const input_selected = css`
  ${({ theme }) =>
    css`
      font: 600 14px/24px Inter, sans-serif !important;
    `}
`;
const input_price = css`
  ${({ theme }) => css`
    font: 600 24px/24px Inter, sans-serif !important;
    letter-spacing: -0.02em !important;
  `}
`;
const helper = css`
  ${({ theme }) =>
    css`
      font: 500 12px/16px Inter, sans-serif !important;
    `}
`;

const typo = {
  gradient,
  heading1,
  heading2,
  heading3,
  heading4,
  heading5,
  displayS,
  displayM,
  displayL,
  body_L,
  headingMedium,
  headingSmall,
  body_M,
  body_S,
  overline_11,
  overline_12,
  overline_uppercase,
  uiText_XL_Bold,
  uiText_XL_Medium,
  uiText_L_Bold,
  uiText_L_Medium,
  uiText_M_Bold,
  uiText_M_Medium,
  uiText_S_Bold,
  uiText_S_Medium,
  label,
  input,
  input_selected,
  input_price,
  helper,
};

export default typo;
