import axios, { AxiosResponse } from 'axios';
import { venusEndpoint as backendURL } from '~/constants';
import RewardDetail from '~/pages/rewards/detail/[id]';

export const api = axios.create({
  baseURL: process.env.NEXT_PUBLIC_BACK_HOST,
  // headers: { "X-Mito-Authorization": "" },
});


/** Obtiene la ruta en base a los parámetros pasados */
const getUrl = (path: string, params?: { [key: string]: any }) => {
  const urlWithParams = Object.entries(params || []).reduce((url, [param, value]) => {
    return (url || path).replace(`:${param}`, value);
  }, '');
  return urlWithParams || path;
};

/** ENDPOINTS */

/** Obtiene el nonce de verifiación */
function getHealthCheck() {
  const url = '/healthz';
  return api.get<{ msg: string }>(url);
}

/** Obtiene el nonce de verifiación */
function getUsersNonce(params: { publicAddress: string }) {
  const url = getUrl('/users/:publicAddress', params);
  return api.get<{ msg: string }>(url);
}

/** Obtiene el token del usuario en MITO */
function postUsersAuth(body: { signature: string; publicAddress: string }) {
  const url = '/auth';
  return api.post<{ auth: string; signerAddress: string }>(url, body);
}

function getNftDetail(params) {
  const url = getUrl('/graph/token/details/:id', params);

  return api.get(url);
}

function getNftLotDetail(params) {
  const url = getUrl('/graph/token/originalLot/:token_id', params);

  return api.get(url);
}

function getVisaPaymentUrl(params) {
  const url = getUrl('/moonpay/generate_url/:token_id', params);
  return api.get(url);
}

function getNftSales(params) {
  const url = getUrl('/graph/token/more_sales/:token_id', params);
  return api.get(url);
}

/** Obtiene lista de ids de nfts del usuario */
function getNftsListFromAddress(params) {
  const url = getUrl('/graph/token/legacy/:user_address', params);

  return api.get(url);
}

/** get all primary lots */
function getAllPrimaryLots() {
  const url = getUrl('/graph/lots/primary');
  return api.get(url);
}

/** get all secondary lots */
function getAllSecondaryLots() {
  const url = getUrl('/graph/lots/secondary');
  return api.get(url);
}


/** get all lots by address */
function getLotsByAddress(params) {
  const url = getUrl('/graph/lots/:user_address', params);

  return api.get(url);
}

function getCollectorsByLot(params) {
  const url = getUrl('/graph/lot/coleccionistas/:lot_id', params);

  return api.get(url);
}

function getResaleLots(params) {
  const url = getUrl('/graph/lot/secondary_lots/:lot_id', params);

  return api.get(url);
}

function getNonce(params) {
  const url = getUrl('/session/nonce/:publicAddress', params);

  return api.get(url);
}

function checkUserName(params) {
  const url = getUrl('/users/check_username/:username', params);

  return api.get(url);
}

function getCollections() {
  const url = getUrl('/collections/');
  return api.get(url);
}

function getRewards() {
  // const url = getUrl('/rewards/');
  // return api.get(url);
  return []

}

export interface CustomMessage {
  es: string;
  en: string;
}
/** Obtiene el estado de la plataforma */
export interface GetAppStatusRes {
  status: 'KO' | 'WARN' | 'OK' | 'CUSTOM';
  message: CustomMessage;
}
function getAppStatus() {
  const url = '/active_status_plataforma';
  return api.get<GetAppStatusRes>(url);
}

/** Obtiene el cms */
function getCms() {
  const url = '/cms';
  return api.get<{ cms: string }>(url);
}

/** Post auth user sign */
function postAuthUser(body: { signature: string; publicAddress: string }) {
  const url = '/session/auth';
  return api.post<{ auth: string; signerAddress: string }>(url, body);
}

/** Put update user data */
function updateUserData(body) {
  const url = `/users`;
  const sendData = {
    data: {
      username: body.username,
      publicName: body.publicName,
      about: body.about,
      background: body.background ? { ...body.background } : undefined,
    }
  };

  return api.put(url, sendData, {
    headers: {
      Authorization: `Bearer ${body.token}`,
      "X-JWT-Token": body.token,
    }
  });
}
/** Post update profile images */
function updateAvatarImages(body) {
  const url = `/users/uploadContent`;

  return api.post(url, body.formData, {
    headers: {
      Authorization: `Bearer ${body?.token}`,
      "X-JWT-Token": body?.token,
      'Content-Type': 'multipart/form-data',
    }
  });
}

function getUserDataProfile(params) {
  const url = getUrl('/users/profile_info/:wallet_address', params);

  return api.get(url);
}

/** Update user links */
function postUserLinks(body) {
  const url = '/users/update_links';

  const sendData = {
    data: {
      discord: body.discord,
      instagram: body.instagram,
      twitter: body.twitter,
      webpage: body.webpage
    }
  };

  return api.put(url, sendData, {
    headers: {
      Authorization: `Bearer ${body?.token}`,
      "X-JWT-Token": body?.token,
    }
  });
}

function getUserAvailableRewards(params) {
  const url = getUrl('/rewards/user/available', params);

  return api.get(url, {
    headers: {
      Authorization: `Bearer ${params?.token}`,
      "X-JWT-Token": params?.token,
    }
  });
}

/** Post create Rewards */
function createRewardRequest(body) {
  const url = `/rewards/creator/createReward`;

  return api.post(url, body.formData, {
    headers: {
      Authorization: `Bearer ${body?.token}`,
      "X-JWT-Token": body?.token,
      'Content-Type': 'multipart/form-data',
    }
  });
}

/** Post create Rewards (deprecated) */
function registerNFTs(body) {
  const url = `/rewards/creator/registerNFTs`;

  return api.post(url, { collection: body.collection }, {
    headers: {
      Authorization: `Bearer ${body?.token}`,
      "X-JWT-Token": body?.token,
    }
  });
}


function getAllCollectiosWithRewards(params) {
  const url = getUrl('/rewards/creator/getCollections', params);
  return api.get(url, {
    headers: {
      Authorization: `Bearer ${params?.token}`,
      "X-JWT-Token": params?.token,
    }
  });
}

function getRewardsInfoFromToken(params) {
  const url = getUrl('/rewards/info/from_token/:tokenId', params);
  return api.get(url);
}

function getRewardsInfoFromCollection(params) {
  const url = getUrl('/rewards/info/from_collection/:collectionId', params);
  return api.get(url);
}

function deleteReward(params) {
  const url = getUrl('/rewards/creator/deleteReward/:reward_id', params);
  return api.delete(url, {
    headers: {
      Authorization: `Bearer ${params?.token}`,
      "X-JWT-Token": params?.token,
    }
  });
}

function downloadReward(params: any): Promise<AxiosResponse<Blob>> {
  const url = '/rewards/user/download_content'

  const body = {
    content_id: params?.content_id,
    reward_id: params?.reward_id,
    nfts_used_to_claim: params?.nfts_used_to_claim,
  };

  return api.post(url, body, {
    responseType: 'blob',
    headers: {
      Authorization: `Bearer ${params?.token}`,
      "X-JWT-Token": params?.token,
    }
  });
}

function postUserVisualizeReward(params) {
  const url = '/rewards/user/visualizeReward';

  const body = {
    reward_id: params?.reward_id,
    nfts_used_to_claim: params?.nfts_used_to_claim,
  };

  return api.post(url, body, {
    headers: {
      Authorization: `Bearer ${params?.token}`,
      "X-JWT-Token": params?.token,
    }
  });
}

function postUserClaimReward(params) {
  const url = '/rewards/user/claimReward';

  const body = {
    reward_id: params?.reward_id,
    nfts_used_to_claim: params?.nfts_used_to_claim,
  };

  return api.post(url, body, {
    headers: {
      Authorization: `Bearer ${params?.token}`,
      "X-JWT-Token": params?.token,
    }
  });
}


function getUserNFTsData(params) {
  const url = getUrl('/graph/userPage/:wallet', params);
  return api.get(url);
}

const MitoService = {
  api,
  getHealthCheck,
  getNftSales,
  getUsersNonce,
  postUsersAuth,
  getAppStatus,
  getNftLotDetail,
  getNftsListFromAddress,
  getNftDetail,
  getAllPrimaryLots,
  getAllSecondaryLots,
  getCollectorsByLot,
  getCms,
  getCollections,
  getResaleLots,
  checkUserName,
  getNonce,
  getLotsByAddress,
  postAuthUser,
  getVisaPaymentUrl,
  updateUserData,
  updateAvatarImages,
  getUserDataProfile,
  postUserLinks,
  getUserAvailableRewards,
  createRewardRequest,
  getAllCollectiosWithRewards,
  registerNFTs,
  getRewardsInfoFromToken,
  downloadReward,
  getRewardsInfoFromCollection,
  postUserVisualizeReward,
  postUserClaimReward,
  getUserNFTsData,
  deleteReward,
};

export default MitoService;
