import Link from 'next/link';
import React from 'react';
import classes from './Logo.module.scss';

interface Props {
  tagBackground?: 'grey' | 'black';
  noMargin?: boolean;
}

function Logo({ tagBackground, noMargin = false }: Props) {
  return (
    <Link href="/">
      <a className={`${classes.Logo} ${noMargin ? classes.noMargin : null}`}>
        <img src="/media/branding/logo.svg" className={`${classes.icons} ${classes.logo}`} />
      </a>
    </Link>
  );
}

export default Logo;
