import 'react-toastify/dist/ReactToastify.min.css';
import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  :root {
    font-size: 10px;
    position: relative;
    font-family: 'Inter', sans-serif;
    font-feature-settings: 'tnum' off;

    -webkit-font-smoothing: antialiased;
    -moz-font-smoothing: antialiased;
    -o-font-smoothing: antialiased;

    --transition-general: all 200ms ease-in-out;
    --theme-grey1: #202020;
    --theme-grey2: #262626;
    --theme-grey3: #4a4a4a;
    --theme-grey4: #383838;
    --theme-grey5: #979797;
    --theme-grey6: #DCDCDC;
    --theme-grey7: #B2B2B2;
    --theme-grey8: #303030;
    --theme-black: black;
    --theme-white: white;
    --theme-cream: #fa9579;
    --theme-state-valid-light: #00EBA0;
    --theme-state-valid-dark: #234339;
    --theme-state-danger-light: #FF787F;
    --theme-state-danger-dark: #472E30;
    --theme-state-warning-dark: #3E3A24;
    --theme-state-warning-light: #FFEA79;

    --theme-dark-overlay: rgba(0, 0, 0, 0.6);

    --mito-surface-0: #121212;
    --mito-surface-1: #202020;
  }

  body,
  body[data-theme='dark'] {
    --background1: var(--mito-surface-0);
    --background2: var(--theme-grey2);
    --background3: var(--theme-grey3);
    --background4: var(--theme-grey4);
    --background5: var(--theme-grey5);
    --background8: var(--theme-grey8);
    --background-strong: var(--theme-black);
    --background-contrast: var(--theme-white);
    --text-color-faded: var(--theme-grey5);
    --text-color-light: var(--theme-grey6);
    --text-color-secondary: var(--theme-grey7);
    --text-color: var(--theme-white);
    --text-color-inverse: var(--theme-black);
    --overlay: var(--theme-dark-overlay);
    --highlight: var(--theme-cream);

    background: var(--mito-surface-0);
    /* background: var(--highlight); */
  }

  body {
    padding: 0;
  }

  body[data-modal='open'] {
    overflow-y: ${(props) => props.theme.modal.onScreen} !important;
    overflow-x: hidden;
  }
  body[data-modal='closed'] {
    overflow-y: ${(props) => props.theme.modal.offScreen} !important;
    overflow-x: hidden;
  }

  input {
    border: none;
    outline: none;
    background-color: transparent;
  }
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  button {
    border: none;
    cursor: pointer;
    background: none;
    user-select: none;
    color: inherit;
    font-family: var(--fontfamily-default);
  }
  button:focus {
    outline: none;
  }
  li {
    list-style: none;
    font: inherit;
  }
  a {
    color: inherit;
    cursor: pointer;
    text-decoration: none;
  }
  a:focus {
    outline: none;
  }

  .capitalize {
    &::first-letter {
      text-transform: capitalize;
    }
  }

`;

export default GlobalStyles;
