import { Dispatch } from 'react';
import { createContext } from 'react';
import * as literals from '~/i18n/literals.json';

export enum Languages {
  es = 'es',
  en = 'en',
}

export const LanguagesTimeFormats = {
  large: {
    es: 'LL',
    en: 'MMMM DD, YYYY',
  },
  short: {
    es: 'DD MMM YYYY',
    en: 'MMM DD, YYYY',
  },
};

export interface LanguageContextValue {
  lang?: Languages;
  setLang?: Dispatch<LanguageContextValue['lang']>;
  literals: typeof literals;
}

export const LanguageContext = createContext({} as LanguageContextValue);
