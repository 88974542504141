import { useEffect } from 'react';
import { ReactNode, useMemo, useState } from 'react';
import { LanguageContext, LanguageContextValue, Languages } from './context';
import * as literals from '~/i18n/literals.json';
import { logClient } from '~/utils';

interface Props {
  children: ReactNode;
}

const LOCALSTORAGE_KEY = 'language';
export const DEFAULT_LANG = Languages.en;

export function LanguageProvider({ children }: Props) {
  const [lang, setLangState] = useState<LanguageContextValue['lang']>(null);

  const setLang = (value: LanguageContextValue['lang']) => {
    localStorage.setItem(LOCALSTORAGE_KEY, value);
    setLangState(value);
  };

  // Inicializa con el idioma por defecto
  useEffect(() => {
    if (lang) return;
    if (typeof window === undefined) return;
    const browserLangs = navigator?.languages?.map((l) => l.slice(0, 2)) || [];

    const storedLang = localStorage.getItem(LOCALSTORAGE_KEY);
    const detectedLang = browserLangs?.find((l) => Languages[l]) || Languages[navigator?.language];

    setLangState(storedLang || detectedLang || DEFAULT_LANG);

    logClient({ storedLang, browserLangs, detectedLang });
  }, []);

  const value = useMemo(() => ({ lang, literals, setLang }), [lang]);

  return <LanguageContext.Provider value={value}>{children}</LanguageContext.Provider>;
}
