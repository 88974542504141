import { useState } from 'react';

export default function useButtonPressedState(ref: any) {
  const [isPressed, setPressed] = useState(false);
  const [wasFocused, setWasFocused] = useState(false);

  const press = () => {
    setPressed(true);
    setWasFocused(ref.current === document.activeElement);
  };

  const release = () => {
    setPressed(false);
    if (!wasFocused) ref.current.blur();
    setWasFocused(false);
  };

  return {
    isPressed,
    press,
    release,
  };
}
