import { Contracts } from './helpers/contractBooter';
import types from './actionTypes';
import { Contract } from '@ethersproject/contracts';
import { SaleWithNftHash } from './services/graph/types';

const initialContracts = {
  nftContract: {} as Contract,
  newNftContract: {} as Contract,
  bridgeNFT: {} as Contract,
  royaltyContract: {} as Contract,
  auctionHubContract: {} as Contract,
  firstPriceContract: {} as Contract,
  limitedTimedContractV2: {} as Contract,
  firstPriceContractV2: {} as Contract,
  limitedTimedContract: {} as Contract,
  limitedTimedBuyLimitContract: {} as Contract,
  directSaleContract: {} as Contract,
  limitedTimedBuyLimitContractV2: {} as Contract,
  directSaleContractV2: {} as Contract,
  timedEditionContractMoonPay: {} as Contract,
};
export interface State {
  isDev: boolean;
  provider: any;
  isWalletConnecting: boolean;
  isWalletConnected: boolean;
  contracts: Contracts;
  userAddress: string;
  userBalance: number;
  galleryValue: number;
  userLots: number;
  usersNFTs?: any;
  userRewards?: { availableCards: Array<any>, claimedCards: Array<any> },
  salesData: SaleWithNftHash[];
  error: string;
  flags: {
    bidStatus: string;
    termsAccepted: boolean;
    cookiesAccepted: boolean;
  };
  userRoyalties: number;
  transactionData: any;
  conversionRate: number;
  globalModal: 'exchange' | 'settings' | 'visa' | null;
  currentLot: any;
  collectionsWithRewards: Array<any>;
  isSessionAuthenticated: boolean;
  marketplaceLastTab: string;
  nftFirstAppearence: any
}

const initialState: State = {
  isDev: process.env.NODE_ENV !== 'production',
  provider: null,
  isWalletConnecting: false,
  isWalletConnected: false,
  contracts: initialContracts,
  userAddress: '',
  userBalance: 0,
  galleryValue: 0,
  userLots: 0,
  salesData: null,
  usersNFTs: [],
  userRewards: { availableCards: [], claimedCards: [] },
  error: '',
  flags: {
    bidStatus: '',
    termsAccepted: false,
    cookiesAccepted: false,
  },
  userRoyalties: 0,
  transactionData: null,
  conversionRate: 0,
  globalModal: null,
  currentLot: null,
  collectionsWithRewards: [],
  isSessionAuthenticated: false,
  marketplaceLastTab: '1',
  nftFirstAppearence: null
};

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case types.SetUserLots.SET_USER_LOST_SUCCESS:
      return {
        ...state,
        userLots: action.payload,
      };
    case types.SetUserSalesData.SET_USER_SALES_DATA_SUCCESS:
      return {
        ...state,
        salesData: action.payload,
      };
    case types.setUsersNFTs.SET_USER_NFTS_SUCCESS:
      return {
        ...state,
        usersNFTs: action.payload,
      };
    case types.setUserRewards.SET_USER_REWARDS_SUCCESS:
      return {
        ...state,
        userRewards: action.payload,
      };
    case types.setRewardManager.SET_COLLECTIONS_WITH_REWARDS:
      return {
        ...state,
        collectionsWithRewards: action.payload,
      };
    case types.SetGalleryValue.SET_GALLERY_VALUE_SUCCESS:
      return {
        ...state,
        galleryValue: action.payload,
      };
    case types.SetTermsAccepted.SET_TERMS_ACCEPTED:
      return {
        ...state,
        flags: {
          ...state.flags,
          termsAccepted: action.payload,
        },
      };
    case types.SetCookiesAccepted.SET_COOKIES_ACCEPTED:
      return {
        ...state,
        flags: {
          ...state.flags,
          cookiesAccepted: action.payload,
        },
      };
    case types.SetConversionRate.SET_CONVERSION_RATE_SUCCESS:
      return {
        ...state,
        conversionRate: action.payload,
      };
    case types.SetUserBalance.SET_USER_BALANCE_SUCCESS:
      return {
        ...state,
        userBalance: action.payload,
      };
    case types.Bid.BID_PENDING:
      return {
        ...state,
        flags: {
          ...state.flags,
          bidStatus: 'pending',
        },
      };
    case types.Bid.BID_FAIL:
      return {
        ...state,
        flags: {
          ...state.flags,
          bidStatus: 'fail',
          transactionData: action.payload,
        },
      };
    case types.Bid.BID_SUCCESS:
      return {
        ...state,
        flags: {
          ...state.flags,
          bidStatus: 'success',
          transactionData: action.payload,
        },
      };
    case types.SetWalletDisconnected.SET_WALLET_DISCONNECTED_SUCCESS:
      return {
        ...state,
        isWalletConnected: false,
        isWalletConnecting: false,
        userAddress: '',
        provider: null,
      };
    case types.SetWalletConnected.SET_WALLET_CONNECTED_REQUEST:
      return { ...state, isWalletConnecting: true };
    case types.SetWalletConnected.SET_WALLET_CONNECTED_SUCCESS:
      return { ...state, isWalletConnected: true, isWalletConnecting: false };
    case types.SetProvider.SET_PROVIDER_SUCCESS:
      return { ...state, provider: action.payload };
    case types.SetContracts.SET_CONTRACTS_SUCCESS:
      return {
        ...state,
        contracts: action.payload,
      };
    case types.GetRoyalties.GET_ROYALTIES_SUCCESS:
      return {
        ...state,
        userRoyalties: action.payload,
      };
    case types.SetWalletAddress.SET_WALLET_ADDRESS_SUCCESS:
      return { ...state, userAddress: action.payload };
    case types.GlobalModal.OPEN:
      return { ...state, globalModal: action.payload };
    case types.GlobalModal.CLOSE:
      return { ...state, globalModal: null };
    case types.GlobalModal.CURRENT_LOT:
      return { ...state, currentLot: action.payload };
    case types.SetSessionAuthenticated.SET_SESSION_AUTHENTICATED_SUCCESS:
      return { ...state, isSessionAuthenticated: true };
    case types.SetMarketplaceLastTab.SET_MARKETPLACE_LAST_TAB:
      return { ...state, marketplaceLastTab: action.payload };
    default:
      return state;
  }
};

export { initialState, reducer };
