import { css } from 'styled-components';
import typo from '~/styles/typography';

const state = {
  hover: ':hover:not(:disabled)',
  pressed: ':is(.--pressed):not(:disabled)',
  focus: ':focus:not(:disabled):not(.--pressed):not(:invalid)',
  disabled: ':disabled',
  invalid: ':invalid',
};

const inputStyles = {
  common: css`
    ${({ theme }) => css`
      width: 100%;
      border-radius: 8px;
      background: rgba(48, 48, 48, 1);
      border: 1px solid rgba(48, 48, 48, 1);
      color: white;

      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      &::placeholder {
        color: #979797;
      }

      ${state.hover} {
        background: #4a4a4a;
      }

      ${state.focus} {
        border: 1px solid white;
      }

      ${state.pressed} {
        border: 1px solid white;
      }

      ${state.invalid} {
        border: 1px solid #ff787f;

        ~ .error-message {
          display: block;
        }
      }
    `}
  `,

  container: css`
    ${({ theme }) => css`
      position: relative;

      .label-container {
        margin-bottom: 8px;

        > label {
          ${typo.label}

          > span {
            color: ${theme.colors.highlight};
          }
        }
      }

      .error-message {
        ${typo.helper}
        color: #FF787F;
        margin-top: 8px;
      }

      .input-container {
        position: relative;
        > p {
          ${typo.input_selected}
          position: absolute;
          right: 12px;
          top: 12px;
          text-align: right;
        }
      }

      .ico-search {
        position: absolute;
        right: 13.3px;
        top: 13.3px;
      }
    `}
  `,

  large: css`
    height: 48px;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: -0.02em;
    text-align: left;
    padding-left: 12px;
  `,

  medium: css`
    height: 40px;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0;
    text-align: left;
    padding-left: 12px;
  `,

  fullWidth: css`
    width: 100%;
    padding: 8px 12px 8px 12px;
  `,
};

export default inputStyles;
