import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import styles from './styles';
import { IconSearch } from '~/components/Icons';
import { FilterTypes } from '~/components/_cdk/Filters';
import typo from '~/styles/typography';

interface InputCustomProps {
  size?: string;
  inputType?: string;
}

export type InputProps = InputCustomProps;
type InputTypes = 'text' | 'number' | 'password' | 'email';
interface Props {
  inputType?: string;
  label?: string;
  errorMessage?: string;
  showMatic?: boolean;
  sendValue?: any;
  showSearch?: boolean;
  size?: any;
  placeholder?: any;
  attributes?: any;
  max?: any;
  value?: any;
  filtersSelected?: FilterTypes;
  handleFiltersSelected?: React.Dispatch<React.SetStateAction<FilterTypes>>;
  fullWidth?: boolean;
  name?: string;
  required?: boolean;
}

const Input = ({
  inputType,
  label,
  showMatic,
  sendValue,
  errorMessage,
  showSearch,
  max,
  filtersSelected,
  handleFiltersSelected,
  fullWidth = false,
  name,
  required = false,
  ...attributes
}: Props) => {
  const [value, setValue] = useState(null);

  const handleChange = (e) => {
    const inputValue = e.target.value;
    if (max >= inputValue && inputType === 'number') {
      setValue(inputValue);
      sendValue(inputValue);
    }

    if (inputType === 'text') {
      setValue(inputValue);
      sendValue(inputValue);
    }

    if (inputType === 'text' && filtersSelected) {
      setValue(inputValue);
      handleFiltersSelected((filtersSelected) => ({ ...filtersSelected, search: inputValue }));
    }
  };

  return (
    <Container fullWidth={fullWidth}>
      {label && <div className="label-container">{label && <label>{label}</label>}</div>}
      <div className="input-container">
        {showMatic && <p>MATIC</p>}
        {showSearch && <IconSearch className="ico-search" />}
        <InputContainer
          type={inputType}
          data-mito
          name={name}
          required={required}
          onKeyDown={(e) => e.key === 'Enter'}
          onKeyUp={(e) => e.key === 'Enter'}
          value={filtersSelected?.search ? filtersSelected?.search : value ? value : ''}
          onChange={handleChange}
          {...attributes}
        ></InputContainer>
        {errorMessage !== '' && <span className="error-message">{errorMessage}</span>}
      </div>
    </Container>
  );
};

Input.defaultProps = {
  disabled: false,
  inputType: 'text',
  max: 9999999,
  min: 0,
  step: 0.000001,
  errorMessage: '',
};

export default Input;

/** Styled components */

interface InputContainerProps {
  size?: any;
}
const InputContainer = styled.input<InputContainerProps>`
  ${({ size }) => css`
    &[data-mito] {
      ${styles.common}
      ${typo.input}

      // Sizes
      ${size === 'l' && styles.large}
      ${size === 'm' && styles.medium}
      ${size === 'full' && styles.fullWidth}
    }
  `}
`;

const Container = styled.div<any>`
  ${() => css`
      ${styles.container}
    }
  `}
  ${({ fullWidth }) => fullWidth && 'width: 100%;'}
`;
