import dynamic from 'next/dynamic';

import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { Web3ReactProvider } from '@web3-react/core';
import { ThemeProvider as StyledComponentsThemeProvider } from 'styled-components';
import { ToastContainer } from '~/components/_ui-kit/Toast';
import TagManager from 'react-gtm-module';

// hooks
import useConsoleWarning from '~/hooks/useConsoleWarning';
import useHealthCheck from '~/hooks/useHealthCheck';

// providers
import { NotificationsProvider } from '~/modules/Notifications/context/provider';
import { AppProvider } from '~/store/appContext/provider';
import { CmsProvider } from '~/store/cmsContext/provider';
import { ContractProvider } from '~/store/contractContext/contractContext';
import { FiatConversionProvider } from '~/store/fiatConversionContext/provider';
import { LanguageProvider } from '~/store/languageContext/provider';
import { StoreProvider } from '~/store/store';
import { ThemeProvider } from '~/store/themeContext/themeContext';
import { UsersProvider } from '~/store/userContext/provider';
import { ViewportProvider } from '~/store/viewportContext/viewportContext';
import WalletProvider from '~/store/walletContext/WalletContext';

import '~/styles/antd/antd.dark.css';
import GlobalStyles from '~/styles/global';
import '~/styles/main.scss';
import theme from '~/styles/theme';
import { getLibrary } from '~/wallets/utils';
import { useEffect } from 'react';

const ExchangeModal = dynamic(() => import('~/components/_core/ExchangeModal'), { ssr: false });

const SignTokenModal = dynamic(() => import('~/components/_core/SignTokenModal'), { ssr: false });

const CookieDialog = dynamic(import('~/components/_cdk/CookieDialog'));
const NextNprogress = dynamic(import('nextjs-progressbar'));

if (process.env.NEXT_PUBLIC_ENVIRONMENT === 'PRO') {
  Sentry.init({
    dsn: 'https://c81c8110569343e0b361aeb19de53d47@o994842.ingest.sentry.io/5953530',
    integrations: [new Integrations.BrowserTracing()],
    // TODO: calibrar para producción
    tracesSampleRate: 0.25,
  });
}

function MyApp({ Component, pageProps }: any) {
  useHealthCheck();
  useConsoleWarning();

  useEffect(() => {
    const tagManagerArgs = {
      gtmId: 'GTM-T7CT9SQ',
    };

    TagManager.initialize(tagManagerArgs);
  }, []);

  return (
    <>
      <ThemeProvider>
        <LanguageProvider>
          <StoreProvider>
            <FiatConversionProvider>
              <CmsProvider>
                <UsersProvider>
                  <ContractProvider>
                    <Web3ReactProvider getLibrary={getLibrary}>
                      <WalletProvider>
                        <ViewportProvider>
                          <NotificationsProvider>
                            <NextNprogress
                              color="#fa9579"
                              startPosition={0.4}
                              stopDelayMs={200}
                              height={2}
                              showOnShallow={true}
                              options={{ showSpinner: false }}
                            />
                            <StyledComponentsThemeProvider theme={theme}>
                              <>
                                <ExchangeModal />
                                <SignTokenModal />
                                <CookieDialog />
                                <ToastContainer />
                                <GlobalStyles />
                                <AppProvider>
                                  <Component {...pageProps} />
                                </AppProvider>
                              </>
                            </StyledComponentsThemeProvider>
                          </NotificationsProvider>
                        </ViewportProvider>
                      </WalletProvider>
                    </Web3ReactProvider>
                  </ContractProvider>
                </UsersProvider>
              </CmsProvider>
            </FiatConversionProvider>
          </StoreProvider>
        </LanguageProvider>
      </ThemeProvider>
    </>
  );
}

export default MyApp;
