export interface Image {
  content_type: string;
  filename: string;
  resource_url: string;
}

export interface Video {
  content_type: string;
  filename: string;
  resource_url: string;
}

export interface Collection {
  id: string;
  nfts?: NFT[];
  title: string;
  creator: string;
}
export interface Minter {
  id: string;
  address: string;
  isMinter: boolean;
  isActiveMinter: boolean;
  userIdentifier: string;
}

export interface Lot {
  id: string;
  owner?: string;
  token?: NFT;
  price?: string;
  currentPrice?: string;
  startTime?: string;
  endTime?: string;
  auction?: Auction;
  limit?: string;
  sales?: Sale[];
  isSecondary?: boolean;
  lotStatus?: string;
  isResolved: boolean;
  minted?: string;
  bids?: Bid[];
  error?: any;
}

export interface Token {
  id: string;
  creator: string;
  lastSoldPrice: string;
  location: string;
  contentHash: string;
  owner: string;
  lots: Lot[];
  collection: Collection;
  batch: any;
}


export interface NFT {
  id: string;
  creator: string;
  minter: string;
  identifier: string;
  owner: string;
  location: string;
  edition?: string;
  activeLot?: any;
  contentHash: string;
  isActive: boolean;
  lots?: Lot[]; // @derivedFrom(field: "token")
  sales?: Sale[]; // @derivedFrom(field: "token");
  collection?: Collection;
  batch?: {
    maxSupply: string;
    creator: string;
    nfts: NFT[];
    originalTokenID?: NFT;
  };
}

export interface Sale {
  id: string;
  price: string;
  buyer: string;
  seller?: string;
  token: NFT;
  timestamp: string;
  transactionHash: string;
}

export interface SaleWithNftHash extends Sale {
  nftHash: string;
  originalId: string;
}

export interface Bid {
  id: string;
  bidder: string;
  lot: Lot;
  amount: string;
  timestamp: string;
  transactionHash: string;
  claimed: boolean;
}

export interface BatchNFT {
  id: string;
  originalTokenID: NFT;
  creator: string;
  minter: string;
}

export interface FullSale {
  id: string;
  price: string;
  buyer: string;
  lot: Lot;
}

export interface Auction {
  id: string;
  address: string;
  index: string;
  owner: string;
  active: boolean;
  name: string;
  onlyPrimarySales: boolean;
}

// TODO: confirm storage location and user details to be stored.
export interface User {
  avatar?: string;
  name?: string;
  username?: string;
  about?: string;
  address?: string;
  isArtist?: boolean;
  verified?: boolean;
  isMinter?: boolean;
  backgroundImg?: string;
  socials?: {
    twitter?: string;
    website?: string;
    instagram?: string;
    discord?: string;
  };
  landing?: any;
}

export enum LotStatus {
  NO_LOT = '0',
  LOT_REQUESTED = '1',
  LOT_CREATED = '2',
  AUCTION_ACTIVE = '3',
  AUCTION_RESOLVED = '4',
  AUCTION_RESOLVED_AND_CLAIMED = '5',
  AUCTION_CANCELED = '6',
}

