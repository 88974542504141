import { css } from 'styled-components';

const config = {
  scaleUp: '1.03',
  scaleDown: '1',
};

const state = {
  hover: ':hover:not(:disabled)',
  pressed: ':is(.--pressed):not(:disabled)',
  focus: ':focus:not(:disabled):not(.--pressed):not(:hover)',
  disabled: ':disabled',
};

const backgroundGradient = css`
  ${({ theme }) => css`
    background: linear-gradient(90deg, ${theme.colors.highlight}, white 100%);
  `}
`;

const overlayGradient = css`
  ${({ theme }) => css`
    background: linear-gradient(90deg, rgba(250, 149, 121, 0.4) 0%, rgba(255, 255, 255, 0.4) 100%);
  `}
`;

const layer = css`
  /* user-select: none; */
  border-radius: 999px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const scaleOnInteractionTransitions = css`
  ${state.hover} {
    .bg {
      transform: scale(${config.scaleUp});
    }
    .overlay {
      opacity: 1;
      transform: scale(${config.scaleUp});
    }
  }

  ${state.pressed} {
    .bg {
      transform: scale(${config.scaleDown});
    }
    .overlay {
      opacity: 1;
      transform: scale(${config.scaleDown});
    }
  }
`;

const buttonStyles = {
  common: css`
    ${({ theme }) => css`
      * {
        transition-property: transform, box-shadow;
        transition-duration: 0.05s;
        transition-timing-function: linear;
      }
      display: inline-block;
      pointer-events: all;
      border-radius: 999px;
      position: relative;
      background: transparent;
      .bg {
        ${layer}
        z-index: 0;
        background: transparent;
      }
      .container {
        ${layer}
        z-index: 1;
      }
      .overlay {
        ${layer}
        z-index: 2;
        opacity: 0;
      }
      .children {
        position: relative;
        z-index: 3;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        text-transform: uppercase;
        padding: 4px 8px;
        text-align: center;
      }

      .text {
        padding: 0 8px;
        font-weight: 900;
        letter-spacing: 0.02em;
        font-size: 12px;
        line-height: 11px;
        position: relative;
      }

      svg {
        font-size: 24px;
        position: relative;
        margin: 0 8px;

        :first-child {
          margin-right: 0px;
        }
        :last-child {
          margin-left: 0px;
        }
      }

      &.--only-icon {
        border-radius: 999px;
        & > * {
          border-radius: 999px !important;
        }
      }

      ${state.hover} {
        .overlay {
          opacity: 1;
        }
      }

      ${state.focus} {
        box-shadow: 0px 0px 0px 5px ${theme.colors.backgroundContrast};
        .bg {
          box-shadow: 0px 0px 0px 3px ${theme.colors.backgroundStrong};
        }
        .overlay {
          opacity: 1;
        }
      }

      ${state.pressed} {
        .overlay {
          opacity: 1;
        }
      }

      ${state.disabled} {
        pointer-events: none;
        cursor: disabled;
        color: ${theme.colors.textColorFaded};
        .bg {
          background: ${theme.colors.background3};
          opacity: 0.6;
        }
      }
    `}
  `,

  primary: css`
    ${({ theme }) => css`
      color: ${theme.colors.textColorInverse};
      .bg {
        ${backgroundGradient}
      }
      .overlay {
        background: ${theme.colors.highlight};
      }
      ${scaleOnInteractionTransitions}
      ${state.focus} {
        .overlay {
          background: ${theme.colors.backgroundContrast};
          opacity: 1;
        }
      }
      ${state.pressed} {
        .bg {
          background: white;
        }
        .overlay {
          opacity: 0.65;
        }
      }
    `}
  `,

  secondary: css`
    ${({ theme }) => css`
      color: ${theme.colors.textColor};
      .bg {
        ${backgroundGradient}
      }
      .container {
        background: ${theme.colors.background1};
        top: 2px;
        left: 2px;
        width: calc(100% - 4px);
        height: calc(100% - 4px);
      }
      .overlay {
        ${overlayGradient}
      }
      ${scaleOnInteractionTransitions}
      ${state.hover} {
        .container {
          transform: scale(${config.scaleUp});
        }
        .overlay {
          opacity: 0.5;
        }
      }
      ${state.focus} {
        color: ${theme.colors.textColorInverse};
        .overlay {
          background: ${theme.colors.backgroundContrast};
          opacity: 1;
        }
      }
      ${state.pressed} {
        .container {
          transform: scale(${config.scaleDown});
        }
        .overlay {
          opacity: 1;
        }
      }
      ${state.disabled} {
        .bg {
          background: transparent;
          box-shadow: inset 0px 0px 0px 2px ${theme.colors.background3};
        }
        .container {
          background: transparent;
        }
      }
    `}
  `,

  terciary: css`
    ${({ theme }) => css`
      color: ${theme.colors.textColor};
      .bg {
        background: ${theme.colors.background3};
      }
      ${state.hover} {
        .overlay {
          background: ${theme.colors.backgroundContrast};
          opacity: 0.15;
        }
      }
      ${state.pressed} {
        .overlay {
          background: ${theme.colors.backgroundStrong};
          opacity: 0.2;
        }
      }
    `}
  `,

  ghost: css`
    ${({ theme }) => css`
      border-radius: 16px;
      background: transparent;
      color: ${theme.colors.textColor};
      cursor: pointer;
      & > * {
        border-radius: 16px !important;
      }
      .children {
        padding: 4px 0px;
      }
      ${state.hover} {
        .overlay {
          background: ${theme.colors.backgroundContrast};
          opacity: 0.1;
        }
        .children {
          svg {
            color: ${theme.colors.textColor};
          }
        }
      }
      ${state.focus} {
        .bg {
          background: ${theme.colors.backgroundStrong};
        }
        .container {
          background: ${theme.colors.backgroundContrast};
          opacity: 0.1;
        }
      }
      ${state.pressed} {
        .overlay {
          background: ${theme.colors.backgroundStrong};
          opacity: 0.2;
        }
      }
      ${state.disabled} {
        .bg {
          background: transparent;
        }
      }
    `}
  `,

  large: css`
    .children {
      height: 48px;
      min-width: 48px;
    }
  `,

  medium: css`
    .children {
      height: 40px;
      min-width: 40px;
      top: 0;
    }
  `,

  small: css`
    .children {
      height: 32px;
      min-width: 32px;
    }
    svg {
      font-size: 16px;
      top: 0px;
    }`,
};

export default buttonStyles;
