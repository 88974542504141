import { Web3Provider } from '@ethersproject/providers';
import { useWeb3React } from '@web3-react/core';
import { InjectedConnector } from '@web3-react/injected-connector';
import { WalletConnectConnector } from '@web3-react/walletconnect-connector';
import { createContext, useContext, useEffect, useState } from 'react';
import useToast from '~/hooks/useToast';
import { logClient, replaceTxT } from '~/utils';
import { Metamask, Torus, WalletConnect, Web3auth } from '../../components/Icons';
import { useToggle } from '../../hooks';
import { injectedConnector, loadTorus, walletconnect } from '../../wallets/connectors';
import { isMetamaskEnabled } from '../../wallets/utils';
import { useContracts } from '../contractContext/contractContext';
import { useLang } from '../languageContext/hooks';
import { findUserLots, getMaticBalance } from '../services/balances';
import { requestNetworkChange } from '../services/networks';
import { getUserRewardCards } from '../services/rewards';
import { useStore } from '../store';

export interface WalletContextProps {
  wallet: any;
  showWalletModal: boolean;
  termsAccepted: boolean;
  showTermsModal: boolean;
  setShowTermsModal: React.Dispatch<React.SetStateAction<boolean>>;
  setTermsAccepted: React.Dispatch<React.SetStateAction<boolean>>;
  disconnectWallet: (wallet: any) => void;
  setShowWalletModal: React.Dispatch<React.SetStateAction<boolean>>;
  handleConnect: (wallet: any) => void;
  activatingConnector: any;
  chainIdIsCorrect: boolean;
  metamaskEnabled?: boolean;
  update: any;
  enabledWallets: {
    name: string;
    displayLabel: string;
    description: any;
    connectFunction: () => void;
    selected: boolean;
    activating: boolean;
    disabled: boolean;
    active: boolean;
    icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  }[];
}

interface Props {
  children: React.ReactNode;
}

export const WalletContext = createContext({} as WalletContextProps);

export default function WalletProvider(props: Props) {
  const { t, lang } = useLang();
  const { state, actions } = useStore();
  const { updateContractsEthers, contracts } = useContracts();
  const { throwToast, dismissToast } = useToast();
  const [showWalletModal, setShowWalletModal] = useState(false);
  const [wallet, setWallet] = useState<any>(undefined);
  const [metamaskEnabled, setMetamaskEnabled] = useState(false);
  const [walletConnectRejected, setWalletConnectRejected] = useState(false);
  const [activatingConnector, setActivatingConnector] = useState<any>();
  const web3React = useWeb3React();
  const {
    connector,
    activate,
    deactivate,
    active,
    chainId,
    account,
    error: web3Error,
    library,
  } = web3React;

  const { isWalletConnected, isWalletConnecting } = state;

  const [termsAccepted, setTermsAccepted] = useToggle(false);
  const [showTermsModal, setShowTermsModal] = useToggle(false);
  const [torusConnector, setTorusConnector] = useState(loadTorus({ lang }));
  const [walletConnector, setWalletConnector] = useState(walletconnect);

  //? connect new signer to ocntracts when wallet changes
  const update = async () => {
    actions.setWalletAddress(account);
    //@ts-ignore
    let web3 = null;
    if (state.provider) {
      web3 = new Web3Provider(state.provider);
    }
    if (!state.provider && connector && active) {
      let { provider } = await connector.activate();
      web3 = new Web3Provider(provider);
    }
    if (web3) {
      updateContractsEthers(web3.getSigner());
      const formattedBalance = await getMaticBalance(web3.getSigner());
      actions.setUserBalance(formattedBalance);
      const { galleryValue, usersNFTs, userLots, salesData } = await findUserLots(
        account,
        state.conversionRate,
      );

      actions.setGalleryValue(galleryValue);
      actions.setUserLots(userLots);
      actions.setUsersNFTs(usersNFTs);
      actions.setUserSalesData(salesData);
    }
  };

  useEffect(() => {
    if (account && active) {
      update();
    }
  }, [account, active]);

  //?first check for item on load
  useEffect(() => {
    if (window) {
      const hasAccepted = window.localStorage.getItem('termsAccepted');
      hasAccepted === 'true' && setTermsAccepted(true);
    }
  }, []);

  //? setItem every time termsAccepted changes
  useEffect(() => {
    window.localStorage.setItem('termsAccepted', termsAccepted.toString());
    actions.setTermsAccepted(termsAccepted);
  }, [termsAccepted]);

  useEffect(() => {
    const enabled = isMetamaskEnabled();
    setMetamaskEnabled(enabled);
  }, []);

  const isExpectedChain = chainId?.toString() === process.env.NEXT_PUBLIC_CHAIN_ID;
  useEffect(() => {
    // Mantiene el estado de iniciado sesión de MITO
    if (account && isExpectedChain) {
      actions.setWalletAddress(account);
      actions.setWalletConnected();
      return;
    }
  }, [account, isExpectedChain, connector]);

  // Sincroniza el estado del wallet con el de MITO
  const error = web3Error?.name;

  useEffect(() => {
    // Previene de mantener MITO conectado si hay un connector de web3 o aparece el wallet como conectado
    if (!error) return;

    if (error === 'UnsupportedChainIdError' && activatingConnector instanceof InjectedConnector)
      throwToast('chainIdError');

    if (error && activatingConnector instanceof WalletConnectConnector)
      setWalletConnectRejected(true);

    disconnectWallet(activatingConnector);
  }, [error, active, activatingConnector]);

  useEffect(() => {
    if (state.isWalletConnected) setShowWalletModal(false);
    if (state.isWalletConnected && !termsAccepted) setShowTermsModal(true);
  }, [state.isWalletConnected]);

  // ? if you want the modal to close when there is a completed connection, use this useEffect below
  useEffect(() => {
    if (activatingConnector && activatingConnector === connector) {
      setActivatingConnector(undefined);
    }
    active && account && setShowWalletModal(false);
  }, [activatingConnector, connector, active, setShowWalletModal]);

  // Actualiza los ingresos generados
  useEffect(() => {
    if (account && contracts.royaltyContract) {
      actions.getRoyalties({
        withdrawAmount: 0,
        userAddress: account,
        royaltiesContract: contracts.royaltyContract,
      });
      logClient('%c ** Update royalties', 'background: #eee; color: #555');
    }
  }, [contracts, account]);

  const selectMetamask = () => {
    setWallet(injectedConnector);
    handleConnect(injectedConnector);
  };

  const selectTorus = () => {
    const initialisedTorus = loadTorus({ lang });
    setTorusConnector(initialisedTorus);

    setWallet(initialisedTorus);
    handleConnect(initialisedTorus);
  };

  const selectWalletConnect = () => {
    setWalletConnector(walletconnect);
    setWallet(walletconnect);
    handleConnect(walletconnect);
  };

  const disconnectWallet = async (activeWallet: any) => {
    deactivate();
    setActivatingConnector(undefined);
    // activeWallet !== injectedConnector && (await activeWallet.close());
    activeWallet === torusConnector && (await activeWallet.close());
    activeWallet === walletConnector && (await walletConnector.close());
    actions.setProvider(null);
    actions.setWalletDisconnected();
  };

  const handleConnect = async (activatingWallet: any) => {
    try {
      actions.setWalletConnectedRequest();
      setActivatingConnector(activatingWallet);
      const activation = await activate(activatingWallet);
      let { provider } = await activatingWallet.activate();
      //@ts-ignore
      let web3 = new Web3Provider(provider);

      actions.setProvider(provider);
      setActivatingConnector(null);
      updateContractsEthers(web3.getSigner());
      const formattedBalance = await getMaticBalance(web3.getSigner());
      actions.setUserBalance(formattedBalance);
      await requestNetworkChange(web3, throwToast, dismissToast);
      // TODO: Implement sign login
      // await login(web3.getSigner());
    } catch (err) {
      // ? If user closes wallet popup modal, rejects connection
      disconnectWallet(activatingWallet);
      console.error({ err });
    }
  };

  // TODO: Locale
  const wallets = [
    {
      name: 'metamask',
      displayLabel: metamaskEnabled
        ? t('WalletModal:metamask:active:title')
        : t('WalletModal:metamask:disabled:title'),
      connectFunction: selectMetamask,
      selected: wallet === injectedConnector,
      activating: activatingConnector === injectedConnector,
      disabled: !metamaskEnabled,
      active: connector === injectedConnector && active,
      icon: Metamask,
      description: metamaskEnabled ? (
        t('WalletModal:metamask:active:text')
      ) : (
        <>
          {t('WalletModal:metamask:disabled:text')}
          <a href="https://metamask.io/" target="_blank">
            {t('WalletModal:metamask:disabled:link')}
          </a>
        </>
      ),
    },
    {
      name: 'torus',
      displayLabel: t('WalletModal:torus:active:title'),
      connectFunction: selectTorus,
      selected: wallet === torusConnector,
      activating: activatingConnector === torusConnector,
      active: connector === torusConnector && active,
      icon: Web3auth,
      disabled: false,
      description: t('WalletModal:torus:active:text'),
    },
    // {
    //   name: 'walletconnect',
    //   displayLabel: walletConnectRejected
    //     ? t('WalletModal:walletconnect:disabled:title')
    //     : t('WalletModal:walletconnect:active:title'),
    //   connectFunction: selectWalletConnect,
    //   selected: wallet === walletConnector,
    //   activating: activatingConnector === walletConnector,
    //   active: connector === walletConnector && active,
    //   icon: WalletConnect,
    //   disabled: walletConnectRejected,
    //   description: walletConnectRejected
    //     ? replaceTxT(t('WalletModal:walletconnect:disabled:text'), [
    //       '{reload}',
    //       <a onClick={() => location.reload()}>{t('WalletModal:walletconnect:disabled:link')}</a>,
    //     ])
    //     : t('WalletModal:walletconnect:active:text'),
    // },
  ];

  const enabledWallets = wallets;

  const chainIdIsCorrect = chainId && chainId.toString() === process.env.NEXT_PUBLIC_CHAIN_ID;

  const value = {
    showWalletModal,
    setShowWalletModal,
    setShowTermsModal,
    showTermsModal,
    termsAccepted,
    setTermsAccepted,
    disconnectWallet,
    enabledWallets,
    metamaskEnabled,
    handleConnect,
    activatingConnector,
    chainIdIsCorrect,
    wallet,
    update,
  };

  return <WalletContext.Provider value={value}>{props.children}</WalletContext.Provider>;
}

export function useWallets() {
  return useContext(WalletContext);
}
