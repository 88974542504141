export default {
  SetUserLots: {
    SET_USER_LOST_SUCCESS: 'SET_USER_LOST_SUCCESS',
  },
  SetUserSalesData: {
    SET_USER_SALES_DATA_SUCCESS: 'SET_USER_SALES_DATA_SUCCESS',
  },
  setUsersNFTs: {
    SET_USER_NFTS_SUCCESS: 'SET_USER_NFTS_SUCCESS',
  },
  setUserRewards: {
    SET_USER_REWARDS_REQUEST: 'SET_USER_REWARDS_REQUEST',
    SET_USER_REWARDS_SUCCESS: 'SET_USER_REWARDS_SUCCESS',
    SET_USER_REWARDS_FAIL: 'SET_USER_REWARDS_FAIL',
  },
  setRewardManager: {
    SET_COLLECTIONS_WITH_REWARDS: 'SET_COLLECTIONS_WITH_REWARDS',
  },
  SetGalleryValue: {
    SET_GALLERY_VALUE_SUCCESS: 'SET_GALLERY_VALUE_SUCCESS',
  },
  SetTermsAccepted: {
    SET_TERMS_ACCEPTED: 'SET_TERMS_ACCEPTED',
  },
  SetCookiesAccepted: {
    SET_COOKIES_ACCEPTED: 'SET_COOKIES_ACCEPTED',
  },
  SetConversionRate: {
    SET_CONVERSION_RATE_SUCCESS: 'SET_CONVERSION_RATE_SUCCESS',
  },
  SetUserBalance: {
    SET_USER_BALANCE_SUCCESS: 'SET_USER_BALANCE_SUCCESS',
  },
  SetWalletConnected: {
    SET_WALLET_CONNECTED_REQUEST: 'SET_WALLET_CONNECTED_REQUEST',
    SET_WALLET_CONNECTED_SUCCESS: 'SET_WALLET_CONNECTED_SUCCESS',
  },
  SetWalletDisconnected: {
    SET_WALLET_DISCONNECTED_SUCCESS: 'SET_WALLET_DISCONNECTED_SUCCESS',
  },
  SetProvider: {
    SET_PROVIDER_SUCCESS: 'SET_PROVIDER_SUCCESS',
  },
  SetContracts: {
    SET_CONTRACTS_REQUEST: 'SET_CONTRACTS_REQUEST',
    SET_CONTRACTS_SUCCESS: 'SET_CONTRACTS_SUCCESS',
    SET_CONTRACTS_FAIL: 'SET_CONTRACTS_FAIL',
    SET_CONTRACTS_PENDING: 'SET_CONTRACTS_PENDING',
  },
  SetWalletAddress: {
    SET_WALLET_ADDRESS_SUCCESS: 'SET_WALLET_ADDRESS_SUCCESS',
  },
  Bid: {
    BID_SUCCESS: 'BID_SUCCESS',
    BID_FAIL: 'BID_FAIL',
    BID_REQUEST: 'BID_REQUEST',
    BID_PENDING: 'BID_PENDING',
  },
  GetRoyalties: {
    GET_ROYALTIES_REQUEST: 'GET_ROYALTIES_REQUEST',
    GET_ROYALTIES_SUCCESS: 'GET_ROYALTIES_SUCCESS',
    GET_ROYALTIES_FAIL: 'GET_ROYALTIES_FAIL',
    GET_ROYALTIES_PENDING: 'GET_ROYALTIES_PENDING',
  },
  ClaimRoyalties: {
    CLAIM_ROYALTIES_REQUEST: 'CLAIM_ROYALTIES_REQUEST',
    CLAIM_ROYALTIES_SUCCESS: 'CLAIM_ROYALTIES_SUCCESS',
    CLAIM_ROYALTIES_FAIL: 'CLAIM_ROYALTIES_FAIL',
    CLAIM_ROYALTIES_PENDING: 'CLAIM_ROYALTIES_PENDING',
  },
  GlobalModal: {
    OPEN: 'OPEN',
    CLOSE: 'CLOSE',
    CURRENT_LOT: 'CURRENT_LOT',
  },
  SetSessionAuthenticated: {
    SET_SESSION_AUTHENTICATED_SUCCESS: 'SET_SESSION_AUTHENTICATED_SUCCESS',
  },
  SetMarketplaceLastTab: {
    SET_MARKETPLACE_LAST_TAB: 'SET_MARKETPLACE_LAST_TAB',
  },
};
