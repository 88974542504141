import { createContext } from 'react';
import { User } from '~/store/services/graph/types';

export interface UsersContextValue {
  users?: {
    [address: string]: User;
  };
}

export const UsersContext = createContext({} as UsersContextValue);
