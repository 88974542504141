import dynamic from 'next/dynamic';

import Overlay from './Overlay/Overlay';
import BlackOverlay from './BlackOverlay/BlackOverlay';

const Footer = dynamic(import('./Footer/Footer'));
const Layout = dynamic(import('./Layout/Layout'));
const Navbar = dynamic(import('./Navbar/Navbar'));
const WalletModal = dynamic(import('./WalletModal/WalletModal'));


export { Footer, Layout, Navbar, Overlay, WalletModal, BlackOverlay };
