const theme = {
  device: {
    mobileS: `@media (max-width: 600px)`,
    mobile: `@media (max-width: 767px)`,
    tabletS: `@media (min-width: 768px)`,
    tabletL: `@media (min-width: 1024px)`,
    desktop: `@media (min-width: 1440px)`,
  },
  colors: {
    background1: 'var(--background1)',
    background2: 'var(--background2)',
    background3: 'var(--background3)',
    background4: 'var(--background4)',
    background5: 'var(--background5)',
    background8: 'var(--background8)',
    backgroundStrong: 'var(--background-strong)',
    backgroundContrast: 'var(--background-contrast)',
    textColor: 'var(--text-color)',
    textColorInverse: 'var(--text-color-inverse)',
    textColorFaded: 'var(--text-color-faded)',
    textColorSecondary: 'var(--text-color-secondary)',
    textColorLight: 'var(--text-color-light)',
    overlay: 'var(--overlay)',
    highlight: 'var(--theme-cream)',
    state: {
      validFg: 'var(--theme-state-valid-light)',
      validBg: 'var(--theme-state-valid-dark)',
      dangerFg: 'var(--theme-state-danger-light)',
      dangerBg: 'var(--theme-state-danger-dark)',
      warningFg: 'var(--theme-state-warning-light)',
      warningBg: 'var(--theme-state-warning-dark)',
    },
  },
  gradients: {
    bannerOverlay: 'linear-gradient(180deg, rgba(20, 20, 20, 0) 0%, rgba(20, 20, 20, 0.8) 100%)',
  },
  modal: {
    onScreen: 'hidden',
    offScreen: 'auto',
  }
};

export default theme;

export type MitoTheme = typeof theme;
