import * as Toastify from 'react-toastify';
import styled, { createGlobalStyle, css } from 'styled-components';
import useMediaDevice from '~/hooks/useMediaDevice';
import Alert, { AlertProps } from '../Alert';
import CloseButton from '../CloseButton';

export interface ToastProps extends AlertProps {
  options?: Omit<Toastify.ToastOptions, 'type'>;
}

/** Handler */
export const toast = ({ options, ...alertProps }: ToastProps) => {
  return Toastify.toast(<Alert iconSize="large" secureCloseArea {...alertProps} />, {
    type: alertProps.type,
    ...options,
  });
};

/** Container Provider */
export const ToastContainer = () => {
  const isMobile = useMediaDevice('mobile');
  const CustomClose = ({ closeToast }: any) => (
    <CloseButtonContainer>
      <CloseButton onClick={closeToast} />
    </CloseButtonContainer>
  );
  return (
    <>
      <Toastify.ToastContainer
        position={isMobile ? 'bottom-center' : 'bottom-right'}
        closeButton={CustomClose}
        closeOnClick={false}
        autoClose={false}
        pauseOnFocusLoss={false}
        pauseOnHover={true}
        limit={3}
      />
      <ToastifyOverrides />
    </>
  );
};

/** Styled components */

const CloseButtonContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  padding: 16px;
`;

const ToastifyOverrides = createGlobalStyle`
  ${({ theme }) => css`
    /** Toastify customization */
    .Toastify {
      &__toast {
        padding: 0;
        min-height: unset;
        border-radius: unset;
        display: block;
        background: transparent !important;
        border-radius: 16px;
        font-family: unset;
        font-weight: 500;
        margin-bottom: 8px;

        &-container {
          width: 100%;
          max-width: 488px;
          color: unset;
          padding: 24px;
          margin-block: auto;

          ${theme.device.tabletS} {
            padding: 0px;
          }

          &--bottom-right {
            bottom: 32px;
            right: 32px;
          }
        }

        &-body {
          margin: 0;
          padding: 0;
        }
      }

      &__progress-bar {
        filter: opacity(0.5);
        background: grey;
      }
    }
  `}
`;
