import { useWeb3React } from '@web3-react/core';
import { useEffect } from 'react';
import { ReactNode, useMemo, useReducer } from 'react';
import { getActiveLots, logClient } from '~/utils';
import { NotificationsContext as Context, notificationsInitialState as initialState } from '.';
import { useNotificationUtils } from './utils';
import NotificationsQueries from './queries';
import { notificationsReducer as reducer } from './reducer';
import { useStore } from '~/store/store';
import { useLang } from '~/store/languageContext/hooks';

export function NotificationsProvider(props: { children: ReactNode }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { state: store } = useStore();
  const { userRoyalties } = store;
  const { getNotificationsByLot } = useNotificationUtils();
  const { account } = useWeb3React();
  const { lang, t } = useLang();

  // Actualiza las notificaciones mediante polling, añadiendo la notificación de ingresos generados si
  useEffect(() => {
    // Valida el address y resetea las notificaciones si hay cambios en las dependencias
    if (!account) return;
    dispatch({ type: 'reset' });

    const payload: Parameters<typeof fetch>[0] = { royalties: userRoyalties };
    const updateNotifications = () => fetch(payload);

    // Establece el polling con fetch inicial
    setTimeout(updateNotifications);
    const interval = setInterval(updateNotifications, 10 * 1000);

    // Limpia el polling
    return () => clearInterval(interval);
  }, [account, userRoyalties, lang,  store.userRewards]);

  /** Actualiza las notificaciones */
  const fetch = async (payload: { royalties: number }) => {
    dispatch({ type: 'fetching' });
    try {
      const { royalties } = payload;
      const lots = await NotificationsQueries.lots();
      const address = account?.toLowerCase();
      const activeLots = getActiveLots(lots);

      const unclaimedAvailableCards = store?.userRewards?.availableCards;

      const notsByLot = getNotificationsByLot(activeLots, address, royalties, unclaimedAvailableCards);

      dispatch({ type: 'success', payload: { data: notsByLot } });
      logClient(`%c ** Notifications updated`, 'background: #eee; color: #555');
    } catch (error) {
      console.error(error);
      dispatch({ type: 'error', payload: { error } });
    }
  };

  /** Valor memoizado del Context */
  const value = useMemo(() => ({ actions: { fetch }, state }), [state]);

  return <Context.Provider value={value}>{props.children}</Context.Provider>;
}
