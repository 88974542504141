import axios from 'axios';
import { graphEndpoint } from '~/constants';
import { Collection, Lot, Minter } from './types';

export const getNFTById = async (id: string): Promise<any> => {
  return axios.post(graphEndpoint, {
    query: `
    {
      nft(id: "${id}") {
        id
        isActive
        edition
        creator
        minter
        identifier
        location
        contentHash
        owner
        lots (first: 1000){
          id
          owner
          price
          minted
          startTime
          endTime
          limit
          isResolved
          isSecondary
          lotStatus
          }
        collection {
          id
          title
          creator
        }
      }
    }
    `,
  });
};

export const getNFTsByLocation = async (location: string): Promise<any> => {
  return axios.post(graphEndpoint, {
    query: `
    {
      nfts(where: {location: "${location}"}, first:1000 ) {
        id
        isActive
        edition
        creator
        minter
        identifier
        location
        contentHash
        owner
        collection {
          id
          title
          creator
        }
      }
    }
    `,
  });
};

export const getCollectionById = async (id: string): Promise<any> => {
  return axios.post(graphEndpoint, {
    query: `
    {
      collection(id: "${id}") {
        id
        nfts (where: { isActive : true }, first: 1000 ){
          id
          isActive
          edition
          creator
          minter
          identifier
          location
          contentHash
          owner
          lots (first: 1000){
            id
          owner
          minted
          sales (first: 1000){
            id
            buyer
            seller
            price
            timestamp
            transactionHash
          }
          auction {
            id
            address
            index
            owner
            active
            name
            onlyPrimarySales
          }
          price
          startTime
          endTime
          limit
          isResolved
          isSecondary
          lotStatus
      bids (first: 1000){
        id
        bidder
        amount
        timestamp
        transactionHash
        }
      }
        }
      title
      creator
    }
    }
    `,
  });
};

export const getSomeCollections = async (amount: number): Promise<any> => {
  return axios.post(graphEndpoint, {
    query: `
    {
      collections (first: ${amount}) {
        id
        nfts (where: { isActive : true }, first: 1000 ){
          id
          isActive
          edition
          creator
          minter
          identifier
          location
          contentHash
          owner
          lots (first: 1000){
            sales {
              id
              buyer
              seller
              price
              timestamp
              transactionHash
            }
            id
          owner
          minted
          auction {
            id
            address
            index
            owner
            active
            name
            onlyPrimarySales
          }
          price
          startTime
          endTime
          limit
          isResolved
          isSecondary
          lotStatus
      bids (first: 1000){
        id
        bidder
        amount
        timestamp
        transactionHash
        }
      }
        }
      title
      creator
    }
    }
        `,
  });
};

export const getCollectionsByTitle = async (title: string): Promise<any> => {
  return axios.post(graphEndpoint, {
    query: `
    {
      collections(where: {title: "${title}"}) {
       id
    nfts (where: { isActive : true }, first: 1000 ){
      id
      isActive
      edition
      creator
      minter {
        id
      }
      identifier
      location
      contentHash
      owner
      lots (first: 1000){
        sales (first: 1000){
          id
          buyer
          seller
          price
          timestamp
          transactionHash
        }
        id
      }
      sales (first: 1000){
        id
        timestamp
        transactionHash
      }
      collection {
        id
      }
      }
      title
      creator
    }
    }
        `,
  });
};

export const getCollectionsById = async (id: string): Promise<any> => {
  return axios.post(graphEndpoint, {
    query: `
    {
      collections (where: { id: "${id}"}) {
        id
        nfts (where: { isActive : true }, first:1000 ){
          id
          isActive
          edition
          creator
          minter
          identifier
          location
          contentHash
          owner
          lots (first:1000) {
            sales (first: 1000){
              id
              buyer
              seller
              price
              timestamp
              transactionHash
            }
            id
          owner
          minted
          auction {
            id
            address
            index
            owner
            active
            name
            onlyPrimarySales
          }
          price
          startTime
          endTime
          limit
          isResolved
          isSecondary
          lotStatus
      bids (first: 1000){
        id
        bidder
        amount
        timestamp
        transactionHash
        }
      }
        }
      title
      creator
    }
    }
        `,
  });
};

export const getCollectionsByCreator = async (creator: string): Promise<any> => {
  return axios.post(graphEndpoint, {
    query: `
    {
      collections (where: { creator: "${creator}"}) {
        id
        nfts (where: { isActive : true }, first: 1000 ){
          id
          isActive
          edition
          creator
          minter
          identifier
          location
          contentHash
          owner
          lots (first: 1000) {
            sales (first: 1000) {
              id
              buyer
              seller
              price
              timestamp
              transactionHash
            }
            id
          owner
          minted
          auction {
            id
            address
            index
            owner
            active
            name
            onlyPrimarySales
          }
          price
          startTime
          endTime
          limit
          isResolved
          isSecondary
          lotStatus
      bids (first: 1000){
        id
        bidder
        amount
        timestamp
        transactionHash
        }
      }
        }
      title
      creator
    }
    }
        `,
  });
};

export const getAllCollections = async (): Promise<any> => {
  return axios.post(graphEndpoint, {
    query: `
    {
      collections {
        id
        nfts (where: { isActive : true }, first: 1000 ){
          id
          isActive
          edition
          creator
          minter
          identifier
          location
          contentHash
          owner
          lots (first: 1000){
            sales (first: 1000){
              id
              buyer
              seller
              price
              timestamp
              transactionHash
            }
            id
          owner
          minted
          auction {
            id
            address
            index
            owner
            active
            onlyPrimarySales
          }
          price
          startTime
          endTime
          limit
          isResolved
          isSecondary
          lotStatus
      bids (first: 1000){
        id
        bidder
        amount
        timestamp
        transactionHash
        }
      }
        }
      title
      creator
    }
    }
        `,
  });
};

export const getSomeNFTs = async (amount: number): Promise<any> => {
  return axios.post(graphEndpoint, {
    query: `
    {
        nfts(first: ${amount}, where : { isActive : true }) {
          id
          isActive
          edition
          creator
          minter
          identifier
          location
          contentHash
          owner
          lots (first: 1000){
            sales (first: 1000){
              id
              buyer
              seller
              price
              timestamp
              transactionHash
            }
            id
            owner
            minted
            price
            currentPrice
            startTime
            endTime
            limit
            isResolved
            isSecondary
            lotStatus
            }
          collection {
            id
            title
            creator
          }
        }
      }
          `,
  });
};

export const getLotById = async (lotId: string): Promise<any> => {
  return axios.post(graphEndpoint, {
    query: `
        {
          lot (id: "${lotId}") {
            id
            owner
            minted
            sales (first:1000) {
              id
              buyer
              seller
              price
              timestamp
              transactionHash
            }
            token {
              id
              creator
              minter
              identifier
              location
              contentHash
              owner
              }
          auction {
              id
              address
              index
              owner
              active
              name
              onlyPrimarySales
            }
          price
          startTime
          endTime
          limit
          isResolved
          isSecondary
          lotStatus
          bids (first: 1000){
              id
              bidder
              amount
              timestamp
              transactionHash
              claimed
            }
            }
        
        }
        `,
  });
};

export const getUserResolvedLots = async (owner: string): Promise<any> => {
  return axios.post(graphEndpoint, {
    query: `
    {
      lots(where: {owner: "${owner}", isResolved: true}, first: 1000) {
        id
        owner
        minted
        sales (first: 1000){
          id
          buyer
          seller
          price
          timestamp
          transactionHash
        }
        token {
          id
          creator
          identifier
          location
          contentHash
          owner
          }
      auction {
          id
          address
          index
          owner
          active
          name
          onlyPrimarySales
        }
      price
      startTime
      endTime
      limit
      isResolved
      isSecondary
      lotStatus
      bids (first: 1000){
          id
          bidder
          amount
          timestamp
          transactionHash
        }
        }
    }
    `,
  });
};

export const getUserNFTs = async (owner: string): Promise<any> => {
  // console.log({ graphEndpoint });
  return axios.post(graphEndpoint, {
    query: `
    {
      nfts(where: { owner: "${owner}", isActive: true}, first: 1000) {
      id
    creator
    batch {
      maxSupply
      creator
      originalTokenID {
        id
      }
      nfts (first:1000){
        id
      }
    }
    minter {
      id
    address
    }
    isActive
    identifier
    bridged
    lastSoldPrice
    edition
    location
    contentHash
    owner
    lots (first:1000){
       id
            owner
            minted
            sales (first:1000){
              id
              buyer
              seller
              price
              timestamp
              transactionHash
            }
          auction {
              id
              address
              index
              owner
              active
              name
              onlyPrimarySales
            }
          price
          startTime
          endTime
          limit
          isResolved
          isSecondary
          lotStatus
          bids (first: 1000){
              id
              bidder
              amount
              timestamp
              transactionHash
    }
      }
      }
    }
    
          `,
  });
};

export const getUserNFTsPendingSale = async (owner: string): Promise<any> => {
  return axios.post(graphEndpoint, {
    query: `
    {
      lots(where: {owner: "${owner}", lotStatus: "1"}) {
        id
        lotStatus
        auction {
          name
          id
        }
        token {
            id
            creator
            lastSoldPrice
            location
            contentHash
            owner
            lots (first: 1000){
                id
                owner
                minted
                price
                startTime
                endTime
                limit
                isResolved
                isSecondary
                lotStatus
             }
             collection {
                id
                title
                creator
              }
          
            batch {
              maxSupply
              creator
              originalTokenID {
                id
              }
              nfts (first:1000){
                id
              }
            }
          }
        }
      }  
    `,
  });
};

export const getUserNFTsOnSale = async (owner: string): Promise<any> => {
  return axios.post(graphEndpoint, {
    query: `
    {
      lots(where: {owner: "${owner}", lotStatus: "3"}) {
        id
        lotStatus
        auction {
          name
          id
        }
        token {
            id
            creator
            lastSoldPrice
            location
            contentHash
            owner
            lots (first: 1000){
                id
                owner
                minted
                price
                startTime
                endTime
                limit
                isResolved
                isSecondary
                lotStatus
             }
             collection {
                id
                title
                creator
              }
    
            batch {
              maxSupply
              creator
              originalTokenID {
                id
              }
              nfts (first:1000){
                id
              }
            }
          }
        }
      } 
    `,
  });
};

export const getUserLots = async (owner: string): Promise<any> => {
  // console.log({ graphEndpoint });
  return axios.post(graphEndpoint, {
    query: `
    {
      lots(where: {owner: "${owner}"}, first:1000) {
        id
        owner
        minted
        token {
          id
          creator
          minter
          identifier
          location
          contentHash
          owner
          }
      auction {
          id
          address
          index
          owner
          active
          name
          onlyPrimarySales
        }
      price
      startTime
      endTime
      limit
      isResolved
      isSecondary
      lotStatus
      sales (first:1000){
        id
        buyer
        seller
        price
        timestamp
        transactionHash
      }
      bids (first: 1000){
          id
          bidder
          amount
          timestamp
          transactionHash
        }
        }
    
    }
          `,
  });
};

export const getAllNFTs = async (): Promise<any> => {
  return axios.post(graphEndpoint, {
    query: `
    {
        nfts(first: 10, where: { isActive : true }) {
          id
          isActive
          edition
          creator
          minter
          identifier
          location
          contentHash
          owner
          lots (first: 1000){
            id
            owner
            minted
            price
            startTime
            endTime
            limit
            isResolved
            isSecondary
            lotStatus
            }
          collection {
            id
            title
            creator
          }
        }
      }
          `,
  });
};

export const getAllLots = async () => {
  return axios.post<{ data: { lots: Lot[] } }>(graphEndpoint, {
    query: `
        {
          lots (first: 1000){
            id
            minted
            owner
            token {
              id
              batch {
                maxSupply
                creator
                originalTokenID {
                  id
                }
                nfts (first:1000){
                  id
                }
              }
              creator
              minter
              identifier
              location
              contentHash
              owner
              }
          auction {
              id
              address
              index
              owner
              active
              name
              onlyPrimarySales
            }
          price
          startTime
          endTime
          limit
          isResolved
          isSecondary
          lotStatus
          sales (first: 1000){
            id
            buyer
            seller
            price
            timestamp
            transactionHash
          }
          bids (first: 1000){
              id
              bidder
              amount
              claimed
              timestamp
              transactionHash
            }
            }
        
        }
        `,
  });
};

export const getSomeLots = async (amount: number): Promise<any> => {
  return axios.post(graphEndpoint, {
    query: `
        {
          lots (first: ${amount}) {
            id
            owner
            minted
            token {
              id
              creator
              minter
              identifier
              location
              contentHash
              owner
              }
          auction {
              id
              address
              index
              owner
              active
              name
              onlyPrimarySales
            }
          price
          startTime
          endTime
          limit
          isResolved
          isSecondary
          lotStatus
          sales (first: 1000){
            id
            buyer
            seller
            price
            timestamp
            transactionHash
          }
          bids (first: 1000){
              id
              bidder
              amount
              timestamp
              transactionHash
            }
            }
        
        }
        `,
  });
};

export const getMinters = async () => {
  return axios.post<{ data: { minters: Minter[] } }>(graphEndpoint, {
    query: `{
      minters(first: 1000 ) {
        id,
        address,
        userIdentifier,
        isActiveMinter,
        isMinter
      }
    }`,
  });
};

export const getLastCollections = async () => {
  const res = await axios.post<{ data: { lots: Lot[] } }>(graphEndpoint, {
    query: `{
        lots(orderBy: startTime, orderDirection: desc, first: 1000) {
        token {
          contentHash
          collection {
            creator,
            id,
            title,
            nfts (first: 1000) {
              id
              contentHash,
              creator,
              edition,
              identifier,
              isActive,
              lastSoldPrice,
              location
            }
          }
        }
      }
    }`,
  });

  const lots = res.data.data.lots as Lot[];

  // Aplana los lots a colecciones
  const collections = lots.reduce<Collection[]>((all, lot) => {
    const collection = lot.token.collection;
    const alreadyAdded = all.some((c) => collection.id === c.id);
    return alreadyAdded ? all : [...all, collection];
  }, []);

  return collections;
};



export const getSameTokensAs = async (owner: string, location: string): Promise<any> => {
  return axios.post(graphEndpoint, {
    query: `{
        nfts(where: { owner: "${owner}", location: "${location}"}, first: 1000) {
          id
        }
      }
    `,
  });
};