import types from './actionTypes';
import { getEthersContracts } from './helpers/contractBooter';
import { State } from './reducers';
import { SaleWithNftHash } from './services/graph/types';
import { BidPayload } from './services/nft';
import { RoyaltiesPayload } from './services/royalties';

export const useActions = (state: any, dispatch: any) => ({
  setUserLots: (lots: number) => {
    dispatch({
      type: types.SetUserLots.SET_USER_LOST_SUCCESS,
      payload: lots,
    });
  },
  setGalleryValue: (value: number) => {
    dispatch({
      type: types.SetGalleryValue.SET_GALLERY_VALUE_SUCCESS,
      payload: value,
    });
  },
  setUserSalesData: (salesData: SaleWithNftHash[]) => {
    dispatch({
      type: types.SetUserSalesData.SET_USER_SALES_DATA_SUCCESS,
      payload: salesData,
    });
  },
  setUsersNFTs: (usersNFTs: any) => {
    dispatch({
      type: types.setUsersNFTs.SET_USER_NFTS_SUCCESS,
      payload: usersNFTs,
    });
  },
  setUserRewards: (userRewards: any) => {
    dispatch({
      type: types.setUserRewards.SET_USER_REWARDS_SUCCESS,
      payload: userRewards,
    });
  },
  setRewardManager: (rewards: any) => {
    dispatch({
      type: types.setRewardManager.SET_COLLECTIONS_WITH_REWARDS,
      payload: rewards,
    });
  },
  setTermsAccepted: (accepted: boolean) => {
    dispatch({
      type: types.SetTermsAccepted.SET_TERMS_ACCEPTED,
      payload: accepted,
    });
  },
  setCookiesAccepted: (accepted: boolean) => {
    dispatch({
      type: types.SetCookiesAccepted.SET_COOKIES_ACCEPTED,
      payload: accepted,
    });
  },
  setConversionRate: (price: number) => {
    dispatch({
      type: types.SetConversionRate.SET_CONVERSION_RATE_SUCCESS,
      payload: price,
    });
  },
  setWalletConnectedRequest: () => {
    dispatch({
      type: types.SetWalletConnected.SET_WALLET_CONNECTED_REQUEST,
      payload: '',
    });
  },
  setWalletConnected: () => {
    dispatch({
      type: types.SetWalletConnected.SET_WALLET_CONNECTED_SUCCESS,
      payload: '',
    });
  },
  setUserBalance: (data: number) => {
    dispatch({
      type: types.SetUserBalance.SET_USER_BALANCE_SUCCESS,
      payload: data,
    });
  },
  setWalletDisconnected: () => {
    dispatch({
      type: types.SetWalletDisconnected.SET_WALLET_DISCONNECTED_SUCCESS,
      payload: '',
    });
  },
  setProvider: (data: any) => {
    dispatch({ type: types.SetProvider.SET_PROVIDER_SUCCESS, payload: data });
  },
  setContracts: (data: any) => {
    dispatch({
      type: types.SetContracts.SET_CONTRACTS_SUCCESS,
      payload: getEthersContracts(data),
    });
  },
  setWalletAddress: (address: string) => {
    dispatch({
      type: types.SetWalletAddress.SET_WALLET_ADDRESS_SUCCESS,
      payload: address,
    });
  },
  bid: (payload: BidPayload) => {
    dispatch({
      type: types.Bid.BID_PENDING,
    });
    dispatch({
      type: types.Bid.BID_REQUEST,
      payload: payload,
    });
  },
  getRoyalties: (data: RoyaltiesPayload) => {
    dispatch({
      type: types.GetRoyalties.GET_ROYALTIES_REQUEST,
      payload: data,
    });
    dispatch({
      type: types.GetRoyalties.GET_ROYALTIES_PENDING,
      payload: {},
    });
  },
  claimRoyalties: (data: RoyaltiesPayload) => {
    dispatch({
      type: types.ClaimRoyalties.CLAIM_ROYALTIES_PENDING,
    });
    dispatch({
      type: types.ClaimRoyalties.CLAIM_ROYALTIES_REQUEST,
      payload: data,
    });
  },
  openGlobalModal: (data: State['globalModal']) => {
    dispatch({
      type: types.GlobalModal.OPEN,
      payload: data,
    });
  },
  openGlobalModalWithData: (data) => {
    dispatch({
      type: types.GlobalModal.CURRENT_LOT,
      payload: data,
    });
  },
  closeGlobalModal: () => {
    dispatch({
      type: types.GlobalModal.CLOSE,
    });
  },
  setSessionAuthenticated: (data) => {
    dispatch({
      type: types.SetSessionAuthenticated.SET_SESSION_AUTHENTICATED_SUCCESS,
    });
  },
  setMarketplaceLastTab: (data: string) => {
    dispatch({
      type: types.SetMarketplaceLastTab.SET_MARKETPLACE_LAST_TAB,
      payload: data,
    });
  },
});

export interface Actions {
  setTermsAccepted: (accepted: boolean) => void;
  setCookiesAccepted: (accepted: boolean) => void;
  setWalletConnectedRequest: () => void;
  setWalletConnected: () => void;
  setWalletDisconnected: () => void;
  setProvider: (data: any) => void;
  setContracts: (data: any) => void;
  setWalletAddress: (address: string) => void;
  bid: (payload: BidPayload) => void;
  setUserBalance: (data: number) => void;
  setConversionRate: (price: number) => void;
  setUserLots: (lots: number) => void;
  setUsersNFTs: (usersNFTs: any) => void;
  setUserRewards: (userRewards: any) => void;
  setRewardManager: (rewards: any) => void;
  setUserSalesData: (salesData: SaleWithNftHash[]) => void;
  setGalleryValue: (value: number) => void;
  getRoyalties: (data: RoyaltiesPayload) => void;
  claimRoyalties: (options: RoyaltiesPayload) => void;
  openGlobalModal: (data: State['globalModal']) => void;
  openGlobalModalWithData: any;
  closeGlobalModal: () => void;
  setSessionAuthenticated: (data?: any) => void;
  setMarketplaceLastTab: (data: string) => void;
}
