import React, { createContext, useContext, useEffect, useState } from 'react';

interface ContextProps {
  theme: string; // =>  'light' || 'dark
  toggleTheme: () => void;
  staticBody: boolean;
  setStaticBody: (state) => void;
}

export const ThemeContext = createContext({} as ContextProps);

interface Props {
  children: React.ReactNode;
}

export function ThemeProvider(props: Props) {
  const [theme, setTheme] = useState('dark');
  const [modalOpen, setModalOpen] = useState(false);
  // when consuming this context, to toggle the theme, call toggleTheme();
  const toggleTheme: any = () => {
    theme === 'dark' ? setTheme('light') : setTheme('dark');
  };
  const value = { theme, toggleTheme, setStaticBody: setModalOpen, staticBody: modalOpen };

  useEffect(() => {
    const savedTheme = window.localStorage.getItem('theme');
    savedTheme && setTheme(savedTheme);
  }, []);

  useEffect(() => {
    document.body.dataset.theme = theme;
    window.localStorage.setItem('theme', theme);
  }, [theme]);

  useEffect(() => {
    if (modalOpen) document.body.dataset.modal = 'open';
    if (!modalOpen) document.body.dataset.modal= 'closed';
  }, [modalOpen]);

  return <ThemeContext.Provider value={value}>{props.children}</ThemeContext.Provider>;
}

export function useTheme() {
  return useContext(ThemeContext);
}
