// import { getPrice, toBigNumber, toDecimal, toHex } from '~/utils';
import { ethersEthers, BigNumberEthers } from '~/ether-utils';

import { Contracts } from '../helpers/contractBooter';
import { AuctionTypes } from './auctions';
import { Lot } from './graph/types';
import { iLot, iNFTDetailsLot } from './mito/mito.models';
// import { getSuggestedGas } from '~/utils';

export interface BidPayload {
  address: string;
  lot: iLot;
  contracts: Contracts;
  amount: string;
  quantity?: number;
}

export interface ContractUpdate {
  contracts: Contracts;
  account?: any;
}

export interface BridgeProcess {
  contracts: Contracts;
  tokenIds: any;
}

export interface CheckApproval {
  contracts: Contracts;
  artistAddress?: any;
}

export interface CheckApprovalAuth {
  contracts: Contracts;
  artistAddress?: any;
  type?: any;
}
export interface ContractUpdateAuth {
  contracts: Contracts;
  account?: any;
  type?: any;
}

export interface directSaleProcess {
  contracts: Contracts;
  lotId?: any;
  tokenId?: any;
  tokenPrice?: any;
  initialStartTime?: any;
  initialEndTime?: any;
  address?: any;
}

export interface ChangePriceProcess {
  contracts: Contracts;
  lotID?: any;
  newPrice?: any;
}
export interface GetBidPayload {
  firstPriceContract: any;
  lotID: string;
  address: string;
}

export interface CheckSendNFT {
  contracts: Contracts;
  from?: any;
  to?: any;
  id?: any;
}

export interface DirectSalePayload {
  contracts: Contracts;
  lot: iNFTDetailsLot | iLot;
  address?: any;
  amount?: any;
  quantity?: any;
}

export const formatResponse = (amount: string): number => {
  return Number(ethersEthers.utils.formatUnits(amount.toString(), 18));
};

export const getHighestBidPrice = async (payload: GetBidPayload) => {
  const { firstPriceContract, lotID, address } = payload;
  const res = firstPriceContract
    .getLotHighestBid(lotID, { gasPrice: 30000000000, from: address })
    .then((tx: any) => tx.wait())
    .catch((err: any) => err);
  return res;
};

export const bid = async (payload: BidPayload, provider?: any) => {
  const { lot, contracts, address, amount, quantity = 1 } = payload;

  switch (lot.sale_contract.hex_index) {
    case AuctionTypes.firstPrice:
      const res1 = contracts.firstPriceContract
        .bid(lot.hex_lot_id, {
          value: ethersEthers.utils.parseEther(amount),
        })
        .then((tx: any) => tx.wait())
        .catch((err: any) => err);
      return res1;

    case AuctionTypes.timedEdition:
      const res2 = contracts.limitedTimedContract
        .bid(lot.hex_lot_id, BigNumberEthers.from(quantity), {
          value: amount,
        })
        .then((tx: any) => tx.wait())
        .catch((err: any) => err);
      return res2;

    case AuctionTypes.timedEditionBuyLimit:
      const res3 = contracts.limitedTimedBuyLimitContract
        .bid(lot.hex_lot_id, BigNumberEthers.from(quantity), {
          value: amount,
        })
        .then((tx: any) => tx.wait())
        .catch((err: any) => err);
      return res3;

    case AuctionTypes.firstPriceV2:
      const res4 = contracts.firstPriceContractV2
        .bid(lot.hex_lot_id, {
          value: ethersEthers.utils.parseEther(amount),
        })
        .then((tx: any) => tx.wait())
        .catch((err: any) => err);
      return res4;

    case AuctionTypes.timedEditionV2:
      const res5 = contracts.limitedTimedContractV2
        .bid(lot.hex_lot_id, BigNumberEthers.from(quantity), {
          value: amount,
        })
        .then((tx: any) => tx.wait())
        .catch((err: any) => err);
      return res5;

    case AuctionTypes.timedEditionBuyLimitV2:
      const res6 = contracts.limitedTimedBuyLimitContractV2
        .bid(lot.hex_lot_id, BigNumberEthers.from(quantity), {
          value: amount,
        })
        .then((tx: any) => tx.wait())
        .catch((err: any) => err);
      return res6;

    case AuctionTypes.directSaleV2:
      const res7 = contracts.directSaleContractV2
        .buy(lot.hex_lot_id, {
          value: amount,
        })
        .then((tx: any) => tx.wait())
        .catch((err: any) => err);
      return res7;

    case AuctionTypes.timedEditionMoonPay:
      const res8 = contracts.timedEditionContractMoonPay
        .bid(lot.hex_lot_id, BigNumberEthers.from(quantity), {
          value: amount,
        })
        .then((tx: any) => tx.wait())
        .catch((err: any) => err);
      return res8;

    default:
      return null;
  }
};

export const buyWithDirectSale = async (payload: DirectSalePayload) => {
  const { lot, contracts, address, amount, quantity = 1 } = payload;
  const response = contracts.directSaleContractV2
    .buy(lot?.hex_lot_id, {
      value: amount,
    })
    .then((tx: any) => tx.wait())
    .catch((err: any) => err);
  return response;
};

export const setApprovalForAll = async (payload: ContractUpdate) => {
  const { contracts } = payload;

  const setApprovalForAllRequest = contracts.nftContract
    .setApprovalForAll(process.env.NEXT_PUBLIC_BRIDGE_ADDRESS, true)
    .then((tx: any) => tx.wait())
    .catch((err: any) => err);

  return setApprovalForAllRequest;
};

export const setApprovalForAirdrops = async (payload: ContractUpdate) => {
  const { contracts } = payload;

  const setApprovalForAirdropsRequest = contracts.newNftContract
    .setApprovalForAll(process.env.NEXT_PUBLIC_MITO_MINTER, true)
    .then((tx: any) => tx.wait())
    .catch((err: any) => err);

  return setApprovalForAirdropsRequest;
};

export const startBridgeProcess = async (payload: BridgeProcess) => {
  const { contracts, tokenIds } = payload;

  const bridgeRequest = contracts.bridgeNFT
    .bridge(tokenIds)
    .then((tx: any) => tx.wait())
    .catch((err: any) => err);

  return bridgeRequest;
};

export const checkIsApprovedForAll = async (payload: CheckApproval) => {
  const { contracts, artistAddress } = payload;

  const approvedRequest = await contracts.newNftContract
    .isApprovedForAll(artistAddress, process.env.NEXT_PUBLIC_BRIDGE_ADDRESS)
    .then((tx: any) => tx.wait())
    .catch((err: any) => err);

  return approvedRequest;
};

export const setApprovedForAll = async (payload: CheckApproval) => {
  const { contracts } = payload;

  const approvedRequest = await contracts.newNftContract
    .setApprovalForAll(process.env.NEXT_PUBLIC_BRIDGE_ADDRESS, true)
    .then((tx: any) => tx.wait())
    .catch((err: any) => err);

  return approvedRequest;
};

export const setApprovedForAllActionHub = async (payload: CheckApproval) => {
  const { contracts, artistAddress } = payload;

  const approvedRequest = await contracts.newNftContract
    .setApprovalForAll(process.env.NEXT_PUBLIC_AUCTION_HUB_v2, true, { from: artistAddress })
    .then((tx: any) => tx.wait())
    .catch((err: any) => err);

  return approvedRequest;
};

export const requestDirectSale = async (payload: directSaleProcess) => {
  const { contracts, lotId, tokenId, tokenPrice, initialStartTime, initialEndTime, address } =
    payload;

  // const approvedRequest = await contracts.directSaleContract
  const approvedRequest = await contracts.directSaleContractV2
    .createLot(
      lotId,
      tokenId,
      ethersEthers.utils.parseEther(tokenPrice),
      parseInt(initialStartTime),
      parseInt(initialEndTime),
      { from: address },
    )
    .then((tx: any) => tx.wait())
    .catch((err: any) => err);

  return approvedRequest;
};

/**
 *
 */
export const requestChangePrice = async (payload: ChangePriceProcess) => {
  const { contracts, lotID, newPrice } = payload;

  // const approvedRequest = await contracts.directSaleContractV2
  const approvedRequest = await contracts.directSaleContractV2
    .changePrice(lotID, ethersEthers.utils.parseEther(newPrice))
    .then((tx: any) => tx.wait())
    .catch((err: any) => err);

  return approvedRequest;
};

export const checkIsApprovedForAllAuctionHub = async (payload: CheckApproval) => {
  // Artist address -> En realidad es el address del usuario loggeado cuando se habla de
  // un NFT de mercado secundario
  const { contracts, artistAddress } = payload;

  const approvedRequest = await contracts.newNftContract
    .isApprovedForAll(artistAddress, process.env.NEXT_PUBLIC_AUCTION_HUB_v2)
    .then((tx: any) => tx)
    .catch((err: any) => err);

  return approvedRequest;
};

const returnCorrectContract = (type) => {
  switch (type) {
    case 'copy-to-winner': // subasta
      return process.env.NEXT_PUBLIC_FIRST_PRICE_v2;
    case 'copy-to-buyer': // Venta por copias
      return process.env.NEXT_PUBLIC_LIMITED_EDITION_v2;
    case 'copy-to-buyer-max': // Venta por copias con limite
      return process.env.NEXT_PUBLIC_LIMITED_EDITION_BUY_LIMIT_v2;
    case 'moonpay': // Venta por copias Moonpay
      return process.env.NEXT_PUBLIC_LIMITED_EDITION_MOONPAY;
    default:
      return process.env.NEXT_PUBLIC_LIMITED_EDITION_v2;
  }
};

export const setApprovalForAllAuth = async (payload: ContractUpdateAuth) => {
  const { contracts, type } = payload;

  const setApprovalForAllRequest = contracts.newNftContract
    .setApprovalForAll(returnCorrectContract(type), true)
    .then((tx: any) => tx.wait())
    .catch((err: any) => err);

  return setApprovalForAllRequest;
};

export const checkIsApprovedForAllAuth = async (payload: CheckApprovalAuth) => {
  const { contracts, artistAddress, type } = payload;

  const approvedRequest = await contracts.newNftContract
    .isApprovedForAll(artistAddress, returnCorrectContract(type))
    .then((tx: any) => tx)
    .catch((err: any) => err);

  return approvedRequest;
};

export const checkIsApprovedForAirdrops = async (payload: CheckApproval) => {
  const { contracts, artistAddress } = payload;

  if (artistAddress == undefined){return false  }
  const approvedRequest = await contracts.newNftContract
    .isApprovedForAll(artistAddress, process.env.NEXT_PUBLIC_MITO_MINTER)
    .then((tx: any) => tx)
    .catch((err: any) => err);

  return approvedRequest;
};

export const sendNFTRequest = async (payload: CheckSendNFT) => {
  const { contracts, from, to, id } = payload;
  const amount = 1;
  const data = '';

  const sendRequest = await contracts.newNftContract
    .safeTransferFrom(
      from,
      to,
      id,
      BigNumberEthers.from(amount),
      ethersEthers.utils.hexlify(ethersEthers.utils.toUtf8Bytes(data)),
    )
    .then((tx: any) => tx.wait())
    .catch((err: any) => err);

  return sendRequest;
};
